<template>
    <div class="header">
        <a href="https://www.ittm-solutions.com/" target="_blank" class="header-link">Powered by ITTM</a>
        <LanguageBar v-if="showLanguageBar" />
        <div style="padding-bottom: 40px;"></div>
        <v-img v-if="logoHeader" max-width="200" src="../assets/img/logo_OPTIMA.png"></v-img>
        <div v-else>    
            <h1> <span style="color:#FDFEFE">{{ this.$appConfig.skeleton.app_name.part1 }}</span>4<span
                    style="color:#5DADE2">{{ this.$appConfig.skeleton.app_name.part2 }}</span> </h1>
            <p>- {{ this.$appConfig.skeleton.app_name.motto }} - </p>
        </div>
    </div>
</template>

<!--
Header component

This is the component to display Header information such as the service name.

.. Copyright (C) 2020-2022 Information Technology for Translational Medicine S.A.
.. moduleauthor:: Kerstin Neininger
-->
<script>
import LanguageBar from '../components/LanguageBar.vue';

export default {
    name: "Header",
    components: {
        LanguageBar,
    },
    props: {
        showLanguageBar: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            logoHeader: process.env.VUE_APP_LOGO_HEADER === 'true'
        }
    },
    computed: {
        moduleIsReady() {
            return this.$store.getters.user_role
        },

    }
}
</script>

<style scoped>
.header {
    padding-bottom: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white; /*background color*/
    color: #FDFEFE; /*font color*/
    font-size: 21px;
    background-color: #28527a !important;
}

.header-link {
    font-size: 12px;
    color: #fff;
    font-family: "Oswald", sans-serif;
    text-decoration: none;
    margin-left: auto;
    padding-right: 3px;
}

.header-link:hover {
    color: #4286f4;
}
</style>
