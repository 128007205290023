import SensorModule from "./SensorModule"

export const SensorRoutes = [
    {
    //path: "/users/:id",
    path: "sensors",
    name: "sensors-tbd",
    component: SensorModule,
    /*
    children: [
        {
            path: "/",
            name: "sensors-tbd",
            component: SensorPage
        }
    ]
    */
}
]
