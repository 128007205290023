
/**
 * Routes of the survey module
 */

import QuestionnaireModule from "./QuestionnaireModule"

export const QuestionnaireRoutes = [
    {
        //path: "/users/:id",
        path: "questionnaires",
        name: "questionnaires-generateurl",
        component: QuestionnaireModule,
    }
]

