<template>
  <v-app>
    <v-container fluid grid-list-lg pa-5>
      <div style="margin: 50px 0 10px 0">
        <ColorPage />
      </div>
    </v-container>
  </v-app>
</template>

<script>
//import i18n from "@/plugins/i18n";
//import * as services from "../../../services";
import ColorPage from "../../../pages/ColorPage.vue";
export default {
  name: "SettingsPage",
  components: {
    ColorPage,
  },
  data() {
    return {};
  },
  methods: {
  },
  computed: {
  },
};
</script>

