<template>
  <v-app>
    <v-container fluid grid-list-lg>
      <p>{{ $t("lang_welcome") }} {{ user_public_id }}</p>
      <p>{{ $t("lang_organisation") }}: {{ user_organisation }}</p>
    </v-container>
  </v-app>
</template>

<!--
Welcome component

This is the component to display some welcome information.
It is part of the body.

.. Copyright (C) 2020-2022 Information Technology for Translational Medicine S.A.
.. moduleauthor:: Kerstin Neininger
-->
<script>
export default {
  name: "Welcome",
  computed: {
    user_public_id() {
      return this.$store.getters.user_public_id;
    },
    user_organisation() {
      return this.$store.getters.user_organisation;
    },
  },
};
</script>


<style scoped>
</style>
