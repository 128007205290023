<template>
  <div class="container">
    <p>Sensors</p>
  </div>
</template>

<script>
export default {
  name: "SensorPage",
  data() {
    return {};
  },
  methods: {},
  computed: {
  },
};
</script>

