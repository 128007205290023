<template>
  <v-app>
    <v-tabs
      v-model="tab"
      grow
      centered
      background-color="header"
      dark
      next-icon="mdi-arrow-right-bold-box-outline"
      prev-icon="mdi-arrow-left-bold-box-outline"
      show-arrows
    >
      <!-- add class="text-capitalize" to v-tab to remove all uppercase name -->
      <!--<v-tabs-slider color="black"></v-tabs-slider>-->
      <v-tabs-slider color="yellow"></v-tabs-slider>

      <v-tab v-if="showWelcome" :to="{ name: 'welcome' }">
        <v-icon left>fas fa-id-card-alt</v-icon>
        {{ $t("lang_tabwelcome") }}
      </v-tab>

      <v-tab v-if="showAdministration" :to="{ name: 'user-administration' }">
        <v-icon left>fas fa-user-lock</v-icon>
        {{ $t("lang_taboverview") }}
      </v-tab>

      <v-tab v-if="showWorkflow" :to="{ name: 'workflow-tbd' }">
        <v-icon left>fas fa-sort-amount-down</v-icon>
        {{ $t("lang_tabworkflow") }}
      </v-tab>

      <v-tab v-if="showSensor" :to="{ name: 'sensors-tbd' }">
        <v-icon left>fas fa-heartbeat</v-icon>
        {{ $t("lang_tabsensordata") }}
      </v-tab>

      <v-tab
        v-if="showQuestionnaires"
        :to="{ name: 'questionnaires-generateurl' }"
      >
        <v-icon left>fas fa-list-alt</v-icon>
        {{ $t("lang_tabsurveys") }}
      </v-tab>

      <v-tab v-if="showEHR" :to="{ name: 'ehr-overview' }">
        <v-icon left>fas fa-file-medical-alt</v-icon>
        {{ $t("lang_tabehr") }}
      </v-tab>

      <v-tab v-if="showComposer" :to="{ name: 'composer-overview' }">
        <v-icon left>fas fa-wrench</v-icon>
      </v-tab>

      <v-tab v-if="showProfile" :to="{ name: 'user-profile' }">
        <v-icon left>fas fa-user-circle</v-icon>
      </v-tab>
    </v-tabs>
    <router-view></router-view>
  </v-app>
</template>

<!--
UI Body component

This is the component to display the body content.
After login, the users will have access to these tabs dependent on their permissions.

.. Copyright (C) 2020-2022 Information Technology for Translational Medicine S.A.
.. moduleauthor:: Kerstin Neininger
-->
<script>
export default {
  name: "BodyPage",
  data() {
    return {
      tab: null,
    };
  },
  computed: {
    user_role() {
      return this.$store.getters.user_role;
    },
    showWelcome() {
      return this.$store.getters.modulesAccess.main; //always be true/shown
    },
    showComposer() {
      return this.$store.getters.modulesAccess.main; //always be true/shown
    },
    showWorkflow() {
      return false; //this.$store.getters.modulesAccess.user;
      //return this.$appConfig.skeleton.visibility.tabs.sensor; not working as user_role must be set
      //return this.$appConfig["skeleton"]["visibility"][this.user_role]["tabs"][
      //  "workflow"
      //];
    },
    showSensor() {
      return this.$store.getters.modulesAccess.sensor;
      //return this.$appConfig["skeleton"]["visibility"][this.user_role]["tabs"][
      //  "sensor"
      //];
    },
    showQuestionnaires() {
      return this.$store.getters.modulesAccess.survey;
      //return this.$appConfig["skeleton"]["visibility"][this.user_role]["tabs"][
      //  "questionnaires"
      //];
    },
    showEHR() {
      return this.$store.getters.modulesAccess.ehr;
      //return this.$appConfig["skeleton"]["visibility"][this.user_role]["tabs"]["ehr"];
    },
    showAdministration() {
      return this.$store.getters.modulesAccess.user;
      //return this.$appConfig["skeleton"]["visibility"][this.user_role]["tabs"][
      // "administration"
      //];
    },
    showProfile() {
      return this.$store.getters.modulesAccess.main; //always be true/shown
      //return this.$appConfig["skeleton"]["visibility"][this.user_role]["tabs"][
      //  "profile"
      //];
    },
  },
  methods: {},
};
</script>

<style scoped>
</style>