<template>
  <footer>
    <div v-if="!is_anonymous_page">
      <LogoPage />
    </div>
    <div v-if="is_anonymous_page">
      <LogoPageAnonymous />
    </div>
  </footer>
</template>

<!--
Footer component

This is the component to display footer information such as version or the copyright information.

.. Copyright (C) 2020-2022 Information Technology for Translational Medicine S.A.
.. moduleauthor:: Kerstin Neininger
-->
<script>
import LogoPage from "./LogoPage";
import LogoPageAnonymous from "./LogoPageAnonymous";
export default {
  name: "Footer",
  components: {
    LogoPage,
    LogoPageAnonymous,
  },
  computed: {
    is_anonymous_page() {
      return this.$route.name == "anonymous" || this.$route.name == "anonymous_passphrase";
    },
  },
};
</script>
