<template>
  <v-app>
    <Header />
    <v-container fluid fill-height>
      <v-container fluid grid-list-lg>
        <div v-if="show_progress">
          <v-layout justify-center>
            <v-progress-circular
              indeterminate
              color="primary"
              :size="100"
              :width="12"
            ></v-progress-circular>
          </v-layout>
          <div style="margin: 20px 0 10px 0">
            <v-layout justify-center>
              {{ $t("lang_loadingwait") }}
            </v-layout>
          </div>
        </div>

        <div v-if="user_has_access_survey">
          <!-- languages: button is disabled as soon as the questionnaire was started -->
          <!--
          <div id="lang_icons">
            <p id="lang_p">
              {{ $t("lang_choose_lang") }}
              <button
                id="lang_btn"
                v-for="entry in app_languages_anonymous"
                :key="entry.title"
                @click="changeLocale(entry.language)"
                :disabled="show_questionnaire"
              >
                <flag :iso="entry.flag" v-bind:squared="false" />
                {{ entry.title }}
              </button>
            </p>
          </div>
          -->

          <!-- <div class="join-action" align="center">
            <div>
                <v-img max-width="200" src="../../assets/img/logo_t2evolve.png"></v-img>
                <span>and</span>
                <v-img max-width="200" src="../../assets/img/logo_gocart.png"></v-img>
            </div>
            <p>Joint action</p>
          </div> -->

          <v-row v-if="!show_questionnaire" align="center">
            <v-col>
              <div class="text-center">
                <!-- lang setting (show only before start) and only if more than one language -->
                <v-container fluid grid-list-lg>
                  <div v-for="item in lang_items" v-bind:key="item.id">
                    <div
                      v-if="survey_languages_anonymous.includes(item.lang)"
                    >
                      <div style="margin: 20px 0 10px 0">
                        <h4>{{ item.message }}</h4>
                      </div>
                    </div>
                  </div>

                  <!-- language buttons -->
                  <button
                    id="lang_btn"
                    v-for="entry in app_languages_anonymous"
                    :key="entry.title"
                    @click="
                      changeLocale(entry.language) & toggleShowQuestionnaire()
                    "
                    :disabled="show_questionnaire"
                  >
                    <flag :iso="entry.flag" v-bind:squared="false" />
                    {{ entry.title }}
                  </button>
                </v-container>

                <!-- <v-container fluid grid-list-lg>
                  <v-btn
                    id="start_btn"
                    v-for="entry in app_languages_anonymous"
                    :key="entry.title"
                    @click="
                        changeLocale(entry.language) & toggleShowQuestionnaire()
                    "
                    :disabled="show_questionnaire"
                    large
                    color="primary"
                  >
                    <flag :iso="entry.flag" v-bind:squared="false" />
                    {{ entry.title }}
                  </v-btn>
                </v-container> -->

                <!-- Start survey button -->
                <!-- <v-container fluid grid-list-lg>
                  <v-btn
                    id="start_btn"
                    large
                    color="primary"
                    @click="toggleShowQuestionnaire()"
                    :disabled="hide_start_button"
                  >
                    {{ $t("lang_start_survey") }}
                    <v-icon right dark>fas fa-edit</v-icon>
                  </v-btn>
                </v-container> -->
              </div>
            </v-col>
          </v-row>

          <!-- Forward the questionnaire ID to the module to visualize it -->
          <!-- distance after component -->
          <!--<div style="margin: 100px 0 10px 0">-->
          <FillAnonymousQuestionnairePage
            v-if="show_questionnaire"
            :survId="url_survId"
            :jsondata_quest="jsondata_quest_send"
            :jsondata_img="jsondata_img_send"
          ></FillAnonymousQuestionnairePage>

          <div class="notes" v-if="!show_questionnaire">
            <div class="">
              <v-container fluid grid-list-lg pl-10>
                <span v-html="$t('lang_anonymous_survey_legend')"></span>
              </v-container>
            </div>
            
            <!-- <div class="">
              <v-container fluid grid-list-lg pl-10>
                <span v-html="$t('lang_anonymous_survey_funding_title')"></span><br />
                <span v-html="$t('lang_anonymous_survey_funding_text')"></span>
                <span><a href="https://www.imi.europa.eu/">https://www.imi.europa.eu/</a></span>
              </v-container>
            </div> -->
          </div>
        </div>
      </v-container>
    </v-container>
    <Footer />
  </v-app>
</template>

<!--
Anonymous survey module component

This is the component to fill the survey as participant.
The user needs access to this survey (by token).

.. Copyright (C) 2020-2022 Information Technology for Translational Medicine S.A.
.. moduleauthor:: Kerstin Neininger
-->
<script>
import Header from "../../pages/Header";
import Footer from "../../pages/Footer";
import FillAnonymousQuestionnairePage from "../questionnaire/pages/FillAnonymousQuestionnairePage";
import i18n from "@/plugins/i18n";
export default {
  name: "AnonymousModule",
  components: {
    Header,
    Footer,
    FillAnonymousQuestionnairePage,
  },
  data() {
    return {
      show_questionnaire: false,
      show_progress: false,
      hide_start_button: true,
      lang_items: [
        {
          id: 1,
          lang: "en",
          message:
            "Please select the language before starting the survey. After the survey has been started, it is no longer possible to adjust the language.",
        },
        {
          id: 2,
          lang: "pt",
          message:
            "Por favor, antes de começar o inquérito, selecione o idioma que pretende utilizar. Após este ponto não será possível voltar a modificá-lo.",
        },
        {
          id: 3,
          lang: "de",
          message:
            "Bitte wählen Sie die Sprache vor dem Starten der Umfrage. Nach dem Starten der Umfrage ist eine Anpassung der Sprache nicht mehr möglich.",
        },
        {
          id: 4,
          lang: "fr",
          message:
            "Veuillez sélectionner la langue avant de commencer l'enquête. Une fois l'enquête lancée, il n'est plus possible de régler la langue.",
        },
        {
          id: 5,
          lang: "it",
          message:
            "Selezionare la lingua prima di iniziare il sondaggio. Dopo l'avvio del sondaggio, non è più possibile modificare la lingua.",
        },
        {
          id: 6,
          lang: "nl",
          message:
            "Gelieve de taal te kiezen voordat u met de enquête begint. Nadat de enquête is gestart, is het niet meer mogelijk de taal aan te passen.",
        },
        {
          id: 7,
          lang: "es",
          message:
            "Por favor, seleccione el idioma antes de comenzar la encuesta. Una vez iniciada la encuesta, ya no es posible ajustar el idioma.",
        },
        {
          id: 8,
          lang: "pl",
          message:
            "Proszę wybrać język przed rozpoczęciem ankiety. Po rozpoczęciu ankiety nie ma już możliwości dostosowania języka.",
        },
        {
          id: 9,
          lang: "el",
          message:
            "Επιλέξτε τη γλώσσα πριν ξεκινήσετε την έρευνα. Μετά την έναρξη της έρευνας, δεν είναι πλέον δυνατή η ρύθμιση της γλώσσας.",
        },
      ],
    };
  },
  async created() {
    //check access
    await this.$store.dispatch("FETCH_SET_ANONYMOUS_TOKEN", this.url_token); //set anonymous token
    await this.validate_user_access();

    //set default language based on url paramater
    this.changeLocale(this.user_default_language);
  },
  computed: {
    /**
     * Survey languages of the UI. Only add languages that should be shown in this survey.
     * @returns {array} - The available UI languages as defined in the store
     */
    app_languages_anonymous() {
      const all_lang = this.$store.getters.app_languages_anonymous; //languages set in store (hardcoded)
      const filtered_lang = [];
      for (var i = 0; i < all_lang.length; i++) {
        const langitem = all_lang[i];
        if (this.survey_languages_anonymous.includes(langitem.language)) {
          filtered_lang.push(langitem);
        }
      }
      return filtered_lang;
    },
    /**
     * Survey languages of the provided survey. Languages taken from 'title' field from surveyJS JSON.
     * This is independent of E-Mail templates associated to a survey.
     * @returns {array} - The available survey languages as defined in the store
     */
    survey_languages_anonymous() {
      //
      if (this.jsondata_quest_send) {
        return Object.keys(this.jsondata_quest_send.title); //["pt"];//["en", "pt"];
      } else {
        return [];
      }
    },
    /**
     * @returns {string} - The survey token as taken from the URL
     */
    url_token() {
      return this.$route.query.token;
    },
    /**
     * @returns {string} - The survey UUID as taken from the URL
     */
    url_survId() {
      return this.$route.query.survId;
    },

    //set language if exists in url, otherwise use en
    //if the url parameter does not exist return 'en', in case in the preferred lang
    /*
    url_default_language() {
      const url_param = this.$route.query.default_language;
      if (url_param) {
        //only allow languages from the quest
        if (this.survey_languages_anonymous.includes(url_param)) {
          return url_param;
        } else {
          return this.survey_languages_anonymous[0];
        }
      } else {
        return this.survey_languages_anonymous.includes("en")
          ? "en"
          : this.survey_languages_anonymous[0];
      }
    },
*/

    /**
     * @returns {object} - The survey data (images, questionnaires, defaultLanguage etc.)
     */
    available_questionnaire_data_anonymous() {
      return this.$store.getters.available_questionnaire_data_anonymous;
    },
    /**
     * @returns {object} - The survey questionnaire data used by surveyJS
     */
    jsondata_quest_send() {
      return this.available_questionnaire_data_anonymous["data"];
    },
    /**
     * @returns {object} - The survey image data used by surveyJS
     */
    jsondata_img_send() {
      return this.available_questionnaire_data_anonymous["imageData"];
    },
    //url_questId() {
    //  return this.$store.getters.user_has_access_survey_questId;
    //},
    /**
     * @returns {string} - The default language for this user
     */
    user_default_language() {
      return this.available_questionnaire_data_anonymous["defaultLanguage"];
    },

    /**
     * @returns {boolean} - True, if the user is allowed to participate in the survey, else False
     */
    user_has_access_survey() {
      return this.$store.getters.user_has_access_survey;
    },
  },

  methods: {
    /**
     * Part of the i18n package to change the UI language based on selected flag
     * @param {object} locale - The selected language in two-letter code
     */
    changeLocale(locale) {
      i18n.locale = locale;
    },
    /**
     * Enable the start button to start the survey
     */
    enableStartButton() {
      this.hide_start_button = false;
    },
    /**
     * Show the rendered questionnaire
     */
    toggleShowQuestionnaire() {
      this.show_questionnaire = !this.show_questionnaire;
      this.hide_start_button = true;
    },
    /**
     * Validate if the user/participant is allowed to access the survey
     */
    async validate_user_access() {
      try {
        this.show_progress = true;
        var payload = {
          survey_public_id: this.url_survId,
        };
        await this.$store.dispatch("FETCH_USER_ACCESS_SURVEY", {
          payload,
        });
        this.show_progress = false;
      } catch (error) {
        this.$modal.show1({
          title1: i18n.t("lang_failure"),
          text1: error,
        });
        this.show_progress = false;
      }
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 1920px;
  width: 100%;
}
#lang_p {
  font-family: "Oswald", sans-serif;
  font-size: 12px;
}

#lang_icons {
  position: absolute;
  top: 5px;
  right: 20px;
  color: white;
  /*border: 1px solid black;*/
}
#lang_btn {
  padding: 2px;
  margin: 5px;
}
.notes {
  /* position: absolute;
  bottom: 140px;
  width: 100%; */
  /* left: 0px; */
  /* padding-left: 10px; */
  display: flex;
}
.notes div {
  flex-grow: 1;
  width: 100%;
}
.join-action {
    font-size: 22px;
    padding-bottom: 16px;
}
.join-action div {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
  align-items: center;
}
.join-action span {
    margin: 0 20px;
}
</style>