<template>
  <div :ref="chart.id"></div>
</template>


<script>
import Plotly from "plotly.js";

export default {
  //ignore npm error which shows up for no reason..
  /* eslint-disable */
  name: "plotly-charts",
  props: {
    chart: ""
    },
  /* eslint-disable */

  mounted() {
    //use plotly library
    Plotly.plot(
      this.$refs[this.chart.id],
      this.chart.data,
      this.chart.layout,
      {displayModeBar: false} //do not show the mode/toolbar
    );
  },
  watch: {
    chart: {
      handler: function() {
        Plotly.react(
          this.$refs[this.chart.id],
          this.chart.data,
          this.chart.layout
        );
      },
      deep: true
    }
  }
};
</script>