<template>
  <v-app>
    <v-container fluid grid-list-lg pa-5>
      <div v-if="moduleIsReady">
        <v-tabs
          v-model="tab"
          light
          grow
          centered
          background-color="subtabs"
          next-icon="mdi-arrow-right-bold-box-outline"
          prev-icon="mdi-arrow-left-bold-box-outline"
          show-arrows
          hide-slider
        >
          <!-- openEHR START -->
          <!--<v-tabs-slider color="header"></v-tabs-slider>-->
          <!-- add class="text-capitalize" to v-tab to remove all uppercase name -->
          <!--
          <v-tab
            class="text-capitalize"
            :disabled="user_role_isOPE || user_role_isHAS"
          >
            <v-icon left>fas fa-paste</v-icon>
            {{ $t("lang_tab_ehrTemplates") }}
          </v-tab>

          <v-tab class="text-capitalize" :disabled="user_role_isAdmin">
            <v-icon left>fas fa-file-medical</v-icon>
            {{ $t("lang_tab_ehrCompositions") }}
          </v-tab>
          -->
          <!-- openEHR END -->

          <v-tab class="text-capitalize" :disabled="user_role_isAdmin">
            <v-icon left>fas fa-table</v-icon>
            {{ $t("lang_tab_ehrQuestionnaire") }}
          </v-tab>

          <v-tab class="text-capitalize" :disabled="user_role_isAdmin">
            <v-icon left>fas fa-fire</v-icon>
            FHIR
          </v-tab>
          <!-- openEHR START -->
          <!--
          <v-tab-item>
            <openEHRTemplatesPage />
          </v-tab-item>

          <v-tab-item>
            <openEHRCompositionsPage />
          </v-tab-item>
          -->
          <!-- openEHR END -->

          <v-tab-item>
            <QuestionnaireOverviewPage />
          </v-tab-item>

          <v-tab-item>
            <FHIRPage />
          </v-tab-item>
        </v-tabs>
      </div>
    </v-container>
  </v-app>
</template>

<script>
//import i18n from "@/plugins/i18n";
//import openEHRTemplatesPage from "./pages/openEHRTemplatesPage";
//import LoadQuestionnaireReplyPage from "./pages/LoadQuestionnaireReplyPage";
import QuestionnaireOverviewPage from "./pages/QuestionnaireOverviewPage";
//import DiagnosesPage from "./pages/DiagnosesPage";
//import openEHRCompositionsPage from "./pages/openEHRCompositionsPage.vue";
import FHIRPage from "./pages/FHIRPage.vue";

export default {
  name: "EHROverviewModule",
  components: {
    //openEHRTemplatesPage,
    //LoadQuestionnaireReplyPage,
    QuestionnaireOverviewPage,
    //openEHRCompositionsPage,
    FHIRPage,
  },
  /*
  async created() {
    await this.openEHR_getTemplates();
  },
    async mounted() {
    await this.openEHR_getTemplates();
  },
  */
  data() {
    return {
      tab: null,
    };
  },
  methods: {
    //NOTE: async does not work here, will retrieve n sub components
    //get all templates
    /*
    async openEHR_getTemplates() {
      try {
        await this.$store.dispatch("FETCH_OPENEHR_GET_TEMPLATES");
      } catch (error) {
        this.$modal.show1({
          title1: i18n.t("lang_failure"),
          text1: error,
        });
      }
    },
    */
  },
  computed: {
    //open tabs as soon as ehr data loaded
    moduleIsReady() {
      return true; //this.$store.getters.openEHR_alltemplates ? true : false;
    },
    user_role_isAdmin() {
      return this.$store.getters.user_role_isAdmin;
    },
    user_role_isOPE() {
      return this.$store.getters.user_role_isOPE;
    },
    user_role_isHAS() {
      return this.$store.getters.user_role_isHAS;
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 1920px;
  width: 100%;
}
</style>