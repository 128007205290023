import Welcome from "./pages/Welcome"
import LandingPage from "./pages/LandingPage.vue"
import LoginPage from "./modules/user/pages/LoginPage"
import AnonymousModule from "./modules/user/AnonymousModule"
import AnonymousModulePassphrase from "./modules/user/AnonymousModulePassphrase"
import ComponentNotFound from './pages/ComponentNotFound';

/*COMMENT WHEN SURVEY CONTENT WAS ADDED */
//import add_new_questionnaire_json from "./modules/questionnaire/pages/add_new_questionnaire_json"
//import test_comp from "./pages/LandingPage"
/*COMMENT WHEN SURVEY CONTENT WAS ADDED */

import { WorkflowRoutes } from "./modules/workflow/routes"
import { UserRoutes } from "./modules/user/routes"
import { QuestionnaireRoutes } from "./modules/questionnaire/routes"
import { SensorRoutes } from "./modules/sensor/routes"
import { EHRRoutes } from "./modules/ehr/routes";
import { ComposerRoutes } from "./modules/dashboardcomposer/routes";

//Anonymous routes
//import AnonymousQuestionnaireModule from "./modules/questionnaire/AnonymousQuestionnaireModule"
//import StartAnonymousQuestionnairePage from "./modules/questionnaire/pages/StartAnonymousQuestionnairePage"

// needed?: path: "/users/:id",
export const AppRoutes = [
    {
        path: "/login",
        name: "login",
        component: LoginPage
    },
    {
        path: "/anonymous",//"/anonymous/<questionnaire_id>/:token", :token is not working but this also works without :token
        name: "anonymous",
        component: AnonymousModule
        /* does not work?
        children: [
            {
                path: "survey",
                name: "anonymous.survey",
                component: StartAnonymousQuestionnairePage
            }
        ]
        */
    },
    {
        path: "/validation",
        name: "anonymous_passphrase",
        component: AnonymousModulePassphrase
    },
    /*COMMENT WHEN SURVEY CONTENT WAS ADDED */
     /*
    {
        path: "/test",
        name: "test",
        component: test_comp
    },
     */
    /*
    {
        path: "/addsurvey",
        name: "addsurvey",
        component: add_new_questionnaire_json
    },
    */
     /*COMMENT WHEN SURVEY CONTENT WAS ADDED */
    {
        path: "/auth",
        name: "auth",
        component: LandingPage,
        children: [
            {
                path: "welcome",
                name: "welcome",
                component: Welcome
            },
            ...WorkflowRoutes,
            ...UserRoutes,
            ...QuestionnaireRoutes,
            ...SensorRoutes,
            ...EHRRoutes,
            ...ComposerRoutes
        ]
    },
    { path: '*', component: ComponentNotFound }
];