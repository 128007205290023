<template>
  <v-app>
    <v-container fluid grid-list-lg pa-5>
      <v-layout justify-center>
        <h4>{{ $t("lang_username") }}: {{ user_public_id }}</h4>
      </v-layout>
      <v-layout justify-center>
        {{ $t("lang_roles") }}: {{ user_role }}
      </v-layout>
      <v-layout justify-center>
        {{ $t("lang_organisation") }}: {{ user_organisation }}
      </v-layout>
      <div style="margin: 50px 0 10px 0">
        <v-layout justify-center> isauth: {{ isAuthenticated }}</v-layout>
      </div>
      <div style="margin: 200px 0 10px 0">
        <v-btn block large color="background" @click="logout">{{
          $t("lang_logout")
        }}</v-btn>
      </div>
    </v-container>
  </v-app>
</template>


<!--
Profile about component

This is the component to display information of the currently logged in user.

.. Copyright (C) 2020-2022 Information Technology for Translational Medicine S.A.
.. moduleauthor:: Kerstin Neininger
-->
<script>
export default {
  name: "ProfileAboutPage",
  data() {
    return {};
  },
  methods: {
    /**
     * User Logout
     */
    async logout() {
      await this.blacklist_token();
      this.redirect_loginpage();
    },

    /**
     * Blacklist the token (e.g. for Logout)
     */
    async blacklist_token() {
      await this.$store.dispatch("FETCH_USER_LOGOUT", { payload: {} }); //backend logout to blacklist token
    },

    /**
     * Redirection to Login page
     */
    redirect_loginpage() {
      this.$emit("authenticated", false);
      this.$router.replace({ name: "login" });
    },
  },
  computed: {
    /**
     * @returns {string} - The user public ID as UUID
     */
    user_public_id() {
      return this.$store.getters.user_public_id;
    },
    /**
     * @returns {string} - The user role, e.g. OPE
     */
    user_role() {
      return this.$store.getters.user_role;
    },
    /**
     * @returns {string} - The organisation that the user is associated to
     */
    user_organisation() {
      return this.$store.getters.user_organisation;
    },
    /**
     * @returns {boolean} - The user authentication state
     */
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
  },
};
</script>

