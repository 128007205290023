<template>
  <v-app>
    <v-container fluid grid-list-lg> Not found - 404 </v-container>
  </v-app>
</template>

<!--
Not found component

This is the component that will always be displayed in a case a route the user is directing to does not exist (default page).

.. Copyright (C) 2020-2022 Information Technology for Translational Medicine S.A.
.. moduleauthor:: Kerstin Neininger
-->
<script>
export default {
  name: "ComponentNotFound",
};
</script>

<style scoped>
</style>