<template>
  <v-app>
    <v-card>
      <!-- Questionnaire Table -->
      <v-container fluid grid-list-lg class="title">
        <v-card-title class="title">
          <v-text-field
            v-model="search"
            append-icon="search"
            :label="$t('lang_search')"
            single-line
            hide-details
          ></v-text-field>

          <!-- Action Button -->
          <v-layout row wrap justify-end>
            <v-flex shrink>
              <v-icon small color="error" @click="request_deletion">
                fas fa-trash-alt
              </v-icon>
            </v-flex>
          </v-layout>
        </v-card-title>

        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="entriesList"
          single-select
          item-key="questionnairePublicId"
          show-select
          class="elevation-2"
          :sort-by="['dateCreatedUtc']"
          :sort-desc="[false, false]"
          :search="search"
        >
        </v-data-table>

        <!-- Realod when data was changed in health management -->
        <v-layout justify-center>
          <v-btn text icon @click="reload_table">
            <v-icon>fas fa-sync-alt</v-icon>
          </v-btn>
        </v-layout>

        <!-- progress -->
        <div v-if="show_progress">
          <v-container style="height: 100px">
            <v-row class="fill-height" align-content="center" justify="center">
              <v-col class="text-subtitle-1 text-center" cols="12">
                {{ $t("lang_getdata") }}
              </v-col>
              <v-col cols="12">
                <v-progress-linear
                  indeterminate
                  color="primary"
                  rounded
                  buffer-value="0"
                  stream
                  height="5"
                ></v-progress-linear>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-container>

      <!-- choose lang -->
      <div v-if="selectedRow">
        <v-container fluid grid-list-lg>
          <v-layout justify-left>
            <v-flex md4>
              <v-select
                v-model="select_language"
                :items="survey_languages"
                :rules="[(v) => !!v || $t('lang_field_required')]"
                :label="$t('lang_language')"
                required
              ></v-select>
            </v-flex>
          </v-layout>
        </v-container>
      </div>

      <!-- preview page -->
      <div v-if="show_rendered">
        <v-container fluid grid-list-lg pa-5>
          <!-- render questionnaire; show when json data is set -->
          <v-card>
            <RenderQuestionnairePage
              :jsondata_quest="jsondata_quest_send"
              :chosen_lang="select_language"
              :jsondata_img="jsondata_img_send"
            />
          </v-card>
        </v-container>
      </div>

      <!-- Message 'Are you sure?' -->
      <v-dialog v-model="visibleDiagSure" max-width="400">
        <v-card>
          <v-card-title class="headline">
            <p id="diaghead">
              {{ $t("lang_diag_areyousure_head") }}
            </p></v-card-title
          >
          <v-card-text
            ><p id="diagbody">{{ textDiagSure }}</p></v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text small color="secondary" @click="closeDiagSure()">
              <p id="diagbody">{{ $t("lang_no") }}</p></v-btn
            >
            <v-btn color="secondary" @click="quest_delete">
              <p id="diagbody">{{ $t("lang_yes") }}</p>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-app>
</template>


<!--
Questionnaire overview component

This is the component to display all questionnaires that were uploaded for an OPE.

.. Copyright (C) 2020-2022 Information Technology for Translational Medicine S.A.
.. moduleauthor:: Kerstin Neininger
--> 
<script>
import i18n from "@/plugins/i18n";
import RenderQuestionnairePage from "./RenderQuestionnairePage";
export default {
  name: "QuestionnaireOverviewPage",
  props: {
    jsondata: String, //send to render component
    chosen_lang: String,
    img_dict: Map,
  },
  components: {
    RenderQuestionnairePage,
  },
  data() {
    return {
      show_progress: true,

      //tables
      search: "",
      entriesList: [],
      selected: [],

      //render quest
      //send data from parent
      select_language: null,
      survey_languages: [],

      jsondata_quest_send: null,
      jsondata_img_send: {},

      //render
      show_rendered: false,

      //dialog
      visibleDiagSure: false,
      textDiagSure: "",
    };
  },
  methods: {
    /**
     * Assign the retrieved questionnaires to the entries of the table
     */
    async fill_table() {
      await this.get_available_questionnaires();
      if (this.available_questionnaires) {
        this.entriesList = this.available_questionnaires.questionnaires;
      }
    },

    /**
     * Reload the table by querying the respective endpoint
     */
    reload_table() {
      this.fill_table();
    },

    /**
     * Retrieve all available questionnaires from the database
     */
    async get_available_questionnaires() {
      const payload = {};
      this.show_progress = true;
      await this.$store.dispatch("FETCH_GET_AVAILABLE_QUESTIONNAIRES", {
        payload,
      });
      this.show_progress = false;
    },

    /**
     * Needed for deletion and give a warning in case no row was selected
     */
    request_action() {
      if (!this.selectedRow) {
        throw {
          title: i18n.t("lang_reminder"),
          message: i18n.t("lang_selectrow"),
        };
      }
    },

    /**
     * Request the deletion of an entry. This will open a dialog with OK to delete or CLOSE buttons.
     */
    request_deletion() {
      try {
        this.request_action();
        // reminder to confirm deletion
        this.showDiagSure({
          textDiagSure: i18n.t("lang_diag_areyousure_delete_body"),
        });
      } catch (error) {
        this.$modal.show1({
          title1: error.title,
          text1: error.message,
        });
      }
    },

    /**
     * In case deletion is triggered, delete the questionnaire from the database
     */
    async quest_delete() {
      this.closeDiagSure(); //close dialog that is  still open
      try {
        var payload = {
          questionnaire_public_id: this.selectedRow.questionnairePublicId,
          questionnaire_uuid: this.selectedRow.questionnaireUuid,
        };
        await this.$store.dispatch("FETCH_DELETE_QUESTIONNAIRE", { payload });
        this.$modal.show1({
          title1: i18n.t("lang_success"),
          text1: i18n.t("lang_itemdeleted"),
        });
        this.reload_table();
        this.reset_render();
        this.selected = [];
      } catch (error) {
        this.$modal.show1({
          title1: i18n.t("lang_failure"),
          text1: error,
        });
      }
    },

    /**
     * Close the deletion dialog
     */
    closeDiagSure() {
      this.visibleDiagSure = false;
    },

    /**
     * Open the deletion dialog
     * @param {object} params - The text to be displayed
     */
    showDiagSure(params) {
      this.visibleDiagSure = true;
      this.textDiagSure = params.textDiagSure;
    },

    /**
     * Get the JSON files (questionnaire, image) from the selected row
     * @returns {object} - The JSON files of the selected questionnaire
     */
    async get_survey_json_data() {
      /*
      Get questionnaire data to display languages (otherwise dropdown languages will be empty)
      */
      var jsondata_quest_send =
        this.available_questionnaires["quest_map"][
          this.selectedRow.questionnairePublicId
        ].data;
      var jsondata_img_send =
        this.available_questionnaires["quest_map"][
          this.selectedRow.questionnairePublicId
        ].imageData;

      //set available languages
      var survey_languages = Object.keys(jsondata_quest_send.title);

      return {
        jsondata_quest_send: jsondata_quest_send,
        jsondata_img_send: jsondata_img_send,
        survey_languages: survey_languages,
      };
    },

    /**
     * Render the selected questionnaire using the surveyJS library functionalities.
     * All data is send to the <RenderQuestionnairePage/> component
     */
    async render_quest() {
      /*
      First retrieve, then set available language, then render
      await necessary to render languages correctly
      */
      if (this.selectedRow) {
        var quest_json_data = await this.get_survey_json_data();
        if (!this.select_language) {
          this.survey_languages = quest_json_data.survey_languages;
        } else {
          this.show_rendered = false;
          this.jsondata_quest_send = await quest_json_data.jsondata_quest_send;
          this.jsondata_img_send = await quest_json_data.jsondata_img_send;
          this.show_rendered = true;
        }
      }
    },

    /**
     * Reset the fields of the rendering
     */
    reset_render() {
      this.show_rendered = false;
      this.survey_languages = [];
      this.select_language = null;
      this.jsondata_img_send = null;
      this.jsondata_quest_send = null;
    },
  },

  mounted() {
    this.fill_table();
  },

  watch: {
    /**
     * Render the questionnaire in case a row is selected
     */
    selectedRow: function () {
      if (this.selectedRow) {
        this.render_quest();
      }
      //always reset as possible that not untick-tick box but rather just change tick
      this.reset_render();
    },
    /**
     * Change the rendering if the language is switched
     */
    select_language: function () {
      this.render_quest();
    },
  },

  computed: {
    /**
     * @returns {object} - All available questionnaires a survey can be created for
     */
    available_questionnaires() {
      return this.$store.getters.available_questionnaires;
    },
    /**
     * @returns {object} - The selected row
     */
    selectedRow() {
      return this.selected[0];
    },

    /**
     * @returns {array} - The headers of the datatable
     */
    headers() {
      return [
        {
          text: this.$t("lang_ques_ID"),
          value: "questionnairePublicId",
          sortable: true,
        },
        {
          text: this.$t("lang_date"),
          value: "dateCreatedUtc",
          sortable: true,
        },
        {
          text: this.$t("lang_quest_uuid"),
          value: "questionnaireUuid",
          sortable: true,
        },
      ];
    },
  },
};
</script>

<style scoped>
</style>