<template>
  <v-container fluid grid-list-lg pa-5>
    <v-form ref="form_register_survey">
      <v-layout justify-left>
        <v-flex md3>
          <v-text-field
            v-model="surveyId_register"
            :label="$t('lang_survey_ID')"
            :rules="surveyidRules"
            required
          ></v-text-field>
        </v-flex>

        <v-flex md3>
          <v-select
            v-model="select_questId_register"
            :items="available_quest_ids"
            :rules="[(v) => !!v || $t('lang_field_required')]"
            :label="$t('lang_ques_ID')"
            required
          ></v-select>
        </v-flex>

        <v-flex md3>
          <v-text-field
            v-model="select_validitytime_survey"
            type="number"
            :label="$t('lang_validdays')"
            :rules="numberDayRules"
            required
          ></v-text-field>
        </v-flex>
      </v-layout>

      <v-layout justify-left>
        <v-flex md5>
          <v-textarea
            v-model="survey_email_template_area"
            :label="$t('lang_emailtemplate')"
            :rules="mailtemplateRules"
            counter
            rows="15"
            filled
            outlined
            color="grey"
            prepend-icon="fas fa-at"
          ></v-textarea>
        </v-flex>
        <v-flex md1>
          <v-select
            v-model="select_language"
            :items="survey_languages_default"
            :rules="[(v) => !!v || $t('lang_field_required')]"
            :label="$t('lang_language')"
            required
          ></v-select>

          <!-- add E-Mail template -->
          <v-layout justify-center>
            <v-icon large color="secondary" @click="add_mail_template_to_json">
              fas fa-arrow-alt-circle-right
            </v-icon>
          </v-layout>
          <v-layout justify-center>
            <!-- added message color=secondary -->
            <p style="color: #34a1ad">
              {{ status_added }}
            </p>
          </v-layout>
          <!-- add E-Mail template -->
          <v-layout justify-center>
            <v-icon large color="error" @click="remove_mail_template_from_json">
              fas fa-arrow-alt-circle-left
            </v-icon>
          </v-layout>
          <v-layout justify-center>
            <p style="color: #ba0000" color="primary">
              {{ status_removed }}
            </p>
          </v-layout>
        </v-flex>

        <v-flex md6>
          <!-- template json -->
          <v-textarea
            v-model="survey_email_template_area_all"
            :label="$t('lang_emailtemplate_storage')"
            counter
            rows="15"
            filled
            outlined
            color="secondary"
            readonly
          ></v-textarea>
        </v-flex>
      </v-layout>

      <!-- ICON 2 -->
      <div style="margin: 20px 0 10px 0">
        <h4>{{ $t("lang_select_icon") }}</h4>
      </div>
      <v-layout justify-left>
        <v-flex md4>
          <label class="text-reader">
            <input
              id="upload_icon2_file"
              type="file"
              accept="application/png"
              @change="read_icon2_binary_from_upload"
            />
          </label>
        </v-flex>
        <v-flex md3>
          <v-btn small color="background" @click="clear_uploaded_icon2_file">{{
            $t("lang_clear")
          }}</v-btn>
        </v-flex>
      </v-layout>

      <!-- Token mode: switch to token dynamic in case several users should use the same ID -->
      <div style="margin: 30px 0 20px 0">
        <h4>{{ $t("lang_token_mode_head") }}</h4>
        <p>{{ $t("lang_token_mode_body") }}</p>
      </div>
      <v-switch
        v-model="switch_token_mode"
        :label="$t('lang_token_switch')"
      ></v-switch>

      <div v-if="switch_token_mode">
        <v-layout justify-left>
          <v-flex md2>
            <v-text-field
              v-model="passphrase"
              :label="$t('lang_passphrase')"
              :rules="passphraseRules"
              required
            ></v-text-field>
          </v-flex>
        </v-layout>
      </div>

      <!-- Buttons -->
      <div style="margin: 40px 0 10px 0">
        <v-layout justify-left>
          <v-flex xs12 md2>
            <v-btn color="primary" @click="register_survey">{{
              $t("lang_btn_create")
            }}</v-btn>
          </v-flex>
          <v-flex xs12 md2>
            <v-btn color="warning" @click="reset_page">{{
              $t("lang_clear")
            }}</v-btn>
          </v-flex>
        </v-layout>
      </div>
    </v-form>
  </v-container>
</template>

<!--
Creation of a survey component

This is the component to create a survey. For that, a questionnaire from the uploaded questionnaires can be chosen.
After this, participanst can be assigned.

.. Copyright (C) 2020-2022 Information Technology for Translational Medicine S.A.
.. moduleauthor:: Kerstin Neininger
-->
<script>
import i18n from "@/plugins/i18n";
import * as rules from "../assets/rules";
import * as services from "../../../services";
export default {
  name: "CreateSurveyPage",
  data() {
    return {
      //token mode
      switch_token_mode: false,

      //register questionnaire
      surveyId_register: "",
      select_questId_register: null,
      select_validitytime_survey: null,
      select_language: null,
      survey_email_template_area: "", //textarea
      survey_email_template_area_all: "", //textarea showing parsed json
      survey_email_templates_json: {}, //json file for the templates (this will be stored when created)
      status_added: "",
      status_removed: "",
      icon2_uploaded: null,
      passphrase: "",
    };
  },
  created() {
    //set E-Mail template to current interface lang
    this.select_language = this.$i18n.locale;
    this.survey_email_template_area =
      this.survey_email_templates[this.$i18n.locale];
    this.get_registered_surveys();

    this.survey_email_template_area_all = this.format_json_to_text({});
  },
  methods: {
    /**
     * Register a survey based on the given parameters. After this, participanst can be assigned.
     */
    async register_survey() {
      if (this.$refs.form_register_survey.validate()) {
        try {
          var icon2_data = this.icon2_uploaded ? this.icon2_uploaded : ""; //send empty if not chosen
          var start_time_utc = new Date().getTime() / 1000; // Math.round(), start time is always NOW (UTC),
          var expiration_time_utc =
            start_time_utc +
            services.daysToSeconds(this.select_validitytime_survey); // * 86400, // + days * (seconds per day),
          var payload = {
            survey_public_id: this.surveyId_register,
            //questionnaire_public_id: this.select_questId_register,
            questionnaire_uuid: this.questionnaire_uuid,
            start_time_utc: start_time_utc,
            expiration_time_utc: expiration_time_utc,
            template_mail: this.survey_email_templates_json,
            icon2_data: icon2_data,
            token_dynamic_mode: this.switch_token_mode,
            passphrase: this.switch_token_mode ? this.passphrase : "",
          };
          await this.$store.dispatch("FETCH_REGISTER_SURVEY", {
            payload,
          });
          await this.get_registered_surveys(); //retrieve again after creation

          this.$modal.show1({
            title1: i18n.t("lang_success"),
            text1: i18n.t("lang_itemadded"),
          });
        } catch (error) {
          this.$modal.show1({
            title1: i18n.t("lang_failure"),
            text1: error,
          });
          this.reset_page();
        }
      }
    },

    /**
     * Validate File size, throw error if invalid
     * @param {object} file - The file object that was uploaded
     */
    validate_file_size(file) {
      const file_size_kb = file.size / 1000;
      const valid_size = services.validUploadFileSize(
        file_size_kb / 1000, //MB
        "studyIcon"
      );
      if (!valid_size) {
        throw {
          type: "c4cError",
          message: i18n.t("lang_sizeinvalid"),
          code: 400,
        };
      }
    },

    /**
     * Clear file icon1
     */
    clear_uploaded_icon2_file() {
      const upload_file = document.getElementById("upload_icon2_file");
      if (upload_file) {
        upload_file.value = null;
      }
    },

    /**
     * Upload base64 icons for the survey. File object is taken from the HTML input field
     * @param {object} ev - The file object that was uploaded
     */
    read_icon2_binary_from_upload(ev) {
      try {
        const file = ev.target.files[0];
        this.validate_file_size(file); //validate size

        //read file if size valid
        const reader = new FileReader();
        reader.onload = (ev) => {
          var text = ev.target.result;
          try {
            this.icon2_uploaded = text;
          } catch (error) {
            this.$modal.show1({
              title1: i18n.t("lang_failure"),
              text1: error,
            });
          }
        };
        reader.readAsDataURL(file);
      } catch (error_size) {
        this.$modal.show1({
          title1: i18n.t("lang_failure"),
          text1: error_size.message,
        });
      }
    },

    /**
     * Add adaptations to json and update textarea
     */
    add_mail_template_to_json() {
      if (this.survey_email_template_area.includes("$url_token")) {
        this.survey_email_templates_json[this.select_language] =
          this.survey_email_template_area;
        this.survey_email_template_area_all = this.format_json_to_text(
          this.survey_email_templates_json
        );
        this.status_added = i18n.t("lang_updated");
      } else {
        this.$modal.show1({
          title1: i18n.t("lang_warning"),
          text1: i18n.t("lang_body_template_url_token"),
        });
        this.status_added = "";
      }
    },

    /**
     * Remove a template for a specific language from the JSON and therefore from the textfield area
     */
    remove_mail_template_from_json() {
      delete this.survey_email_templates_json[this.select_language];
      this.survey_email_template_area_all = this.format_json_to_text(
        this.survey_email_templates_json
      );

      this.status_removed = i18n.t("lang_removed");
    },

    /**
     * Format json dict to text to make it more user-friendly
     * @param {object} json_templates - The JSON E-Mail templates as shown in the textarea
     * @returns {string} - The E-Mail template JSON in a user-friendly format
     */
    format_json_to_text(json_templates) {
      var json_as_text = "";
      for (var key in json_templates) {
        if (json_templates.hasOwnProperty(key)) {
          var value = json_templates[key];
          json_as_text =
            json_as_text +
            "--- " +
            i18n.t("lang_language") +
            ": " +
            key +
            " ---\n\n" +
            value +
            "\n\n" +
            "-".repeat(50) +
            "\n";
        }
      }
      return json_as_text;
    },

    /**
     * Retrieve all registered surveys for this user
     */
    async get_registered_surveys() {
      try {
        await this.$store.dispatch("FETCH_REGISTERED_SURVEYS");
      } catch (error) {
        this.$modal.show1({
          title1: i18n.t("lang_failure"),
          text1: error,
        });
      }
    },

    /**
     * Reset the page content
     */
    reset_page() {
      this.surveyId_register = "";
      this.select_questId_register = null;
      this.select_validitytime_survey = null;
      this.clear_uploaded_icon2_file();
      this.survey_email_template_area_all = "";
      this.survey_email_templates_json = {};
      this.switch_token_mode = false;
    },
  },
  computed: {
    /**
     * @returns {array} - All available questionnaire IDs/names a survey can be created for
     */
    available_quest_ids() {
      return this.$store.getters.available_questionnaires.quest_names;
    },
    /**
     * @returns {object} - All registered surveys
     */
    registered_surveys() {
      return this.$store.getters.registered_surveys;
    },
    /**
     * @returns {array} - Set of rules for the passphrase
     */
    passphraseRules() {
      return rules.passphraseRules;
    },
    /**
     * @returns {object} - All available questionnaires a survey can be created for
     */
    available_questionnaires() {
      return this.$store.getters.available_questionnaires;
    },
    /**
     * @returns {string} - The UUID of the selected questionnaire
     */
    questionnaire_uuid() {
      return this.available_questionnaires["quest_map"][
        this.select_questId_register
      ]["questionnaire_uuid"];
    },
    /**
     * @returns {array} - Set of rules for the entered number of days
     */
    numberDayRules() {
      return rules.numberDayRules;
    },
    /**
     * @returns {array} - Set of rules for E-Mail template
     */
    mailtemplateRules() {
      return rules.mailtemplateRules;
    },
    /**
     * @returns {array} - Set of rules for the survey Id
     */
    surveyidRules() {
      return rules.surveyidRules;
    },
    /**
     * @returns {object} - The E-Mail templates available for the different languages
     */
    survey_email_templates() {
      return this.$store.getters.survey_email_templates;
    },
    /**
     * @returns {array} - The list of languages two-letter codes from the available E-Mail templates. Needed to create the template.
     */
    survey_languages_default() {
      return Object.keys(this.survey_email_templates);
    },
    /**
     * @returns {object} - The current survey results
     */
    all_survey_results() {
      return this.$store.getters.all_survey_results;
    },
  },
  watch: {
    /**
     * Change the E-Mail template based on the chosen language
     */
    select_language: function () {
      this.survey_email_template_area =
        this.survey_email_templates[this.select_language];
      this.status_added = "";
      this.status_removed = "";
    },
    /**
     * Reset the status under the arrow to not show anything after the template changed.
     */
    survey_email_template_area: function () {
      this.status_added = "";
      this.status_removed = "";
    },
  },
};
</script>

<style scoped>
</style>