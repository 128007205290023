<template>
  <v-app>
    <v-container fluid grid-list-lg pa-5>
      <div v-if="moduleIsReady">
        <div v-if="showServiceCom4Survey">
          <AnonymousSurvey />
        </div>

         <div v-if="showServiceTBD">
           TBD
           </div>
      </div>
      <div v-else>Loading profile...</div>
    </v-container>
  </v-app>
</template>

<!--
Survey/questionnaire component

This is the component to upload questionnaires and create surveys. Survey replies can be visualized and downloaded.

.. Copyright (C) 2020-2022 Information Technology for Translational Medicine S.A.
.. moduleauthor:: Kerstin Neininger
-->
<script>
import AnonymousSurvey from "./pages/AnonymousSurvey";
import "survey-vue/defaultV2.min.css";

export default {
  name: "QuestionnaireModule",
  components: {
    AnonymousSurvey,
  },
  created() {},
  data() {
    return {};
  },
  methods: {},
  computed: {
    /**
     * TODO: Make this dependent on some mounting functionalities
     * @returns {boolean} - True, if the module is ready, else false.
     */
    moduleIsReady() {
      return true; //this.$store.getters.available_questionnaires;
    },
    showServiceCom4Survey() {
      return this.$appConfig["client"] == "com4survey";
    },
    showServiceTBD() {
      return false; //put any other service here with respective suboages for this module
    }
  },
  mounted() {},
};
</script>

<style scoped>
.container {
  max-width: 1920px;
  width: 100%;
}
</style>