<template>
    <div :style="{ color }" id="lang_icons">
      <p id="lang_p">
        {{ $t("lang_choose_lang") }}
        <button
          id="lang_btn"
          v-for="entry in app_languages"
          :key="entry.title"
          @click="changeLocale(entry.language)"
        >
          <flag :iso="entry.flag" v-bind:squared="false" />
          {{ entry.title }}
        </button>
      </p>
    </div>
</template>

<script>
import i18n from "@/plugins/i18n";

export default {
    props:{
        color: {
            type: String,
            default: 'white'
        }
    },
    methods: {
        changeLocale(locale) {
            i18n.locale = locale;
        }
    },
    computed: {
        app_languages() {
            return this.$store.getters.app_languages;
        }
    }
}
</script>

<style scoped>
#lang_p {
  font-family: "Oswald", sans-serif;
  font-size: 12px;
}

#lang_icons {
  margin-left: auto;
}

#lang_btn {
  padding: 2px;
  margin: 5px;
}
</style>