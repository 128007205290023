import Vue from 'vue'
import Vuex from 'vuex'
//import './plugins/vuetify'
import App from './App.vue'
import i18n from '@/plugins/i18n'  //language module
import FlagIcon from 'vue-flag-icon' //language module
//import { store } from "./components/store.js"

import { store } from "./store.js"

import colors from "./assets/json/colors.json"
import Vuetify from 'vuetify/lib'
Vue.use(Vuetify, {
    iconfont: 'fa',
})

const vuetify = new Vuetify({
    iconfont: 'fa',
    options: {
        customProperties: true
    },
    theme: {
        themes: {
            light: colors.light1 //default
        }
    },
})
import 'vuetify/dist/vuetify.min.css' // Ensure you are using css-loader

// import Modal/Dialog plugin
import Modal from './plugins/appmodal.js'
Vue.use(Modal)

//language module
Vue.use(FlagIcon)
Vue.config.productionTip = false
Vue.use(Vuex)

//vue router
import VueRouter from 'vue-router'
Vue.use(VueRouter)

//UUID
import UUID from "vue-uuid"
Vue.use(UUID)

//color themes are set in ./plugins/vuetify.js

//vue idle
import IdleVue from 'idle-vue'
const eventsHub = new Vue()
Vue.use(IdleVue, {
    eventEmitter: eventsHub,
    store,
    idleTime: 15 * 60 * 1000, // 15 min (3 seconds = 3000 ms)
    startAtIdle: false
})

//routes
import { AppRoutes } from "./routes"
const router = new VueRouter({
    mode: 'history',
    base: `${process.env.VUE_APP_BASE_URL}`,
    routes: [
        ...AppRoutes,
    ],
    navigationFallback: {
        "rewrite": "/index.html",
        "exclude": ["/images/*.{png,jpg,gif}", "/css/*"]
    }
})

//console.log(router.options)
//config, global variables
import appConfig from "./modules/dashboardcomposer/config_C4Survey.json"
//import appConfig from "./modules/dashboardcomposer/config_C4PDnet.json"
Vue.prototype.$appConfig = appConfig
Vue.prototype.$colors = colors
//Vue.prototype.$alias_url_flask = '/api/redirect_alias_service/'

new Vue({
    el: '#app',
    i18n,
    router,
    store,
    vuetify,
    render: h => h(App),
}).$mount('#app')