<template>
  <v-app>
    <v-container fluid grid-list-lg pa-5>
      <div v-if="moduleIsReady">
        <v-tabs
          v-model="tab"
          light
          grow
          centered
          background-color="subtabs"
          next-icon="mdi-arrow-right-bold-box-outline"
          prev-icon="mdi-arrow-left-bold-box-outline"
          show-arrows
          hide-slider
        >
          <!--<v-tabs-slider color="header"></v-tabs-slider>-->
          <!-- add class="text-capitalize" to v-tab to remove all uppercase name -->
          <v-tab class="text-capitalize">
            <v-icon left>fas fa-palette</v-icon>
            {{ $t("lang_tab_settings") }}
          </v-tab>

          <v-tab-item>
            <SettingsPage />
          </v-tab-item>
        </v-tabs>
      </div>
    </v-container>
  </v-app>
</template>


<!--
Dashboard composer setting component

This is the component for the settings (e.g. colors)

.. Copyright (C) 2020-2022 Information Technology for Translational Medicine S.A.
.. moduleauthor:: Kerstin Neininger
-->
<script>
//import i18n from "@/plugins/i18n";
import SettingsPage from "./pages/SettingsPage";
export default {
  name: "ComposerOverviewModule",
  components: {
    SettingsPage,
  },
  data() {
    return {
      tab: null,
    };
  },
  methods: {},
  computed: {
    /**
     * TODO: Make this dependent on some mounting functionalities to open tabs as soon as ehr data loaded
     * @returns {boolean} - True, if the module is ready, else false.
     */
    moduleIsReady() {
      return true; //this.$store.getters.openEHR_alltemplates ? true : false;
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 1920px;
  width: 100%;
}
</style>