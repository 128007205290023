/**
 * Vuex store of the user module
 */
import { UserService } from "./services"
var serviceClass = new UserService();

/* eslint-disable */


export const UserStore = {
  //namespaced: true,
  // -----------------------------------------------------------------
  state: {
    //roles
    user_roles_choice: ["admin", "HAS", "OPE", "ORG"],
    user_level_choice: [1, 2, 3],
    user_lang_choice: ["en","pt","de","fr","it","es","nl","pl","el"],

    // properties to store user data
    user_public_id: null,
    user_token: null, //current user token
    user_role: null, //current user role
    user_organisation: null, //current user organisation
    organisation_icon: null, //uploaded at sign up of organization
    user_users: null,  //users with GET permission
    user_lang: null,
    user_date: null,

    //for sub-components e.g.
    user_role_isAdmin: false,
    user_role_isOPE: false,
    user_role_isHAS: false,

    all_organisations: null,

    //access to modules
    modulesAccess: {
      communication: false,
      ehr: false,
      main: false,
      report: false,
      sensor: false,
      survey: false,
      user: false,
    },

    //openEHR Id
    user_openEHR_id: "",
    selected_user_public_id: ""
  },
  // -----------------------------------------------------------------
  getters: {
    // getters and computed props on the user data
    user_roles_choice: state => {
      return state.user_roles_choice
    },
    user_level_choice: state => {
      return state.user_level_choice
    },
    user_lang_choice: state => {
      return state.user_lang_choice
    },
    user_public_id: state => {
      return state.user_public_id
    },
    user_token: state => {
      return state.user_token
    },

    user_role: state => {
      return state.user_role
    },
    user_role_isAdmin: state => {
      return state.user_role_isAdmin
    },
    user_role_isOPE: state => {
      return state.user_role_isOPE
    },
    user_role_isHAS: state => {
      return state.user_role_isHAS
    },

    user_lang: state => {
      return state.user_lang
    },
    user_organisation: state => {
      return state.user_organisation
    },
    organisation_icon: state => {
      return state.organisation_icon
    },
    user_date: state => {
      return state.user_date
    },
    user_users: state => {
      return state.user_users
    },
    all_organisations: state => {
      return state.all_organisations
    },
    isAuthenticated: (state) => {
      return !!state.user_token
    },
    modulesAccess: state => {
      return state.modulesAccess
    },
    user_openEHR_id: state => {
      return state.user_openEHR_id
    },
    selected_user_public_id: state => {
      return state.selected_user_public_id
    },
  },
  // -----------------------------------------------------------------
  mutations: {
    // stuff to set user data locally
    SET_USER_PUBLICID(state, { user_public_id }) {
      state.user_public_id = user_public_id;
    },
    SET_USER_TOKEN(state, { user_token }) {
      state.user_token = user_token;
    },

    SET_USER_ROLE(state, { user_role }) {
      state.user_role = user_role;
    },
    SET_USER_ROLE_ISADMIN(state, { user_role_isAdmin }) {
      state.user_role_isAdmin = user_role_isAdmin;
    },
    SET_USER_ROLE_ISOPE(state, { user_role_isOPE }) {
      state.user_role_isOPE = user_role_isOPE;
    },
    SET_USER_ROLE_ISHAS(state, { user_role_isHAS }) {
      state.user_role_isHAS = user_role_isHAS;
    },

    SET_USER_LANG(state, { user_lang }) {
      state.user_lang = user_lang;
    },
    SET_USER_ORGANISATION(state, { user_organisation }) {
      state.user_organisation = user_organisation;
    },
    SET_ORGANISATION_ICON(state, { organisation_icon }) {
      state.organisation_icon = organisation_icon;
    },
    SET_USER_USERS(state, { user_users }) {
      state.user_users = user_users;
    },
    SET_USER_DATE(state, { user_date }) {
      state.user_date = user_date;
    },
    SET_ALL_ORGANISATIONS(state, { all_organisations }) {
      state.all_organisations = all_organisations;
    },
    SET_MODULES_ACCESS(state, { modulesAccess }) {
      state.modulesAccess = modulesAccess;
    },
    SET_USER_OPENEHRID(state, { user_openEHR_id }) {
      state.user_openEHR_id = user_openEHR_id;
    },
    SET_SELECTED_USER_PUBLICID(state, { selected_user_public_id }) {
      state.selected_user_public_id = selected_user_public_id;
    },
  },
  // -----------------------------------------------------------------
  actions: {
    async FETCH_USER_LOGIN({ commit }, { payload }) {
      commit("SET_USER_TOKEN", { user_token: null });
      commit("SET_USER_ROLE", { user_role: null });
      commit("SET_USER_ROLE_ISADMIN", { user_role_isAdmin: null });
      commit("SET_USER_ROLE_ISOPE", { user_role_isOPE: null });
      commit("SET_USER_ROLE_ISHAS", { user_role_isHAS: null });
      commit("SET_USER_LANG", { user_lang: null });
      commit("SET_USER_ORGANISATION", { user_organisation: null });
      commit("SET_ORGANISATION_ICON", { organisation_icon: null });
      commit("SET_USER_DATE", { user_date: null });
      commit("SET_USER_PUBLICID", { user_public_id: null });
      commit("SET_MODULES_ACCESS", {
        modulesAccess: {
          communication: false,
          ehr: false,
          main: false,
          report: false,
          sensor: false,
          survey: false,
          user: false,
        }
      });

      const response = await serviceClass.userAuthentication(payload);

      commit("SET_USER_TOKEN", { user_token: response.access_token });
      commit("SET_USER_ROLE", { user_role: response.user_role });
      commit("SET_USER_ROLE_ISADMIN", { user_role_isAdmin: response.user_role == "admin" });
      commit("SET_USER_ROLE_ISOPE", { user_role_isOPE: response.user_role == "OPE" });
      commit("SET_USER_ROLE_ISHAS", { user_role_isHAS: response.user_role == "HAS" });
      commit("SET_USER_LANG", { user_lang: response.user_lang });
      commit("SET_USER_ORGANISATION", { user_organisation: response.user_organisation });
      commit("SET_ORGANISATION_ICON", { organisation_icon: response.organisation_icon });
      commit("SET_USER_DATE", { user_date: response.user_date });
      commit("SET_USER_PUBLICID", { user_public_id: payload['public_id'] });
      commit("SET_MODULES_ACCESS", { modulesAccess: response.modulesAccess });

      return response;
    },

    async FETCH_USER_LOGOUT({ commit }, { payload }) {
      try {
        await serviceClass.userLogout(payload);
      } catch (error) {
        console.log('Error: ', error)
      }

      commit("SET_USER_TOKEN", { user_token: null });
      commit("SET_USER_ROLE", { user_role: null });
      commit("SET_USER_ROLE_ISADMIN", { user_role_isAdmin: null });
      commit("SET_USER_ROLE_ISOPE", { user_role_isOPE: null });
      commit("SET_USER_ROLE_ISHAS", { user_role_isHAS: null });
      commit("SET_USER_LANG", { user_lang: null });
      commit("SET_USER_ORGANISATION", { user_organisation: null });
      commit("SET_USER_DATE", { user_date: null });
      commit("SET_USER_PUBLICID", { user_public_id: null });
    },

    async FETCH_ALL_ORGANISATIONS({ commit }) {
      //commit("SET_ALL_ORGANISATIONS", { all_organisations: null });
      const all_organisations = await serviceClass.getAllOrganisations();
      commit("SET_ALL_ORGANISATIONS", { all_organisations: all_organisations });
      return all_organisations;
    },


    async FETCH_ORGANISATION_REGISTER({ commit }, { payload }) {
      //console.log(commit);
      const new_org = await serviceClass.organisationRegister(payload);
      return new_org;
    },

    async FETCH_ORGANISATION_DELETE({ commit }, { payload }) {
      //console.log(commit);
      const response = await serviceClass.organisationDelete(payload);
      return response;
    },

    async FETCH_USER_SIGNUP({ commit }, { payload }) {
      //console.log(commit);
      const new_user = await serviceClass.userSignup(payload);
      return new_user;
    },

    async FETCH_USER_USERS({ commit }) {
      //console.log(commit);
      commit("SET_USER_USERS", { user_users: null });
      const user_users = await serviceClass.getUsers();
      commit("SET_USER_USERS", { user_users: user_users });
      return user_users;
    },

    async FETCH_USER_DELETE({ commit }, { payload }) {
      //console.log(commit);
      const response = await serviceClass.userDelete(payload);
      return response;
    },

    async FETCH_USER_EDIT({ commit }, { payload }) {
      //console.log(commit);
      const response = await serviceClass.userEdit(payload);
      return response;
    }
  }
}