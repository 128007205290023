<template>
  <v-app>
    <Header />

    <!-- LOGIN -->
    <v-container fluid fill-height>
      <v-container fluid grid-list-lg>
        <v-layout row wrap align-center justify-center>
          <v-flex md2>
            <!-- if form around, pressing enter leads to undesired page reload -->
            <!--<v-form ref="form_passphrase">-->
            <v-text-field
              v-model="passphrase"
              :append-icon="show_eye ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show_eye ? 'text' : 'password'"
              :label="$t('lang_passphrase')"
              :rules="passphraseRules"
              prepend-icon="lock"
              required
              @click:append="show_eye = !show_eye"
              autocomplete="new-password"
              @keyup.enter="validate_passphrase"
            ></v-text-field>
            <!--</v-form>-->
          </v-flex>
          <v-flex md1>
            <!-- pt-2 moves the button down to align wit the textfield -->
            <v-btn color="primary" @click="validate_passphrase"
              >{{ $t("lang_verify_access") }}
            </v-btn>
          </v-flex>

          <!--
            <div v-if="show_progress">
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </div>
              -->
        </v-layout>
        <v-container fluid grid-list-lg pa-15>
          <v-layout justify-center>
            <v-flex md6>
              <div v-if="show_progress">
                <v-progress-linear
                  indeterminate
                  color="primary"
                  rounded
                  buffer-value="0"
                  stream
                  height="10"
                ></v-progress-linear>
              </div>
            </v-flex>
          </v-layout>
        </v-container>
      </v-container>
    </v-container>

    <Footer />
  </v-app>
</template>

<!--
Anonymous survey passphrase component

This is the component for participants to enter the passphrase.
In case the passphrase is correct, the participant is redirected to the anonymous survey component.

.. Copyright (C) 2020-2022 Information Technology for Translational Medicine S.A.
.. moduleauthor:: Kerstin Neininger
-->
<script>
import Header from "../../pages/Header";
import Footer from "../../pages/Footer";
import * as rules from "./assets/rules";
import i18n from "@/plugins/i18n";
export default {
  name: "AnonymousModulePassphrase",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      valid_passphrase: true,
      passphrase: "",
      show_eye: false,
      show_progress: false,
    };
  },
  async created() {
    await this.fetch_access_survey_logos();
  
    try {
      this.changeLocale(this.url_language);
    } catch (error) {
      this.$modal.show1({
        title1: i18n.t("lang_failure"),
        text1: error,
      });
    }
  },
  computed: {
    /**
     * @returns {array} - Set of rules for the passphrase
     */
    passphraseRules() {
      return rules.passphraseRules;
    },
    /**
     * @returns {string} - The current URL
     */
    serviceURL() {
      return location.host;
    },
    /**
     * @returns {string} - Survey UUID as taken from the URL
     */
    url_survId() {
      return this.$route.query.survId;
    },
    /**
     * @returns {string} - The language of the survey as taken from the URL
     */
    url_language() {
      // if missing in URL, choose english
      const lang = this.$route.query.language;
      if (lang) {
        return lang != "gr" ? lang : "el"; // two letter code implementation for greek: surveyJS uses 'gr' and i18n uses 'el'
      } else {
        return "en";
      }
    },
  },

  methods: {
    /**
     * Part of the i18n package to change the UI language based on selected flag
     * @param {object} locale - The selected language in two-letter code
     */
    changeLocale(locale) {
      i18n.locale = locale;
    },
    /**
     * Validate the passphrase and allow access in case it was successful
     */
    async validate_passphrase() {
      if (this.url_survId && this.url_language) {
        //if (this.$refs.form_passphrase.validate()) {
        if (this.passphrase) {
          const token = await this.fetch_access_survey_passphrase();
          if (token) {
            this.event_access(token);
          } else {
            this.event_no_access();
          }
        } else {
          this.$modal.show1({
            title1: i18n.t("lang_failure"),
            text1: i18n.t("lang_enterpassphrase"),
          });
        }
      } else {
        this.$modal.show1({
          title1: i18n.t("lang_warning"),
          text1: i18n.t("lang_urlincorrect"),
        });
      }
    },

    /**
     * @param {string} token - The token as returned from the API call (passphrase verification)
     * @returns {string} - The url with the token (for redirection to the survey loading page) in case user has access
     */
    get_url_with_token(token) {
      var url =
        "https://" +
        this.serviceURL +
        process.env.VUE_APP_BASE_URL + "anonymous?survId=" +
        this.url_survId +
        "&token=" +
        token;

      return url;
    },

    /**
     * Redirect to anonymous page with token
     */
    async event_access(anonymous_token) {
      //await this.get_anonymous_token();
      var url = this.get_url_with_token(anonymous_token);
      if (url.includes("localhost")) {
        url = url.replace("https", "http");
      }
      window.location.href = url;
    },

    /**
     * Show error message in case access is not allowed
     */
    event_no_access() {
      this.$modal.show1({
        title1: i18n.t("lang_failure"),
        text1: i18n.t("lang_passphrase_incorrect"),
      });
    },

    /**
     * Verify if the passphrase is valid
     */
    async fetch_access_survey_passphrase() {
      try {
        this.show_progress = true;
        var payload = {
          survey_uuid: this.url_survId,
          data: {
            passphrase: this.passphrase,
            default_language: this.url_language,
          },
        };
        const response = await this.$store.dispatch(
          "FETCH_USER_ACCESS_SURVEY_PASSPHRASE",
          {
            payload,
          }
        );
        this.show_progress = false;
        return response; //.has_access;
      } catch (error) {
        this.$modal.show1({
          title1: i18n.t("lang_failure"),
          text1: error,
        });
        this.show_progress = false;
      }
    },

    /**
     * Fetch the survey and org logos
     */
    async fetch_access_survey_logos() {
      try {
        this.show_progress = true;
        var payload = {
          survey_public_id: this.url_survId,
        };
        await this.$store.dispatch("FETCH_USER_ACCESS_SURVEY_LOGOS", {
          payload,
        });
        this.show_progress = false;
      } catch (error) {
        this.$modal.show1({
          title1: i18n.t("lang_failure"),
          text1: error,
        });
        this.show_progress = false;
      }
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 1920px;
  width: 100%;
}
</style>