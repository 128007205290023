import { render, staticRenderFns } from "./RenderQuestionnairePage.vue?vue&type=template&id=456838cf&scoped=true"
import script from "./RenderQuestionnairePage.vue?vue&type=script&lang=js"
export * from "./RenderQuestionnairePage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "456838cf",
  null
  
)

export default component.exports