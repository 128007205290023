import AppModal from '../components/AppModal.vue'

const Modal = {
  // every plugin for Vue.js needs install method
  // this method will run after Vue.use(<your-plugin-here>) is executed
  install(Vue) {    //, options
    this.EventBus = new Vue()   // available as Modal.EventBus; listens to events
    Vue.component('app-modal', AppModal)    //make component global
    Vue.prototype.$modal = {
      show1(params) {
        Modal.EventBus.$emit('show1', params)   // emit 'show' event with parameters 'params'; takes object params as argument
      },
      show2(params) {
        Modal.EventBus.$emit('show2', params)   // emit 'show' event with parameters 'params'; takes object params as argument
      }
      //showIdle() {
      // Modal.EventBus.$emit('showIdle')   // emit 'show' event with parameters 'params'; takes object params as argument
      //}
    }
  }
}

export default Modal