<template>
  <v-app>
    <v-container fluid grid-list-lg pa-5>
      <div v-if="moduleIsReady">
      <v-tabs
        v-model="tab"
        light
        grow
        centered
        background-color="subtabs"
        next-icon="mdi-arrow-right-bold-box-outline"
        prev-icon="mdi-arrow-left-bold-box-outline"
        show-arrows
        hide-slider
      >
        <!--<v-tabs-slider color="header"></v-tabs-slider>-->
        <!-- add class="text-capitalize" to v-tab to remove all uppercase name -->

        <v-tab class="text-capitalize">
          <v-icon left>fas fa-table</v-icon>
          {{ $t("lang_tab_questQuestOverview") }}
        </v-tab>

        <v-tab class="text-capitalize">
          <v-icon left>fas fa-folder-open</v-icon>
          {{ $t("lang_tab_questQuestUploader") }}
        </v-tab>

        <v-tab class="text-capitalize">
          <v-icon left>fas fa-heartbeat</v-icon>
          {{ $t("lang_tab_questCreateSurveyPREMPROM") }}
          
        </v-tab>

        <v-tab class="text-capitalize">
          <v-icon left>fas fa-pencil-alt</v-icon>
          {{ $t("lang_tab_questCreateSurvey") }}
        </v-tab>

        <v-tab class="text-capitalize">
          <v-icon left>fas fa-cloud-download-alt</v-icon>
          {{ $t("lang_tab_questRecSurveyResp") }}
        </v-tab>

       

        <v-tab-item>
          <QuestionnaireOverviewPage />
        </v-tab-item>

        <v-tab-item>
          <UploadQuestionnairePage />
        </v-tab-item>

          <v-tab-item>
          <PROMPREMSetupPage />
        </v-tab-item>

        <v-tab-item>
          <SurveySetupPage />
        </v-tab-item>

        <v-tab-item>
          <ReceiveQuestionnaireResponsePage />
        </v-tab-item>

        
      </v-tabs>
      </div>
    </v-container>
  </v-app>
</template>


<!--
Anonymous Survey component

This is the component to create surveys and link the subcomponent as tabs.

.. Copyright (C) 2020-2022 Information Technology for Translational Medicine S.A.
.. moduleauthor:: Kerstin Neininger
-->
<script>
//import i18n from "@/plugins/i18n";
import QuestionnaireOverviewPage from "./QuestionnaireOverviewPage";
import UploadQuestionnairePage from "./UploadQuestionnairePage";
import PROMPREMSetupPage from "./PROMPREMSetupPage";
import SurveySetupPage from "./SurveySetupPage";
import ReceiveQuestionnaireResponsePage from "./ReceiveQuestionnaireResponsePage";
export default {
  name: "AnonymousSurvey",
  components: {
    QuestionnaireOverviewPage,
    UploadQuestionnairePage,
    PROMPREMSetupPage,
    SurveySetupPage,
    ReceiveQuestionnaireResponsePage,
    
  },
   created() {
     this.get_available_questionnaires();
  },
  data() {
    return {
      tab: null,
    };
  },
  methods: {
    /**
     * Retrieves all available questionnaires for this user
     */
    async get_available_questionnaires() {
      const payload = {};
      await this.$store.dispatch("FETCH_GET_AVAILABLE_QUESTIONNAIRES", {
        payload,
      });
    },

  },
  computed: {
    /**
     * TODO: Make this dependent on some mounting functionalities to open tabs as soon as questionnaires loaded
     * @returns {boolean} - True, if the module is ready, else false.
     */
    moduleIsReady() {
      return true;//this.$store.getters.available_questionnaires; there is a delay as takes some time to retrieve questionnaires
    },
  }
  };
</script>

<style scoped>
.container {
  max-width: 1920px;
  width: 100%;
}
</style>