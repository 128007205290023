<template>
  <div id="app">
    <v-app>
      <!-- handle inactivity -->
      <!--<p>Is it Idle? - {{ isIdle }} {{ authenticated }}</p>-->
      <IdleModal v-if="showIdle" />

      <!-- LOGIN -->
      <div id="nav">
        <router-link v-if="authenticated" to="/login"></router-link>
      </div>

      <router-view @authenticated="setAuthenticated" />

      <!-- modal/dialogs -->
      <app-modal />
    </v-app>
  </div>
</template>

<!--
App component

This is the component to initialize the app. Idle modus and authetication are handled here.

.. Copyright (C) 2020-2022 Information Technology for Translational Medicine S.A.
.. moduleauthor:: Kerstin Neininger
-->
<script>
import i18n from '@/plugins/i18n';
import IdleModal from '@/components/IdleModal';
export default {
  name: 'App',
  components: {
    IdleModal,
  },
  data() {
    return {
      //Login, authentication
      authenticated: false,
    };
  },
  created() {
    //redirrect to Login, authentication, need differentiation because of anonymous module/page
    this.authenticated = this.$store.getters.isAuthenticated;
    if (
      !this.authenticated &&
      (this.$route.path.includes('/auth') || (this.$route.path == '/'))
    ) {
      this.$router.replace({ name: 'login' });
    } else if (this.authenticated && this.$route.path == '/') {
      this.$router.replace({ name: 'welcome' });
    }
  },
  computed: {
    /**
     * Idle modus for inactivity (components/IdleModal.vue)
     * Do not show on Login page. Do not auto-logout on anonymous survey as not user-friendly.
     * @returns {boolean} - True if idle mode should be activated, else False
     */
    showIdle() {
      return (
        this.isIdle &&
        this.$route.path != '/login' &&
        this.$route.path != '/anonymous' &&
        this.$route.path != '/validation'
      );
    },
    /**
     * @returns {boolean} - True if state is idle, else False (display messages, logout, time etc. are implemented in components/IdleModal.vue and in the main.js)
     */
    isIdle() {
      return this.$store.state.idleVue.isIdle;
    },
  },
  methods: {
    /**
     * Part of the i18n package to change the UI language based on selected flag
     * @param {object} locale - The selected language in two-letter code
     */
    changeLocale(locale) {
      i18n.locale = locale;
    },
    /**
     * Login, authentication. Set authentication state
     */
    setAuthenticated(status) {
      this.authenticated = status;
    },
  },
  watch: {},
};
</script>

<style lang="css">
</style>
