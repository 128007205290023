<template>
  <v-app>
    <v-container fluid grid-list-lg>
      <div v-if="moduleIsReady">
        <v-tabs
          v-model="tab"
          light
          grow
          centered
          background-color="subtabs"
          next-icon="mdi-arrow-right-bold-box-outline"
          prev-icon="mdi-arrow-left-bold-box-outline"
          show-arrows
          hide-slider
        >
          <!--<v-tabs-slider color="header"></v-tabs-slider>-->
          <!-- add class="text-capitalize" to v-tab to remove all uppercase name -->

          <!-- ADMIN -->
          <v-tab class="text-capitalize" :disabled="user_role_isOPE">
            <v-icon left>fas fa-sitemap</v-icon>
            {{ $t("lang_tab_organisations_admin") }}
          </v-tab>

          <v-tab class="text-capitalize">
            <v-icon left>fas fa-users</v-icon>
            {{ $t("lang_tab_useroverview_admin") }}
          </v-tab>

          <v-tab class="text-capitalize">
            <v-icon left>fas fa-user-plus</v-icon>
            {{ $t("lang_tab_usersignup_admin") }}
          </v-tab>

          <!--
          <v-tab class="text-capitalize">
            <v-icon left>fas fa-list-alt</v-icon>
            {{ $t("lang_tab_usersignup_moredata") }}
          </v-tab>
           -->
          <!-- Admin, OPE basic sign up-->
          <v-tab-item>
            <OrganisationOverviewPage />
          </v-tab-item>

          <v-tab-item>
            <UserOverviewPage />
          </v-tab-item>

          <v-tab-item>
            <SignupBasicPage />
          </v-tab-item>
          <!--
          <v-tab-item>
            <SignupExtendedPage />
          </v-tab-item>
          -->
        </v-tabs>
      </div>
      <div v-else>Loading profile...</div>
    </v-container>
  </v-app>
</template>

<!--
User administration component

This is the component for the administration such as overview of existing users or signup of new ones.

.. Copyright (C) 2020-2022 Information Technology for Translational Medicine S.A.
.. moduleauthor:: Kerstin Neininger
-->
<script>
import i18n from "@/plugins/i18n";
import OrganisationOverviewPage from "./pages/OrganisationOverviewPage";
import UserOverviewPage from "./pages/UserOverviewPage";
import SignupBasicPage from "./pages/SignupBasicPage";
//import SignupExtendedPage from "./pages/SignupExtendedPage";
export default {
  name: "UserAdministrationModule",
  components: {
    OrganisationOverviewPage,
    UserOverviewPage,
    SignupBasicPage,
    //SignupExtendedPage,
  },
  created() {
    //request here as also needed in user signup page
    this.get_all_organizations();
    this.get_user_users();
  },
  data() {
    return {
      tab: null,
    };
  },
  computed: {
    /**
     * TODO: Make this dependent on some mounting functionalities
     * @returns {boolean} - True, if the module is ready, else false.
     */
    moduleIsReady() {
      return this.user_users; // load as soon as everything is loaded;
    },
    /**
     * @returns {array} - List of users
     */
    user_users() {
      return this.$store.getters.user_users;
    },
    /**
     * @returns {boolean} - True, if the user is admin, else false
     */
    user_role_isAdmin() {
      return this.$store.getters.user_role_isAdmin;
    },
    /**
     * @returns {boolean} - True, if the user is OPE, else false
     */
    user_role_isOPE() {
      return this.$store.getters.user_role_isOPE;
    },
  },

  methods: {
    /**
     * Retrieve all organisations
     */
    async get_all_organizations() {
      try {
        await this.$store.dispatch("FETCH_ALL_ORGANISATIONS");
      } catch (error) {
        //show error only for Admin, as OPE is forbiddedn (403) and displaying this error is annoying because ORG table is not even shown for OPE
        if (this.user_role_isAdmin) {
          this.$modal.show1({
            title1: i18n.t("lang_failure"),
            text1: error,
          });
        }
      }
    },
    /**
     * Retrieve all users
     */
    async get_user_users() {
      try {
        await this.$store.dispatch("FETCH_USER_USERS");
      } catch (error) {
        this.$modal.show1({
          title1: i18n.t("lang_failure"),
          text1: error,
        });
      }
    },
  },
};
</script>

<style scoped>
</style>