<template>
  <v-app>
    <LanguageBar color="black" />

    <!--<v-btn large color="primary" @click="test">test</v-btn>-->

    <!-- ANONYMOUS LOGIN -->
    <!--
    <v-container fluid>
      <v-layout justify-left>
        <v-btn color="primary" :to="{ name: 'anonymous' }">
          Anonymous Login
          <v-icon right dark>fas fa-arrow-circle-right</v-icon>
        </v-btn>
      </v-layout>
    </v-container>
    -->

    <!-- LOGIN -->
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4>
          <v-card class="elevation-12">
            <v-toolbar color="primary" flat dark>
              <v-toolbar-title
                >{{ $t("lang_login") }}
                {{ this.$appConfig.skeleton.app_name.full }}</v-toolbar-title
              >
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    href="https://www.ittm-solutions.com"
                    icon
                    large
                    target="_blank"
                    v-on="on"
                  >
                    <v-icon>fas fa-external-link-alt</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("lang_linklogin") }}</span>
              </v-tooltip>
            </v-toolbar>
            <v-card-text>
              <v-form>
                <v-text-field
                  v-model="input.username"
                  :label="$t('lang_username')"
                  name="username"
                  prepend-icon="person"
                  type="text"
                ></v-text-field>
                <v-text-field
                  v-model="input.password"
                  :label="$t('lang_password')"
                  name="password"
                  prepend-icon="lock"
                  type="password"
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              {{ $t("lang_newsignup") }}
              <!-- :to="{ name: 'signup' }" -->
              <v-btn text @click="signup" class="lowercase">{{
                $t("lang_signup")
              }}</v-btn>

              <v-spacer></v-spacer>
              <v-btn large color="primary" @click="login">{{
                $t("lang_login")
              }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>

    <LoginFooter />
  </v-app>
</template>

<!--
The Login page component

This is the component to Login into the service.

.. Copyright (C) 2020-2022 Information Technology for Translational Medicine S.A.
.. moduleauthor:: Kerstin Neininger
-->
<script>
import i18n from "@/plugins/i18n";
import LoginFooter from "./LoginFooter";
import LanguageBar from "../../../components/LanguageBar.vue";
export default {
  name: "LoginPage",
  components: {
    LoginFooter,
    LanguageBar
  },
  data() {
    return {
      input: {
        username: "",
        password: "",
      },
    };
  },
  created() {},
  methods: {
    /*
    test() {
      this.$modal.show1({
          title1: i18n.t("lang_success"),
          text1: "djlsdjlsk"
        });
    },
    */

   /**
    * Part of the i18n package to change the UI language based on selected flag
    * @param {object} locale - The selected language in two-letter code
    */
    changeLocale(locale) {
      i18n.locale = locale;
    },

/**
 * User Login with username and password.
 * If credentials are valid, the user is directed to the home/welcome page
 */
    async login() {
      try {
        var payload = {
          password: this.input.password,
          public_id: this.input.username,
        };
        await this.$store.dispatch("FETCH_USER_LOGIN", { payload });
        this.$emit("authenticated", true);
        this.$router.replace({ name: "welcome" }); //directly forward to the welcome page
      } catch (error) {
        this.$modal.show1({
          title1: i18n.t("lang_failure"),
          text1: error,
        });
      }
    },

    /**
     * User signup.
     * Not implemented, only a message is displayed to a user.
     */
    signup() {
      this.$modal.show1({
        title1: i18n.t("lang_notification"),
        text1: i18n.t("lang_contactadmin"),
      });
    },
  },
  computed: {
    /**
     * @returns {array} - The available languages in the UI as defined in the Dashboard composer module
     */
    app_languages() {
      return this.$store.getters.app_languages;
    },
  },
};
</script>

<style scoped>
.v-btn.lowercase {
  text-transform: none !important;
}
</style>