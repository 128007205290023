import { EHRService } from "./services"
var serviceClass = new EHRService();

/* eslint-disable */

export const EHRStore = {
  //namespaced: true,
  // -----------------------------------------------------------------
  state: {
    //openEHRdata: {},
    openEHR_alltemplates: [],
    //openEHR_flatformat: {},
    available_composition: [],
    all_available_compositions: [] //list of all compositions
  },
  // -----------------------------------------------------------------
  getters: {
    // getters and computed props on the user data
    //openEHRdata: state => {
    //  return state.openEHRdata
    //},
    openEHR_alltemplates: state => {
      return state.openEHR_alltemplates
    },
    //openEHR_flatformat: state => {
    //  return state.openEHR_flatformat
    //},
    available_composition: state => {
      return state.available_composition
    },
    all_available_compositions: state => {
      return state.all_available_compositions
    },
  },
  // -----------------------------------------------------------------
  mutations: {
    // stuff to set user data locally
    //SET_OPENEHRDATA(state, { openEHRdata }) {
    //  state.openEHRdata = openEHRdata;
    //},
    SET_OPENEHR_ALLTEMPLATES(state, { openEHR_alltemplates }) {
      state.openEHR_alltemplates = openEHR_alltemplates;
    },
    //SET_OPENEHR_FLATFORMAT(state, { openEHR_flatformat }) {
    //  state.openEHR_flatformat = openEHR_flatformat;
    //},
    SET_AVAILABLE_COMPOSITION(state, { available_composition }) {
      state.available_composition = available_composition;
    },
    SET_ALL_AVAILABLE_COMPOSITIONS(state, { all_available_compositions }) {
      state.all_available_compositions = all_available_compositions;
    },
  },
  // -----------------------------------------------------------------
  actions: {
    /*
    async FETCH_OPENEHR_RETRIEVE_EHR( { commit }, { payload } ) {
      commit("SET_OPENEHRDATA", { openEHRdata: {} });
      const response = await serviceClass.openEHR_retrieveEHR( payload );
      commit("SET_OPENEHRDATA", { openEHRdata: response.ehr_data });
      return response;
    },
    */

    async FETCH_OPENEHR_CREATE_EHR({ commit }, { payload }) {
      //commit("SET_USER_OPENEHRID", { user_openEHR_id: null });
      const response = await serviceClass.openEHR_createEHR(payload);
      //commit("SET_USER_OPENEHRID", { user_openEHR_id: response.ehr_id });
      return response.ehr_id;
    },

    async FETCH_OPENEHR_DELETE_EHR({ commit }, { payload }) {
      const response = await serviceClass.openEHR_deleteEHR(payload);
      return response;
    },

    async FETCH_OPENEHR_UPLOAD_TEMPLATE({ commit }, { payload }) {
      //console.log(commit);
      const response = await serviceClass.openEHR_uploadTemplate(payload);
      return response;
    },

    async FETCH_TEMPLATE_DELETE({ commit }, { payload }) {
      //console.log(commit);
      const response = await serviceClass.openEHR_templateDelete(payload);
      return response;
    },


    async FETCH_OPENEHR_GET_COMPOSITION_DETAILS({ commit }, { payload }) {
      const flatformat = await serviceClass.openEHR_compositionDetails(payload);
      //commit("SET_OPENEHR_FLATFORMAT", { openEHR_flatformat: flatformat });
      return flatformat;
    },

    async FETCH_OPENEHR_GET_TEMPLATES({ commit }) {
      commit("SET_OPENEHR_ALLTEMPLATES", { openEHR_alltemplates: [] });
      const all_templates = await serviceClass.openEHR_getTemplates();
      commit("SET_OPENEHR_ALLTEMPLATES", { openEHR_alltemplates: all_templates });
      return all_templates;
    },

    async FETCH_OPENEHR_STORE_COMPOSITION({ commit }, { payload }) {
      //console.log(commit);
      const response = await serviceClass.openEHR_storeComposition(payload);
      return response;
    },

    async FETCH_OPENEHR_GET_COMPOSITION({ commit }, { payload }) {
      commit("SET_AVAILABLE_COMPOSITION", { available_composition: [] });
      const available_composition = await serviceClass.openEHR_getComposition(payload);
      commit("SET_AVAILABLE_COMPOSITION", { available_composition: available_composition });
      return available_composition;
    },

    async FETCH_OPENEHR_GET_ALL_COMPOSITIONS({ commit }, { payload }) {
      commit("SET_ALL_AVAILABLE_COMPOSITIONS", { all_available_compositions: [] });
      const all_available_compositions = await serviceClass.openEHR_getAllCompositions(payload);
      commit("SET_ALL_AVAILABLE_COMPOSITIONS", { all_available_compositions: all_available_compositions });
      return all_available_compositions;
    },

    async FETCH_OPENEHR_STORE_COMPOSITION_FLAT({ commit }, { payload }) {
      const response = await serviceClass.openEHR_storeComposition_flat(payload);
      return response;
    },

    async FETCH_OPENEHR_COMPOSITION_DELETE({ commit }, { payload }) {
      //console.log(commit);
      const response = await serviceClass.openEHR_compositionDelete(payload);
      return response;
    },

    async FETCH_OPENEHR_LAUNCH_AQL_QUERY({ commit }, { payload }) {
      //console.log(commit);
      const response = await serviceClass.openEHR_launchAQL(payload);
      return response;
    },

    async FETCH_EHR_GET_FHIRRESOURCE({ commit }, { payload }) {
      //console.log(commit);
      const response = await serviceClass.EHR_getFHIRresource(payload);
      return response;
    },

    async FETCH_EHR_STORE_FHIRRESOURCE({ commit }, { payload }) {
      //console.log(commit);
      const response = await serviceClass.EHR_storeFHIRresource(payload);
      return response;
    },
    

    

  }
}