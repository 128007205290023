<template>
  <v-app>
    <v-container fluid grid-list-lg>
      <v-form ref="form_upload_quest">
        <div>
          <!-- upload questionnaire -->
          <div style="margin: 20px 0 10px 0">
            <h3>{{ $t("lang_select_questfile") }}</h3>
          </div>
          <v-container fluid grid-list-lg>
            <v-layout justify-left>
              <v-flex md4>
                <label class="text-reader">
                  <input
                    id="upload_questionnaire_file"
                    type="file"
                    accept="application/json"
                    @change="read_questionnaire_from_upload"
                  />
                </label>
              </v-flex>
              <v-flex md3>
                <!--<v-btn color="warning" @click="parse_batch_upload">{{ $t('lang_upload_data') }}</v-btn>-->
                <v-btn small color="background" @click="clear_uploaded_file">{{
                  $t("lang_clear")
                }}</v-btn>
              </v-flex>
            </v-layout>
            <v-flex md6>
              <v-text-field
                v-model="questionnaire_id"
                :label="$t('lang_ques_ID')"
                :rules="questidRules"
                required
              ></v-text-field>
            </v-flex>
          </v-container>
          <!-- upload images -->
          <div style="margin: 20px 0 10px 0">
            <h3>{{ $t("lang_select_imgfile") }}</h3>
          </div>
          <v-container fluid grid-list-lg>
            <v-layout justify-left>
              <v-flex md4>
                <label class="text-reader">
                  <input
                    id="upload_images_file"
                    type="file"
                    accept="application/json"
                    @change="read_images_from_upload"
                  />
                </label>
              </v-flex>
              <v-flex md3>
                <!--<v-btn color="warning" @click="parse_batch_upload">{{ $t('lang_upload_data') }}</v-btn>-->
                <v-btn
                  small
                  color="background"
                  @click="clear_uploaded_img_file"
                  >{{ $t("lang_clear") }}</v-btn
                >
              </v-flex>
            </v-layout>
          </v-container>

          <!-- choose lang -->
          <div style="margin: 20px 0 10px 0">
            <h3>{{ $t("lang_select_display_lang") }}</h3>
          </div>
          <v-container fluid grid-list-lg>
            <v-layout justify-left>
              <v-flex md6>
                <v-select
                  v-model="select_language"
                  :items="survey_languages"
                  :rules="[(v) => !!v || $t('lang_field_required')]"
                  :label="$t('lang_language')"
                  required
                ></v-select>
              </v-flex>
            </v-layout>
          </v-container>

          <div style="margin: 20px 0 10px 0">
            <v-btn color="primary" @click="render_questionnaire">{{
              $t("lang_preview_quest")
            }}</v-btn>
          </div>

          <div v-if="show_rendered">
            <div style="margin: 50px 0 10px 0">
              <h3>{{ $t("lang_assess_quest") }}</h3>
              <p>{{ $t("lang_checkpreviewimg") }}</p>
            </div>

            <!-- render questionnaire; show when json data is set -->
            <div style="margin: 20px 0 10px 0">
              <v-card>
                <v-container fluid grid-list-lg pa-5>
                  <RenderQuestionnairePage
                    :key="componentKey"
                    :jsondata_quest="jsondata_quest_send"
                    :chosen_lang="select_language"
                    :jsondata_img="jsondata_img_send"
                  />
                </v-container>
              </v-card>
            </div>

            <!-- Store questionnaire -->
            <v-container fluid grid-list-lg pa-5>
              <div style="margin: 40px 0 10px 0">
                <h3>{{ $t("lang_store_valquest") }}</h3>
              </div>
              <v-btn block color="background" @click="reset_page">{{
                $t("lang_clear")
              }}</v-btn>
              <div style="margin: 20px 0 10px 0">
                <v-btn
                  block
                  color="primary"
                  @click="store_questionnaire_template"
                  >{{ $t("lang_store_ques") }}</v-btn
                >
              </div>
            </v-container>
            <v-layout justify-center>
              <div v-if="show_progress">
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </div>
            </v-layout>
          </div>
        </div>
      </v-form>
    </v-container>
  </v-app>
</template>


<!--
Upload questionnaire component

This is the component to upload and store a questionnaire (in surveyJS format).
After this, the uploaded questionnaire can be selected to create a survey.

.. Copyright (C) 2020-2022 Information Technology for Translational Medicine S.A.
.. moduleauthor:: Kerstin Neininger
--> 
<script>
import i18n from "@/plugins/i18n";
import * as rules from "../assets/rules";
import * as services from "../../../services";
import RenderQuestionnairePage from "./RenderQuestionnairePage";
export default {
  name: "UploadQuestionnairePage",
  props: {
    jsondata: String, //send to render component
    chosen_lang: String,
    img_dict: Map,
  },
  components: {
    RenderQuestionnairePage,
  },
  data() {
    return {
      //send data from parent
      select_language: null,
      survey_languages: [],

      jsondata_quest_send: null,
      questionnaire_id: "",
      jsondata_img_send: {},
      show_progress: false,

      //render
      show_rendered: false,
      componentKey: 0,
    };
  },
  created() {},
  methods: {
    /**
     * Clear questionnaire file that was uploaded
     */
    clear_uploaded_file() {
      const upload_file = document.getElementById("upload_questionnaire_file");
      if (upload_file) {
        upload_file.value = null;
        this.clear_entries();
      }
    },

    /**
     * Clear image file that was uploaded
     */
    clear_uploaded_img_file() {
      const upload_file = document.getElementById("upload_images_file");
      if (upload_file) {
        upload_file.value = null;
        this.clear_entries();
      }
    },

    /**
     * Validate File size, throw error if invalid
     * @param {object} file - The file object that was uploaded
     */
    validate_file_size(file) {
      const file_size_kb = file.size / 1000;
      const valid_size = services.validUploadFileSize(
        file_size_kb / 1000, //MB
        "surveyJS"
      );
      if (!valid_size) {
        throw {
          type: "c4cError",
          message: i18n.t("lang_sizeinvalid"),
          code: 400,
        };
      }
    },

    /**
     * Upload questionnaire file for the survey. File object is taken from the HTML input field
     * @param {object} ev - The file object that was uploaded
     */
    read_questionnaire_from_upload(ev) {
      try {
        const file = ev.target.files[0];
        this.validate_file_size(file); //validate size

        //read file if size valid
        const reader = new FileReader();
        reader.onload = (ev) => {
          var text = ev.target.result;
          try {
            this.questionnaire_id = file.name.replace(".json", "");
            this.jsondata_quest_send = JSON.parse(text);

            //set available languages
            this.survey_languages = Object.keys(this.jsondata_quest_send.title);
            this.select_language = this.survey_languages.includes("en")
              ? "en"
              : this.survey_languages[0];
          } catch (error) {
            this.$modal.show1({
              title1: i18n.t("lang_failure"),
              text1: error,
            });
          }
        };
        reader.readAsText(file, "UTF-8");
      } catch (error_size) {
        this.$modal.show1({
          title1: i18n.t("lang_failure"),
          text1: error_size.message,
        });
      }
    },

    /**
     * Upload base64 images file for the survey. File object is taken from the HTML input field
     * @param {object} ev - The file object that was uploaded
     */
    read_images_from_upload(ev) {
      try {
        const file = ev.target.files[0];
        this.validate_file_size(file); //validate size

        //read file if size valid
        const reader = new FileReader();
        reader.onload = (ev) => {
          var text = ev.target.result;
          try {
            this.jsondata_img_send = JSON.parse(text);
          } catch (error) {
            this.$modal.show1({
              title1: i18n.t("lang_failure"),
              text1: error,
            });
          }
        };
        reader.readAsText(file, "UTF-8");
      } catch (error_size) {
        this.$modal.show1({
          title1: i18n.t("lang_failure"),
          text1: error_size.message,
        });
      }
    },

    //
    /**
     * Set json data and send to sub-component
     */
    render_questionnaire() {
      if (this.jsondata_quest_send) {
        this.forceRerender(); //Force render with new language
        this.show_rendered = true;
      } else {
        this.$modal.show1({
          title1: i18n.t("lang_warning"),
          text1: i18n.t("lang_nodatasel"),
        });
      }
    },

    /**
     * Force to re-render in case the language was changed. This is necessary to render a surveyJS questionnaire with a new language.
     */
    forceRerender() {
      this.componentKey += 1;
    },

    /**
     * Clear all entries of the uploaded files
     */
    clear_entries() {
      this.questionnaire_id = "";
      this.jsondata_quest_send = null;
      this.jsondata_img_send = null;

      this.show_rendered = false;
      this.survey_languages = [];
    },

    /**
     * Reset page variables
     */
    reset_page() {
      this.clear_uploaded_file();
      this.clear_uploaded_img_file();
    },

    /**
     * Store the uploaded questionnaire data
     */
    async fetch_store_quest() {
      try {
        this.show_progress = true;
        var payload = {
          questionnaire_public_id: this.questionnaire_id,
          data: this.jsondata_quest_send,
          image_data: this.jsondata_img_send ? this.jsondata_img_send : {},
        };
        await this.$store.dispatch("FETCH_STORE_QUESTIONNAIRE_TEMPLATE", {
          payload,
        });

        this.$modal.show2({
          title2: i18n.t("lang_success"),
          text2:
            i18n.t("lang_quest_uploaded_msg") +
            " '" +
            this.questionnaire_id +
            "'",
        });
        this.show_progress = false;
      } catch (error) {
        this.$modal.show1({
          title1: i18n.t("lang_failure"),
          text1: error,
        });
        this.show_progress = false;
      }
    },

    /**
     * Store uploaded questionnaire
     */
    async store_questionnaire_template() {
      if (this.$refs.form_upload_quest.validate()) {
        await this.fetch_store_quest();
        //reload after successful adding of a questionnaire
        await this.get_available_questionnaires();
        this.reset_page();
      }
    },
    async get_available_questionnaires() {
      await this.$store.dispatch("FETCH_GET_AVAILABLE_QUESTIONNAIRES");
    },
  },
  computed: {
    //survey_email_templates() {
    // return this.$store.getters.survey_email_templates;
    //},
    //survey_languages() {
    // return Object.keys(this.survey_email_templates);
    //},

    /**
     * @returns {array} - Set of rules for the questionnaire ID
     */
    questidRules() {
      return rules.questidRules;
    },
  },
  watch: {
    /**
     * Re-render the questionnaire in case the language was changed
     */
    select_language: function () {
      // only re-render when language changes and preview is already shown (show_rendered=true: this avoids preview when img file is not yet uploaded)
      if (this.select_language && this.show_rendered) {
        this.render_questionnaire();
      }
    },
    // this.clear_uploaded_file();
    // this.clear_uploaded_img_file();
    // this.questionnaire_id = "";
    //},
  },
};
</script>

<style scoped>
</style>