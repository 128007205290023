import { render, staticRenderFns } from "./IdleModal.vue?vue&type=template&id=2c54a169&scoped=true"
import script from "./IdleModal.vue?vue&type=script&lang=js"
export * from "./IdleModal.vue?vue&type=script&lang=js"
import style0 from "./IdleModal.vue?vue&type=style&index=0&id=2c54a169&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c54a169",
  null
  
)

export default component.exports