<template>
  <v-app>
    <v-card>
      <v-container fluid grid-list-lg>
        {{ show_plots }}
        <!-- Replies Table -->
        <v-container fluid grid-list-lg class="title">
          <v-form ref="form_repliestable">
            <v-card-title class="title">
              <v-text-field
                v-model="search"
                append-icon="search"
                :label="$t('lang_search')"
                single-line
                hide-details
              ></v-text-field>

              <!-- Action Button -->
              <v-layout row wrap justify-end>
                <v-flex shrink>
                  <v-icon
                    small
                    color="error"
                    :disabled="showloadingbtn_replies"
                    @click="request_deletion"
                  >
                    fas fa-trash-alt
                  </v-icon>
                </v-flex>
              </v-layout>
            </v-card-title>

            <!-- do not show select = disable boxes during loading of results, i.e. only allow select when not loading -->
            <v-data-table
              v-model="selected"
              :headers="headers"
              :items="entriesList"
              single-select
              item-key="surveyPublicId"
              :show-select="!showloadingbtn_replies"
              class="elevation-2"
              :sort-by="['surveyPublicId', 'questionnairePublicId']"
              :sort-desc="[false, false]"
              :search="search"
            >
            </v-data-table>

            <!-- Realod when data was changed in health management -->
            <v-layout justify-center>
              <v-btn text icon @click="reload_table">
                <v-icon>fas fa-sync-alt</v-icon>
              </v-btn>
            </v-layout>
          </v-form>
        </v-container>

        <div style="margin: 40px 0 10px 0" v-if="selectedRow">
          <v-container>
            <!-- meta data -->
            <p>
              <b>{{ $t("lang_survey_ID") }}: </b>
              {{ this.selectedRow.surveyPublicId }}
            </p>
            <p>
              <b>{{ $t("lang_ques_ID") }}: </b>
              {{ this.selectedRow.questionnairePublicId }}
            </p>
            <p>
              <b>{{ $t("lang_validdays") }}: </b> {{ this.validitytime_survey }}
            </p>
            <p>
              <b> {{ $t("lang_surveystartdate") }}: </b>
              {{ this.startdate_survey }}
            </p>
            <p>
              <b>{{ $t("lang_surveyenddate") }}:</b> {{ this.enddate_survey }}
            </p>
            <!-- switch to token dynamic in case several users should use the same ID -->
            <v-flex md4>
              <v-switch
                v-model="token_dynamic_mode"
                :label="$t('lang_token_switch')"
                readonly
              ></v-switch>
            </v-flex>

            <!-- loading button load replies -->
            <v-flex xs12 md12>
              <div class="text-xs-center" v-if="showloadingbtn_replies">
                <v-container>
                  <v-row
                    class="fill-height"
                    align-content="center"
                    justify="center"
                  >
                    <v-col class="text-subtitle-1 text-center" cols="12">
                      {{ $t("lang_inprogress") }}
                    </v-col>
                    <v-col cols="12">
                      <v-progress-linear
                        color="secondary"
                        indeterminate
                        rounded
                        height="25"
                      ></v-progress-linear>
                    </v-col>
                  </v-row>
                </v-container>
              </div>
            </v-flex>
          </v-container>

          <v-container>
            <!-- statistics -->
            <v-container>
              <!-- NOT dynamic mode -->
              <div v-if="show_plots">
                <v-layout justify-center>
                  <v-flex md6>
                    <div class="chart-container column">
                      <plotlycharts :chart="chartBarHistogram"></plotlycharts>
                    </div>
                  </v-flex>

                  <v-flex md6>
                    <div class="chart-container column">
                      <plotlycharts :chart="chartBarPie"></plotlycharts>
                    </div>
                  </v-flex>
                </v-layout>
              </div>

              <div
                v-if="!show_plots && !showloadingbtn_replies && !show_survey"
                style="margin: 200px 0 200px 0"
              >
                <v-layout justify-center>
                  <p>{{ $t("lang_not_replies_yet") }}</p>
                </v-layout>
              </div>
            </v-container>

            <!-- PDF -->
            <v-container>
              <embed
                id="pdfRefBinary"
                ref="pdfRefBinary"
                src=""
                type="application/pdf"
                style=""
                width="0"
                height="0"
              />

              <v-container fluid grid-list-lg v-if="show_render_upload">
                <h4>eSanté Connection</h4>
                <v-layout>
                  <v-btn color="secondary" @click="send_esante"
                    >Send PDF to eSanté<v-icon right dark
                      >fas fa-file-medical</v-icon
                    ></v-btn
                  >
                  <!-- loading button eSante -->
                  <v-flex xs12 md1>
                    <div class="text-xs-center" v-if="showloadingbtn_esante">
                      <v-progress-circular
                        indeterminate
                        color="secondary"
                      ></v-progress-circular>
                    </div>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-container>
          </v-container>

          <v-container v-if="show_plots">
            <!--<v-layout justify-left>-->
            <v-flex md12>
              <v-btn block color="primary" @click="export_survey_replies">{{
                $t("lang_btn_exportresults")
              }}</v-btn>
            </v-flex>
            <!--</v-layout>-->
            <div style="margin: 20px 0 10px 0">
              <p>
                {{ $t("lang_jsonformatterlink") }}
                <a href="https://jsonformatter.org/" target="_blank"
                  >JSON formatter</a
                >.
              </p>
            </div>
          </v-container>
        </div>
      </v-container>
    </v-card>

    <!-- Message 'Are you sure?' -->
    <v-dialog v-model="visibleDiagSure" max-width="400">
      <v-card>
        <v-card-title class="headline">
          <p id="diaghead">
            {{ $t("lang_diag_areyousure_head") }}
          </p></v-card-title
        >
        <v-card-text
          ><p id="diagbody">{{ textDiagSure }}</p></v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text small color="secondary" @click="closeDiagSure()">
            <p id="diagbody">{{ $t("lang_no") }}</p></v-btn
          >
          <!-- do not allow deletion during loading of replies -->
          <v-btn color="secondary" @click="survey_delete">
            <p id="diagbody">{{ $t("lang_yes") }}</p>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>



<!--
Survey replies/results component

This is the component to retrieve and display survey results based on the selected survey.

.. Copyright (C) 2020-2022 Information Technology for Translational Medicine S.A.
.. moduleauthor:: Kerstin Neininger
--> 
<script>
import i18n from "@/plugins/i18n";
import * as services from "../../../services";
import plotlycharts from "../../../assets/plotly-charts";
import * as plotting_services from "../../../assets/plotting_services.js";

import * as SurveyVue from "survey-vue";
import surveyTheme from "../../../assets/json/surveyTheme.json";

export default {
  name: "ReceiveQuestionnaireResponsePage",
  components: {
    plotlycharts,
    // Survey,
  },
  data() {
    return {
      //tables
      search: "",
      entriesList: [],
      selected: [],

      //metadata
      validitytime_survey: "",
      startdate_survey: "",
      enddate_survey: "",

      //answers in survey
      show_survey: false,
      survey: this.survey,
      survey_results: "",
      show_results_dual: false,

      //plotting
      show_plots: false,
      chartBarHistogram: {},
      chartBarPie: {},
      //eSante upload
      show_render_upload: false,
      showloadingbtn_esante: false,
      showloadingbtn_replies: false,

      //dialog
      visibleDiagSure: false,
      textDiagSure: "",
    };
  },
  created() {
    this.visualize_statistics(); //necessary as called in created() [plotly bug requires this]
  },
  mounted() {
    this.fill_table();
  },
  methods: {
    /**
     * Assign the retrieved questionnaires to the entries of the table
     */
    async fill_table() {
      await this.get_registred_surveys();
      this.entriesList = this.registered_surveys.surveys;
    },

    /**
     * Relod the table by querying the endpoint again
     */
    reload_table() {
      this.fill_table();
    },

    /**
     * Retrieve all available surveys from the database
     */
    async get_registred_surveys() {
      try {
        await this.$store.dispatch("FETCH_REGISTERED_SURVEYS");
      } catch (error) {
        this.$modal.show1({
          title1: i18n.t("lang_failure"),
          text1: error,
        });
      }
    },

    /**
     * Needed for deletion and give a warning in case no row was selected
     */
    request_action() {
      if (!this.selectedRow) {
        throw {
          title: i18n.t("lang_reminder"),
          message: i18n.t("lang_selectrow"),
        };
      }
    },

    /**
     * Request the deletion of an entry. This will open a dialog with OK to delete or CLOSE buttons.
     */
    request_deletion() {
      try {
        this.request_action();
        // reminder to confirm deletion
        this.showDiagSure({
          textDiagSure: i18n.t("lang_diag_areyousure_delete_body"),
        });
      } catch (error) {
        this.$modal.show1({
          title1: error.title,
          text1: error.message,
        });
      }
    },

    /**
     * Reset the selected rows
     */
    reset_selected_table() {
      this.selected = [];
    },

    /**
     * In case deletion is triggered, delete the survey from the database
     */
    async survey_delete() {
      this.closeDiagSure(); //close dialog that is  still open
      try {
        var payload = {
          survey_public_id: this.selectedRow.surveyPublicId,
          survey_uuid: this.selectedRow.surveyUuid,
        };
        //delete
        await this.$store.dispatch("FETCH_DELETE_REGISTERED_SURVEY", {
          payload,
        });
        //reload and uncheck to not show graphics
        this.reload_table();
        this.reset_selected_table();
        //message
        this.$modal.show1({
          title1: i18n.t("lang_success"),
          text1: i18n.t("lang_itemdeleted"),
        });
      } catch (error) {
        this.$modal.show1({
          title1: i18n.t("lang_failure"),
          text1: error,
        });
      }
    },

    /**
     * Close the deletion dialog
     */
    closeDiagSure() {
      this.visibleDiagSure = false;
    },
    /**
     * Open the deletion dialog
     * @param {object} params - The text to be displayed
     */
    showDiagSure(params) {
      this.visibleDiagSure = true;
      this.textDiagSure = params.textDiagSure;
    },

    /**
     * Retrieve and show the results for the selected survey and put it in a surveyJS format in order to add comments
     */
    async show_results() {
      this.reset_plotting();

      await this.get_all_survey_replies();

      if (this.all_survey_results) {
        this.render_DualPIF();
      }
    },

    /**
     * Display the results accordingly as sections/<p>
     */
    display_survey_metadata() {
      const startTimeUtc = this.selectedRow.startTimeUtc;
      const expirationTimeUtc = this.selectedRow.expirationTimeUtc;
      var validityTimeSeconds =
        services.dateTimeToEpoch(expirationTimeUtc) -
        services.dateTimeToEpoch(startTimeUtc);

      this.validitytime_survey = services.secondsToDays(validityTimeSeconds);
      this.startdate_survey = startTimeUtc;
      this.enddate_survey = expirationTimeUtc;
    },

    /**
     * Retrieve the results/stats for the selected survey
     */
    async get_all_survey_replies() {
      try {
        this.showloadingbtn_replies = true;
        var payload = {
          survey_public_id: this.selectedRow.surveyPublicId,
          survey_uuid: this.selectedRow.surveyUuid,
        };
        await this.$store.dispatch("FETCH_ALL_SURVEY_REPLIES", {
          payload,
        });
        this.showloadingbtn_replies = false;
      } catch (error) {
        this.$modal.show1({
          title1: i18n.t("lang_failure"),
          text1: error,
        });
        this.showloadingbtn_replies = false;
      }
    },

    /**
     * Display the results in a surveyJS and modify the results accordingly
     */
    render_DualPIF() {
      var json = this.all_survey_results.questionnaire;

      this.show_survey = false;
      this.survey = new SurveyVue.Model(json);
      this.survey.applyTheme(surveyTheme);
      this.survey.locale = this.all_survey_results.language; //this.chosen_lang; //"en"; //de, pt this.$i18n.locale;
      this.survey.data = this.all_survey_results.dualPIF;

    //   this.survey.onComplete.add(() => {
        var payload = {
          data: {
            language_chosen: this.all_survey_results.language,
            survey_results: this.survey.data,
          },
          survey_public_id: this.selectedRow.surveyPublicId,
          survey_uuid: this.selectedRow.surveyUuid,
        };

        this.post_answers(payload);
    //   });
    },

    async post_answers(payload) {
      try {
        this.showloadingbtn_replies = true;

        await this.$store.dispatch("FETCH_ALL_SURVEY_REPLIES_MODIF", {
          payload,
        });
        this.showloadingbtn_replies = false;

        this.show_survey = true;
        this.show_plots = true;

        this.display_survey_metadata();
        this.visualize_statistics();
        // this.render_pdf();
      } catch (error) {
        this.$modal.show1({
          title1: i18n.t("lang_failure"),
          text1: error,
        });
      }
    },

    // render base64 to PDF
    render_pdf() {
      if (this.show_plots) {
        const FHIRdocumentReference = this.all_survey_results.replies_pdf;
        const type =
          FHIRdocumentReference["content"][0]["attachment"]["contentType"];
        const data = FHIRdocumentReference["content"][0]["attachment"]["data"];

        // get binary data
        if (type == "application/pdf") {
          this.$refs.pdfRefBinary.width = "1000";
          this.$refs.pdfRefBinary.height = "800";
          this.$refs.pdfRefBinary.style = "border: 1px solid #000";
          this.$refs.pdfRefBinary.src = "data:application/pdf;base64," + data;
        }
        this.show_render_upload = true;
      } else {
        this.reset_refspdf();
        this.show_render_upload = false;
      }
    },

    // reset PDF rendering
    reset_refspdf() {
      if (this.$refs.pdfRefBinary) {
        this.$refs.pdfRefBinary.src = "";
        this.$refs.pdfRefBinary.width = "0";
        this.$refs.pdfRefBinary.height = "0";
      }
    },

    // send PDF to eSante
    send_esante() {
      const selected_resource = JSON.stringify(
        this.all_survey_results.replies_pdf
      );
      const resourceType = this.all_survey_results.resourceType; //TODO JSON.parse(selected_resource)["resourceType"];
      if (resourceType == "DocumentReference") {
        this.showloadingbtn_esante = true;
        /* eslint-disable */
        console.log(selected_resource);
        this.$modal.show1({
          title1: i18n.t("lang_warning"),
          //text1: i18n.t("lang_pdf_fhir"),
          text1: "Connection not yet available.", //i18n.t("lang_nodatasel"),
        });
        this.showloadingbtn_esante = false;
      } else {
        this.$modal.show1({
          title1: i18n.t("lang_failure"),
          text1:
           i18n.t("lang_pdf_fhir"),
        });
      }
    },

    /**
     * Export the retrieved results as JSON and TSV
     */
    export_survey_replies() {
      //json
      services.saveTextAsFile(
        JSON.stringify(this.all_survey_results.replies_json),
        "export_replies.json"
      );

      //table
      services.saveTextAsFile(
        this.all_survey_results.replies_table,
        "export_replies.tsv"
      );

      //pdf
    //   services.savePDF(this.$refs.pdfRefBinary.src, "export_replies.pdf");
    },

    /**
     * Export the survey files (surveyJS) as JSON
     */
    export_survey_content() {
      //metadata
      services.saveTextAsFile(
        JSON.stringify(this.all_survey_results.survey),
        "export_survey_meta.json"
      );

      //survey content
      services.saveTextAsFile(
        JSON.stringify(this.all_survey_results.survey),
        "export_survey_content.json"
      );
    },

    /**
     * Dependent if dynamic or not, different stats/charts are shown
     */
    visualize_statistics() {
      const chart_vals_pie = this.token_dynamic_mode
        ? {}
        : this.visualize_piechart();
      const chart_vals_histogram = this.token_dynamic_mode
        ? this.visualize_barchart_dynamic()
        : this.visualize_barchart_notdynamic();

      if (chart_vals_histogram.yvals[0] > 0) {
        this.show_plots = true;

        this.chartBarPie = plotting_services.plotly_piechart(
          "chartBarPie",
          chart_vals_pie
        );

        this.chartBarHistogram = plotting_services.plotly_barchart(
          "chartBarHistogram",
          chart_vals_histogram
        );
      } else {
        this.show_plots = false;
      }
    },

    /**
     * Visualization of results as barchart for dynamic mode
     * @returns {object} - The parameters needed to plot the chart
     */
    visualize_barchart_dynamic() {
      //necessary as called in created() [plotly bug requires this]
      var plot_values = [];
      var xvals_list = [];
      var number_of_replies = 0;
      //set values if exists, otherwise init values
      if (this.all_survey_results) {
        number_of_replies = this.all_survey_results.stats.nbAnonymousReplies;
        plot_values = [number_of_replies];
        xvals_list = [i18n.t("lang_submittedreplies")];
      }

      var hoverlist = [];
      for (var j = 0; j < xvals_list.length; j++) {
        var str_hover =
          "<b>" +
          xvals_list[j] +
          "</b><br /><b>" +
          plot_values[j].toString() +
          "</b> " +
          i18n.t("lang_replies");
        hoverlist.push(str_hover);
      }

      var chart_vals = {
        title:
          i18n.t("lang_statistics") +
          ". " +
          i18n.t("lang_submittedreplies") +
          ": " +
          number_of_replies.toString(),
        hover: hoverlist,
        xvals: xvals_list,
        yvals: plot_values,
        yrange: [0, Math.max(...plot_values)],
        ytitle: "",
        colors: ["#f1f9f9"], //"#e9b2bc", "#f3dfe3",
      };

      return chart_vals;
    },

    /**
     * Visualization of results as barchart for nondynamic mode
     * @returns {object} - The parameters needed to plot the chart
     */
    visualize_barchart_notdynamic() {
      //necessary as called in created() [plotly bug requires this]
      var plot_values = [];
      var xvals_list = [];
      var number_of_replies = 0;
      var number_of_expected_replies = 0;
      //set values if exists, otherwise init values
      if (this.all_survey_results) {
        number_of_replies = this.all_survey_results.stats.nbAnonymousReplies;
        number_of_expected_replies =
          this.all_survey_results.stats.nbExpectedAnonymousReplies;
        plot_values = [number_of_replies, number_of_expected_replies];
        xvals_list = [
          i18n.t("lang_submittedreplies"),
          i18n.t("lang_expectedreplies"),
        ];
      }

      var hoverlist = [];
      for (var j = 0; j < xvals_list.length; j++) {
        var str_hover =
          "<b>" +
          xvals_list[j] +
          "</b><br /><b>" +
          plot_values[j].toString() +
          "</b> " +
          i18n.t("lang_replies");
        hoverlist.push(str_hover);
      }

      var chart_vals = {
        title:
          i18n.t("lang_statistics") +
          ". " +
          i18n.t("lang_submittedreplies") +
          ": " +
          number_of_replies.toString() +
          "/" +
          number_of_expected_replies.toString(),
        hover: hoverlist,
        xvals: xvals_list,
        yvals: plot_values,
        yrange: [0, Math.max(...plot_values)],
        ytitle: "",
        colors: ["#f1f9f9", "#e1f2fb"], //"#e9b2bc", "#f3dfe3",
      };

      return chart_vals;
    },

    /**
     * Visualization of results as piechart
     * @returns {object} - The parameters needed to plot the chart
     */
    visualize_piechart() {
      //necessary as called in created() [plotly bug requires this]
      var plot_values = [];
      var xvals_list = [];
      var number_of_replies = 0;
      var number_of_expected_replies = 0;
      var submitted_perc = 0;
      var open_perc = 0;
      //set values if exists, otherwise init values
      if (this.all_survey_results) {
        number_of_replies = this.all_survey_results.stats.nbAnonymousReplies;
        number_of_expected_replies =
          this.all_survey_results.stats.nbExpectedAnonymousReplies;

        if (number_of_expected_replies != 0) {
          const perc = (number_of_replies / number_of_expected_replies) * 100;
          submitted_perc = Math.round(perc * 10) / 10; //round 1 decimmal place
          open_perc = 100 - submitted_perc;
        }

        plot_values = [submitted_perc, open_perc];
        xvals_list = [
          i18n.t("lang_submittedreplies"),
          i18n.t("lang_pendingreplies"),
        ];
      }

      var hoverlist = [];
      for (var j = 0; j < xvals_list.length; j++) {
        var str_hover =
          "<b>" +
          xvals_list[j] +
          "</b><br /><b>" +
          plot_values[j].toString() +
          "%</b> ";
        hoverlist.push(str_hover);
      }

      var chart_vals = {
        title:
          i18n.t("lang_statistics") +
          ". " +
          i18n.t("lang_submittedreplies") +
          ": " +
          submitted_perc.toString() +
          "%",
        values: plot_values,
        labels: [
          i18n.t("lang_submittedreplies"),
          i18n.t("lang_pendingreplies"),
        ],
        hover: hoverlist,
        colors: ["#f1f9f9", "#e1f2fb"], //"#e9b2bc", "#f3dfe3",
      };

      return chart_vals;
    },

    reset_plotting() {
      this.show_plots = false;
      this.chartBarHistogram = {};
      this.chartBarPie = {};
      this.show_render_upload = false;
      this.showloadingbtn_esante = false;
      this.showloadingbtn_replies = false;
      this.show_survey = false;
      this.show_results_dual = false;
      this.reset_refspdf();
    },
  },
  computed: {
    /**
     * @returns {object} - The selected row
     */
    selectedRow() {
      return this.selected[0];
    },
    /**
     * @returns {array} - The headers of the datatable
     */
    headers() {
      return [
        {
          text: this.$t("lang_survey_ID"),
          value: "surveyPublicId",
          sortable: true,
        },
        {
          text: this.$t("lang_ques_ID"),
          value: "questionnairePublicId",
          sortable: true,
        },
        {
          text: this.$t("lang_surveystartdate"),
          value: "startTimeUtc",
          sortable: true,
        },
        {
          text: this.$t("lang_surveyenddate"),
          value: "expirationTimeUtc",
          sortable: true,
        },
        {
          text: this.$t("lang_survey_uuid"),
          value: "surveyUuid",
          sortable: true,
        },
      ];
    },
    /**
     * @returns {object} - The registered surveys
     */
    registered_surveys() {
      return this.$store.getters.registered_surveys;
    },
    /**
     * @returns {object} - The survey results
     */
    all_survey_results() {
      return this.$store.getters.all_survey_results;
    },

    /**
     * @returns {boolean} - True, if the survey is dynamic, else False
     */
    token_dynamic_mode() {
      if (
        this.selectedRow &&
        Object.keys(this.registered_surveys).length != 0
      ) {
        return this.registered_surveys["survey_map"][
          this.selectedRow.surveyPublicId
        ]["token_dynamic_mode"];
      }
      return false;
    },
  },
  watch: {
    /**
     * Render the questionnaire in case a row is selected
     */
    selectedRow: function () {
      if (this.selectedRow) {
        this.show_results();
      } else {
        this.reset_plotting();
      }
    },
  },
};
</script>

<style scoped>
</style>