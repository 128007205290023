//colors
export var color_green = "#0c9463"
export var color_orange = "#ffa41b"
export var color_red = "#c81912"

/*
Functionalities for the following plots:
lineplot, scatterplot, radarplot, piechart
*/

export function sort_lists(xvals, yvals) {
  var list = [];
  for (var j = 0; j < xvals.length; j++)
    list.push({ 'xval': xvals[j], 'yval': yvals[j] });

  list.sort(function (a, b) {
    return ((a.xval < b.xval) ? -1 : ((a.xval == b.xval) ? 0 : 1));
  });

  for (var k = 0; k < list.length; k++) {
    xvals[k] = list[k].xval;
    yvals[k] = list[k].yval;
  }
  return { "xvals": xvals, "yvals": yvals }
}


//extract x ad y arrays from in table selected entries
export function get_xy_from_selected(selected_array, firstcol, secondcol) {
  var xvals = []
  var yvals = []
  for (var i = 0; i < selected_array.length; i++) {
    var firstval = selected_array[i][firstcol];
    var secondval = selected_array[i][secondcol];
    if (typeof firstval != "undefined" & typeof secondval != "undefined") {
      xvals.push(firstval)
      yvals.push(secondval)
    }

  }
  //sort after date
  var sort_res = sort_lists(xvals, yvals)
  return { "xvals": sort_res["xvals"], "yvals": sort_res["yvals"] }
  //return { "xvals": xvals, "yvals": yvals }
}

export function plotly_lineplot(chart_id, chart_vals) {
  var chart = {
    id: chart_id,
    data: [
      {
        x: chart_vals["xvals"],
        y: chart_vals["yvals"],
        hovertext: chart_vals["hover"],
        hoverinfo: "text",
        line: {
          color: "#387FC1",
          width: 2,
          shape: "line"
        }
      }
    ],
    layout: {
      title: chart_vals["title"],
      xaxis: {
        range: chart_vals["xrange"],
        dtick: 1,
        autorange: false,
        title: chart_vals["xtitle"],
        tickvals: chart_vals["tickvals"],
        ticktext: chart_vals["ticktext"]
      },
      yaxis: {
        range: chart_vals["yrange"],
        title: chart_vals["ytitle"]
      }
    }
  };

  return chart;
}

export function get_dot_sizes(color_array) {
  var size_dots_array = [];
  var s = 0;
  for (var i = 0; i < color_array.length; i++) {
    if (color_array[i] == color_red) {
      s = 25
    } else if (color_array[i] == color_orange) {
      s = 20
    } else {
      s = 15
    }
    size_dots_array.push(s);
  }
  return size_dots_array;
}

export function get_dot_colors(yvals_array) {
  var colors_dots_array = [];
  var s = 0;
  for (var i = 0; i < yvals_array.length; i++) {
    if (yvals_array[i] <= 3) {
      s = color_green
    } else if (yvals_array[i] > 3 & yvals_array[i] <= 7) {
      s = color_orange
    } else {
      s = color_red
    }
    colors_dots_array.push(s);
  }
  return colors_dots_array;
}

export function plotly_lineplot_colored(chart_id, chart_vals) {
  //dot colors and sizez
  var yvals_array = chart_vals["yvals"];
  var colors_dots_array = get_dot_colors(yvals_array);
  var size_dots_array = get_dot_sizes(colors_dots_array);

  var chart = {
    id: chart_id,
    data: [
      {
        x: chart_vals["xvals"],
        y: yvals_array,
        hovertext: chart_vals["hover"],
        hoverinfo: "text",
        line: {
          color: chart_vals["color_line"],
          width: 2,
          shape: "line"
        },
        marker: {
          color: colors_dots_array,
          size: size_dots_array
        }
      }
    ],
    layout: {
      title: chart_vals["title"],
      xaxis: {
        range: chart_vals["xrange"],
        dtick: 1,
        autorange: false,
        title: chart_vals["xtitle"],
        tickvals: chart_vals["xtickvals"],
        ticktext: chart_vals["xticktext"]
      },
      yaxis: {
        range: chart_vals["yrange"],
        tickvals: chart_vals["ytickvals"],
        ticktext: chart_vals["yticktext"],
        title: chart_vals["ytitle"]
      }
    }
  };

  return chart;
}

export function plotly_scatterplot(chart_id, chart_vals) {
  var chart = {
    id: chart_id,
    data: [
      {
        x: chart_vals["xvals"],
        y: chart_vals["yvals"],
        mode: "markers",
        type: "scatter",
        marker: {
          size: 8
        }
      }
    ],
    layout: {
      title: chart_vals["title"],
      xaxis: {
        range: chart_vals["xrange"],
        title: chart_vals["xtitle"]
      },
      yaxis: {
        range: chart_vals["yrange"],
        title: chart_vals["ytitle"]
      }
    }
  };
  return chart;
}

export function plotly_radarplot(chart_id, chart_vals) {
  var data_plot = chart_vals["data"];
  var data_vals = [];
  for (var i = 0; i < Object.keys(data_plot).length; i++) {
    var data_entry = {
      name: data_plot[i]["name"],
      r: data_plot[i]["rvals"],
      theta: data_plot[i]["theta"],
      fill: data_plot[i]["fill"],
      type: "scatterpolar",
      marker: {
        size: 8
      }
    };
    data_vals.push(data_entry);
  }

  var chart = {
    id: chart_id,
    data: data_vals,

    layout: {
      title: "",
      height: 500,
      width: 500,
      polar: {
        radialaxis: {
          visible: true
          //range: [0, 5]
        }
      },
      showlegend: true,
    }
  };

  chart.layout.title = chart_vals["title"];
  return chart;
}

export function plotly_piechart(chart_id, chart_vals) {
  var chart = {
    id: chart_id,
    data: [
      {
        values: chart_vals["values"],
        type: "pie",
        labels: chart_vals["labels"],
        hovertext: chart_vals["hover"],
        hoverinfo: "text",
        hole: .3,
        marker: {
          colors: chart_vals["colors"],
        }
      }
    ],
    layout: {
      title: chart_vals["title"],
      //height: 600,
      //width: 600,
      showlegend: true
    }
  };
  return chart;
}

export function plotly_barchart(chart_id, chart_vals) {
  var chart = {
    id: chart_id,
    data: [
      {
        x: chart_vals["xvals"],
        y: chart_vals["yvals"],
        type: "bar",
        hovertext: chart_vals["hover"],
        hoverinfo: "text",
        marker: {
          color: chart_vals["colors"]
        }
      }
    ],
    layout: {
      title: chart_vals["title"],
      //height: 600,
      //width: 600,
      //xaxis: {
      //  range: chart_vals["xrange"],
      //  title: chart_vals["xtitle"]
      //},
      yaxis: {
        range: chart_vals["yrange"],
        title: chart_vals["ytitle"]
      }
    }
  };
  return chart;
}