<template>
  <v-app>
    <v-card>
      <v-container fluid grid-list-lg pa-5>
        <!-- User Table -->
        <v-container fluid grid-list-lg class="title">
          <v-form ref="form_usertable">
            <v-card-title class="title">
              <v-text-field
                v-model="search"
                append-icon="search"
                :label="$t('lang_search')"
                single-line
                hide-details
              ></v-text-field>

              <!-- Action Button -->
              <v-layout row wrap justify-end>
                <v-flex shrink>
                  <v-icon small color="error" @click="request_deletion">
                    fas fa-trash-alt
                  </v-icon>
                </v-flex>
              </v-layout>
            </v-card-title>

            <v-data-table
              v-model="selected"
              :headers="headers"
              :items="entriesList"
              single-select
              item-key="publicId"
              show-select
              class="elevation-2"
              :sort-by="['role', 'roleLevel']"
              :sort-desc="[false, false]"
              :search="search"
            >
            </v-data-table>

            <!-- Realod when data was changed in health management -->
            <v-layout justify-center>
              <v-btn text icon @click="reload_table">
                <v-icon>fas fa-sync-alt</v-icon>
              </v-btn>
            </v-layout>
          </v-form>
        </v-container>

        <!--
        <v-container fluid grid-list-lg pa-5>
          {{ this.selectedRowcontent }}
        </v-container>
        -->

        <!-- EDIT More information to edit; do not show for anonymous user -->
        <div v-if="selectedRow && isIdentifiedUser">
          <v-container fluid grid-list-lg pa-5>
            <v-container fluid grid-list-lg>
              <v-layout justify-left>
                <v-flex xs12 md6>
                  <v-select
                    v-model="role_selected"
                    :items="user_roles_choice"
                    :rules="[(v) => !!v || $t('lang_field_required')]"
                    :label="$t('lang_roles')"
                    required
                    :disabled="user_role_isOPE"
                  ></v-select>

                  <v-select
                    v-model="roleLevel_selected"
                    :items="user_level_choice"
                    :rules="[(v) => !!v || $t('lang_field_required')]"
                    :label="$t('lang_level')"
                    required
                    :disabled="user_role_isOPE"
                  ></v-select>

                  <v-select
                    v-model="lang_selected"
                    :items="user_lang_choice"
                    :rules="[(v) => !!v || $t('lang_field_required')]"
                    :label="$t('lang_defaultlang')"
                    required
                  ></v-select>
                </v-flex>
              </v-layout>
            </v-container>

            <!-- ACCESS RIGHTS -->
            <v-container fluid grid-list-lg>
              <h4>{{ $t("lang_access_rights") }}</h4>
              <v-layout justify-left>
                <v-flex xs12 md3>
                  <v-switch
                    v-model="platform_access"
                    color="primary"
                    :label="$t('lang_platform_access')"
                  ></v-switch>
                  <v-switch
                    v-model="accessmodule_main"
                    color="secondary"
                    disabled
                    :label="$t('lang_access_module') + ` MAIN`"
                  ></v-switch>

                  <v-switch
                    v-model="accessmodule_user"
                    color="secondary"
                    disabled
                    :label="$t('lang_access_module') + ` USER`"
                  ></v-switch>
                </v-flex>
                <v-flex xs12 md3>
                  <v-switch
                    v-model="accessmodule_survey"
                    color="secondary"
                    :disabled="user_role_isOPE"
                    :label="$t('lang_access_module') + ` SURVEY`"
                  ></v-switch>
                  <v-switch
                    v-model="accessmodule_ehr"
                    color="secondary"
                    :disabled="user_role_isOPE"
                    :label="$t('lang_access_module') + ` EHR`"
                  ></v-switch>
                  <v-switch
                    v-model="accessmodule_sensor"
                    color="secondary"
                    :disabled="user_role_isOPE"
                    :label="$t('lang_access_module') + ` SENSOR`"
                  ></v-switch>
                </v-flex>
                <v-flex xs12 md3>
                  <v-switch
                    v-model="accessmodule_communication"
                    color="secondary"
                    :disabled="user_role_isOPE"
                    :label="$t('lang_access_module') + ` COMMUNICATION`"
                  ></v-switch>

                  <v-switch
                    v-model="accessmodule_report"
                    color="secondary"
                    :disabled="user_role_isOPE"
                    :label="$t('lang_access_module') + ` REPORT`"
                  ></v-switch>
                </v-flex>
              </v-layout>
            </v-container>
            <!-- openEHR -->
            <v-container fluid grid-list-lg>
              <v-switch
                v-model="openEHR_access"
                :label="
                  $t('lang_openEHR_access') + `: ${openEHR_access.toString()}`
                "
              ></v-switch>
            </v-container>

            <!-- Buttons -->
            <div style="margin: 50px 0 10px 0">
              <v-layout justify-left>
                <v-flex xs12 md2>
                  <v-btn color="primary" @click="request_edit_save">
                    {{ $t("lang_update") }}
                    <!--<v-icon>fas fa-save</v-icon>-->
                  </v-btn>
                </v-flex>
              </v-layout>
            </div>
          </v-container>
        </div>
      </v-container>
    </v-card>

    <!-- Message 'Are you sure?' -->
    <v-dialog v-model="visibleDiagSure" max-width="400">
      <v-card>
        <v-card-title class="headline">
          <p id="diaghead">
            {{ $t("lang_diag_areyousure_head") }}
          </p></v-card-title
        >
        <v-card-text
          ><p id="diagbody">{{ textDiagSure }}</p></v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text small color="secondary" @click="closeDiagSure()">
            <p id="diagbody">{{ $t("lang_no") }}</p></v-btn
          >
          <v-btn color="secondary" @click="user_delete">
            <p id="diagbody">{{ $t("lang_yes") }}</p>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<!--
User overiew component

This is the component to display all available users.
Dependend on the role (OPE, admin), they differ of course.

.. Copyright (C) 2020-2022 Information Technology for Translational Medicine S.A.
.. moduleauthor:: Kerstin Neininger
-->
<script>
import i18n from "@/plugins/i18n";
import * as services from "../../../services";
export default {
  name: "UserOverviewPage",

  data() {
    return {
      //tables
      search: "",
      entriesList: [],
      selected: [],

      //fields
      role_selected: null,
      roleLevel_selected: null,
      lang_selected: null,

      platform_access: false,
      openEHR_access: false,

      //AccessToModules
      accessmodule_main: false,
      accessmodule_user: false,
      accessmodule_survey: false,
      accessmodule_ehr: false,
      accessmodule_sensor: false,
      accessmodule_communication: false,
      accessmodule_report: false,

      //dialog
      visibleDiagSure: false,
      textDiagSure: "",
    };
  },
  methods: {
    /**
     * Assign the retrieved users to the entries of the table
     */
    async fill_table() {
      //await this.get_user_users();
      if (this.user_users) {
        this.entriesList = this.user_users.users;
      }
    },

    /**
     * Reload the table by querying the respective endpoint
     */
    async reload_table() {
      await this.get_user_users();
      this.fill_table();
    },

    /**
     * Retrieve all users
     */
    async get_user_users() {
      try {
        await this.$store.dispatch("FETCH_USER_USERS");
      } catch (error) {
        this.$modal.show1({
          title1: i18n.t("lang_failure"),
          text1: error,
        });
      }
    },
    /**
     * Needed for deletion and give a warning in case no row was selected
     */
    request_action() {
      if (!this.selectedRow) {
        throw {
          title: i18n.t("lang_reminder"),
          message: i18n.t("lang_selectrow"),
        };
      }
    },

    /**
     * Request the deletion of an entry. This will open a dialog with OK to delete or CLOSE buttons.
     */
    request_deletion() {
      try {
        this.request_action();
        // reminder to confirm deletion
        this.showDiagSure({
          textDiagSure: i18n.t("lang_diag_areyousure_delete_body"),
        });
      } catch (error) {
        this.$modal.show1({
          title1: error.title,
          text1: error.message,
        });
      }
    },

    /**
     * In case deletion is triggered, delete the user from the database
     */
    async user_delete() {
      this.closeDiagSure(); //close dialog that is  still open
      try {
        var payload = {
          publicId: this.selectedRow.publicId,
        };
        await this.$store.dispatch("FETCH_USER_DELETE", { payload });
        this.$modal.show1({
          title1: i18n.t("lang_success"),
          text1: i18n.t("lang_itemdeleted"),
        });
        this.reload_table();
      } catch (error) {
        this.$modal.show1({
          title1: i18n.t("lang_failure"),
          text1: error,
        });
      }
    },

    /**
     * Update the user information
     */
    request_edit_save() {
      try {
        this.request_action();
        this.user_edit_save();
        if (this.openEHR_access) {
          this.signup_openEHR();
        }
      } catch (error) {
        this.$modal.show1({
          title1: error.title,
          text1: error.message,
        });
      }
    },

    /**
     * Store the updated information
     */
    async user_edit_save() {
      try {
        var payload = {
          publicId: this.selectedRow.publicId,
          data: {
            role: {
              role: this.role_selected,
              role_level: this.roleLevel_selected,
            },
            access_to_modules: {
              main: this.accessmodule_main,
              user: this.accessmodule_user,
              survey: this.accessmodule_survey,
              ehr: this.accessmodule_ehr,
              sensor: this.accessmodule_sensor,
              communication: this.accessmodule_communication,
              report: this.accessmodule_report,
            },
            default_language: this.lang_selected,
            platform_access: this.platform_access,
            openEHR_access: this.openEHR_access,
          },
        };
        await this.$store.dispatch("FETCH_USER_EDIT", { payload });
        this.$modal.show1({
          title1: i18n.t("lang_success"),
          text1: i18n.t("lang_itemupdated"),
        });
        this.reload_table();
      } catch (error) {
        this.$modal.show1({
          title1: i18n.t("lang_failure"),
          text1: error,
        });
      }
    },

    /**
     * Signup openEHR (backend is connected to their library)
     */
    async signup_openEHR() {
      try {
        var payload = {
          public_id: this.selectedRow.publicId,
        };
        await this.$store.dispatch("FETCH_OPENEHR_CREATE_EHR", { payload });
        this.$modal.show1({
          title1: i18n.t("lang_success"),
          text1: i18n.t("lang_itemadded"),
        });
      } catch (error) {
        this.$modal.show1({
          title1: i18n.t("lang_failure"),
          text1: error,
        });
      }
    },

    /**
     * Close the deletion dialog
     */
    closeDiagSure() {
      this.visibleDiagSure = false;
    },
    /**
     * Open the deletion dialog
     * @param {object} params - The text to be displayed
     */
    showDiagSure(params) {
      this.visibleDiagSure = true;
      this.textDiagSure = params.textDiagSure;
    },

    /**
     * After row selection, show details
     */
    show_row_details() {
      //retrieve from what is displayed in table
      this.role_selected = this.selectedRow.role;
      this.roleLevel_selected = this.selectedRow.roleLevel;
      this.lang_selected = this.selectedRow.defaultLanguage;

      //retrieve data not displayed in the table
      const publicId = this.selectedRow.publicId;
      const rowData = services.geTableRowData(
        this.entriesList,
        "publicId",
        publicId
      );

      //as retrieved from the table, should actually always exist
      if (rowData) {
        if (rowData.modulesAccess) {
          const rowATM = rowData.modulesAccess;
          this.accessmodule_main = rowATM.main;
          this.accessmodule_user = rowATM.user;
          this.accessmodule_survey = rowATM.survey;
          this.accessmodule_ehr = rowATM.ehr;
          this.accessmodule_sensor = rowATM.sensor;
          this.accessmodule_communication = rowATM.communication;
          this.accessmodule_report = rowATM.report;
        }
        this.platform_access = rowData.platformAccess
          ? rowData.platformAccess
          : false;
        this.openEHR_access = rowData.openEHRAccess
          ? rowData.openEHRAccess
          : false;
      }
    },
  },
  created() {
    this.fill_table();
  },
  //  mounted() {
  //  this.fill_table();
  //},
  watch: {
    selectedRow: function () {
      if (this.selectedRow) {
        this.show_row_details();

        //set the openehr id for storing data etc.
        this.$store.commit("SET_USER_OPENEHRID", {
          user_openEHR_id: this.selectedRow.openEHRId,
        });

        // set user public id
        this.$store.commit("SET_SELECTED_USER_PUBLICID", {
          selected_user_public_id: this.selectedRow.publicId,
        });
      } else {
        this.$store.commit("SET_USER_OPENEHRID", {
          user_openEHR_id: "",
        });
        this.$store.commit("SET_SELECTED_USER_PUBLICID", {
          selected_user_public_id: "",
        });
      }
    },
  },
  computed: {
    /**
     * @returns {boolean} - True, if the user is OPE, else false
     */
    user_role_isOPE() {
      return this.$store.getters.user_role_isOPE;
    },
    /**
     * @returns {boolean} - True, if the user is admin, else false
     */
    user_role_isAdmin() {
      return this.$store.getters.user_role_isAdmin;
    },
    /**
     * @returns {array} - List of users
     */
    user_users() {
      return this.$store.getters.user_users;
    },
    /**
     * @returns {string} - The user role, e.g. OPE
     */
    user_role() {
      return this.$store.getters.user_role;
    },
    /**
     * @returns {string} - The language selected by the user
     */
    user_lang() {
      return this.$store.getters.user_lang;
    },
    /**
     * @returns {array} - The list of available user roles
     */
    user_roles_choice() {
      return this.$store.getters.user_roles_choice;
    },
    /**
     * @returns {array} - The list of available user levels
     */
    user_level_choice() {
      return this.$store.getters.user_level_choice;
    },
    /**
     * @returns {array} - The list of available languages
     */
    user_lang_choice() {
      return this.$store.getters.user_lang_choice;
    },
    /**
     * @returns {boolean} - True, if the user not a token ("identified"), else False. This is needed to filter the table.
     */
    isIdentifiedUser() {
      return this.selectedRow.description == "identified";
    },
    /**
     * @returns {string} - The user openEHR ID as assigned by the openEHR library
     */
    user_openEHR_id() {
      return this.$store.getters.user_openEHR_id;
    },

    //select row in table and display content
    /*
    selectedRowcontent() {
      if (this.selectedRow) {
        return `${this.selectedRow.publicId}, ${this.selectedRow.role}, ${this.selectedRow.roleLevel} `;
      } else {
        return i18n.t("lang_nodatasel");
      }
    },
    */

    /**
     * @returns {object} - The selected row
     */
    selectedRow() {
      return this.selected[0];
    },

    /**
     * @returns {array} - The headers of the datatable
     */
    headers() {
      return [
        {
          text: this.$t("lang_description"),
          value: "description",
          sortable: true,
        },
        {
          text: this.$t("lang_username"),
          value: "publicId",
          sortable: true,
        },
        {
          text: this.$t("lang_role"),
          value: "role",
          sortable: true,
        },
        {
          text: this.$t("lang_level"),
          value: "roleLevel",
          sortable: true,
        },
        {
          text: this.$t("lang_organisation"),
          value: "organisation",
          sortable: true,
        },
        {
          text: this.$t("lang_defaultlang"),
          value: "defaultLanguage",
          sortable: true,
        },
        {
          text: this.$t("lang_openEHR_access"),
          value: "openEHRAccess",
          sortable: true,
        },
        {
          text: this.$t("lang_openehr_id"),
          value: "openEHRId",
          sortable: true,
        },
        {
          text: this.$t("lang_date"),
          value: "dateCreatedUtc",
          sortable: true,
        },
      ];
    },
  },
};
</script>

<style scoped>
</style>