<template>
  <div class="container">
      <v-container fluid grid-list-lg pa-5>
        <div v-if="moduleIsReady">
          <EHROverviewModule />
        </div>
        <div v-else>Loading profile...</div>        
      </v-container>
  </div>
</template>

<script>
import EHROverviewModule from "./EHROverviewModule";

export default {
  name: "EHRModule",
  components: {
    EHROverviewModule,
  },
  created() {
    //this.bootstrap();
  },
  computed: {
    moduleIsReady() {
      return true;
    },

  },

  methods: {
    /**
     * Fetch data from backend
     * Set state.user
     */
    //async bootstrap() {
    //  const id = this.$route.params.id;
     // await this.$store.dispatch("FETCH_SENSOR_BY_ID", { id });
    //},
  },
};
</script>

<style scoped>
.container {
  max-width: 1920px;
  width: 100%;
}
</style>