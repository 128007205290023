<template>
  <v-app>
    <v-card>
      <v-container fluid grid-list-lg>
        <!-- Retrieve data with existing publicId -->
        <v-container fluid grid-list-lg pa-5>
          <v-flex xs12 md8>
            <v-text-field
              v-model="selected_user_public_id"
              :label="$t('lang_username')"
              required
              readonly
            ></v-text-field>
          </v-flex>
        </v-container>
        <div v-if="valid_selected_user_public_id">
          <!-- <div v-if="moduleIsReady">-->
          <div style="margin: 40px 0 40px 0">
            <v-expansion-panels v-model="panel" popout focusable multiple>
              <!-- create survey -->
              <v-expansion-panel>
                <v-expansion-panel-header
                  >{{ $t("lang_register_survey_PROMPREM") }}
                  <template v-slot:actions>
                    <v-icon color="primary">$expand</v-icon>
                  </template>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                  <CreatePROMPREMPage />
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- Assign participants -->
              <v-expansion-panel v-model="panel">
                <v-expansion-panel-header
                  >{{ $t("lang_assign_patient_survey") }}
                  <template v-slot:actions>
                    <v-icon color="primary">$expand</v-icon>
                  </template>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                  <AssignPatientsPROMPREMPage />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </div>
      </v-container>
    </v-card>
  </v-app>
</template>

<script>
import CreatePROMPREMPage from "./CreatePROMPREMPage";
import AssignPatientsPROMPREMPage from "./AssignPatientsPROMPREMPage";
export default {
  name: "PROMPREMSetupPage",
  components: {
    CreatePROMPREMPage,
    AssignPatientsPROMPREMPage,
  },
  created() {},
  data() {
    return {
      panel: [0],
    };
  },
  methods: {},
  computed: {
    //moduleIsReady() {
    //  return true;
    //},
    selected_user_public_id() {
      return this.$store.getters.selected_user_public_id;
    },
    valid_selected_user_public_id() {
      return this.selected_user_public_id && this.selected_user_public_id != "";
    },
  },
  mounted() {},
};
</script>

<style scoped>
.container {
  max-width: 1920px;
  width: 100%;
}
</style>