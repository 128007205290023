//API calls
export class WorkflowService {

    url = "";

    /**
     * Initialize class
     * 
     * @param {string} url 
     */
    async init(url) {
        this.url = url;
    }

    getWorkflowContent(id) {
        /* eslint-disable */
        console.log(id);
        return "Workflow content tbd";
    }

}
