<template>
  <v-app>
    <v-card>
      <!-- Retrieve data with existing publicId -->
      <v-container fluid grid-list-lg pa-5>
        <v-flex xs12 md8>
          <v-text-field
            v-model="selected_user_public_id"
            :label="$t('lang_username')"
            required
            readonly
          ></v-text-field>
        </v-flex>
      </v-container>
      <div v-if="valid_selected_user_public_id">
        <v-container fluid grid-list-lg>
          <h4>
            Store data as FHIR resource. Example: <code>Blood group</code>
          </h4>
          <v-form ref="form_fhir_store">
            <v-layout justify-left>
              <v-flex md8>
                <v-select
                  v-model="bloodgroup"
                  :items="items_bloodgroup"
                  :rules="[(v) => !!v || $t('lang_field_required')]"
                  label="Blood group"
                  required
                ></v-select>

                <v-text-field
                  v-model="performer"
                  label="Performer"
                  :rules="[(v) => !!v || $t('lang_field_required')]"
                  required
                  autocomplete="new-password"
                  hint="Dr. Mustermann"
                ></v-text-field>

                <div>
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date"
                        label="Effective date time"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date"
                      :active-picker.sync="activePicker"
                      :max="
                        new Date(
                          Date.now() - new Date().getTimezoneOffset() * 60000
                        )
                          .toISOString()
                          .substr(0, 10)
                      "
                      min="1950-01-01"
                      @change="save"
                    ></v-date-picker>
                  </v-menu>
                </div>
              </v-flex>
            </v-layout>
          </v-form>
          <!-- Buttons -->
          <div style="margin: 20px 0 50px 0">
            <v-layout justify-left>
              <v-flex xs12 md2>
                <v-btn color="primary" @click="storeFHIR_resource_bloodgroup"
                  >STORE AS FHIR</v-btn
                >
              </v-flex>
              <v-flex xs12 md2>
                <v-btn color="warning" @click="reset">Reset</v-btn>
              </v-flex>
            </v-layout>
          </div>
        </v-container>

        <v-container fluid grid-list-lg>
          <h4>Get data as FHIR resource. Example: <code>Blood group</code></h4>
          <!--<v-btn color="primary" @click="getFHIR_resource">GET FHIR</v-btn>-->

          <v-container fluid grid-list-lg>
            <!-- Resources Table -->
            <v-container fluid grid-list-lg class="title">
              <v-form ref="form_usertable">
                <v-card-title class="title">
                  <v-text-field
                    v-model="search"
                    append-icon="search"
                    :label="$t('lang_search')"
                    single-line
                    hide-details
                  ></v-text-field>

                  <!-- Action Button -->
                  <!--
              <v-layout row wrap justify-end>
                <v-flex shrink>
                  <v-icon small color="error" @click="request_deletion">
                    fas fa-trash-alt
                  </v-icon>
                </v-flex>
              </v-layout>
              -->
                </v-card-title>

                <v-data-table
                  v-model="selected"
                  :headers="headers"
                  :items="entriesList"
                  single-select
                  item-key="resourceNbr"
                  show-select
                  class="elevation-2"
                  :sort-by="['resourceNbr']"
                  :sort-desc="[false, false]"
                  :search="search"
                >
                </v-data-table>

                <!-- Realod when data was changed in health management -->
                <v-layout justify-center>
                  <v-btn text icon @click="reload_table">
                    <v-icon>fas fa-sync-alt</v-icon>
                  </v-btn>
                </v-layout>
              </v-form>
            </v-container>
          </v-container>
        </v-container>

        <!-- PDF export -->
        <v-container fluid grid-list-lg>
          <h4>Get PDF from FHIR resource. Example: <code>Blood group</code></h4>

          <!--<v-btn color="primary" @click="plot_data">Plot</v-btn>-->

          <v-container fluid grid-list-lg>
            <!--
            <div ref="content_pdf">
              <div id="chart_fhir">
                <div class="chart-container column" v-if="show_chart">
                  <plotlycharts :chart="chart_fhir"></plotlycharts>
                </div>
              </div>
            </div>
            -->

            <!-- Export -->
            <!-- <v-layout justify-left>
              <v-flex xs12 md2>
                <v-btn color="primary" @click="export_pdf"
                  >Export PDF<v-icon right dark>fas fa-file-pdf</v-icon></v-btn
                >
              </v-flex>
              <v-flex md12 md6 class="pt-0">
                <v-checkbox
                  v-model="pdfCheckbox"
                  label="Store in database as FHIR DocumentReference"
                  color="primary"
                  hide-details
                ></v-checkbox>
              </v-flex>
            </v-layout> -->
          </v-container>
        </v-container>

        <v-container fluid grid-list-lg>
          <h4>eSanté Connection</h4>
          <v-container fluid grid-list-lg>
            <v-btn color="primary" @click="send_esante"
              >Send PDF to eSanté<v-icon right dark
                >fas fa-file-medical</v-icon
              ></v-btn
            >
          </v-container>
        </v-container>
      </div>
    </v-card>
  </v-app>
</template>

<script>
import i18n from "@/plugins/i18n";
//import plotlycharts from "../../../assets/plotly-charts.vue";
//import Plotly from "plotly.js";
//import html2canvas from "html2canvas";
import jsPDF from "jspdf";
//import * as rules from "../assets/rules";
import * as services_main from "../../../services";
export default {
  name: "FHIRPage",
  //components: {
  //  plotlycharts,
  //},
  data() {
    return {
      bloodgroup: "",
      performer: "",

      // date
      activePicker: null,
      date: null,
      menu: false,

      // resources table
      //tables
      search: "",
      entriesList: [],
      selected: [],

      // PDF chart
      //show_chart: false,
      //chart_fhir: {},
      pdfbase64: "", //base64 string of generated PDF
      pdfCheckbox: false,
    };
  },
  created() {
    //this.chart_fhir = {
    //  id: "chart_fhir",
    //};
    this.reload_table();
  },
  mounted() {},
  methods: {
    async getFHIR_resource() {
      try {
        var payload = {
          user_public_id: this.selected_user_public_id,
        };
        this.entriesList = await this.$store.dispatch(
          "FETCH_EHR_GET_FHIRRESOURCE",
          {
            payload,
          }
        );
      } catch (error) {
        this.$modal.show1({
          title1: i18n.t("lang_failure"),
          text1: error,
        });
      }
    },

    async storeFHIR_resource(data) {
      try {
        var payload = {
          user_public_id: this.selected_user_public_id,
          data: { fhirdata: data },
        };
        /* eslint-disable */
        console.log(payload);
        await this.$store.dispatch("FETCH_EHR_STORE_FHIRRESOURCE", {
          payload,
        });
        this.$modal.show1({
          title1: i18n.t("lang_success"),
          text1: i18n.t("lang_itemadded"),
        });
      } catch (error) {
        this.$modal.show1({
          title1: i18n.t("lang_failure"),
          text1: error,
        });
      }
    },

    storeFHIR_resource_bloodgroup() {
      if (this.$refs.form_fhir_store.validate()) {
        const data = {
          resource_name: "bloodgroup",
          effectiveDateTime: this.date,
          bloodgroup: this.bloodgroup,
          performer: this.performer,
          subject: this.selected_user_public_id,
        };
        this.storeFHIR_resource(data);
      }
    },

    storeFHIR_resource_PDF() {
      const data = {
        resource_name: "pdfdocument",
        encoded_string_binary: this.pdfbase64,
        title: "FHIR resource as PDF export",
        description: "FHIR resource example based on bloodgroup Observation",
        subject: this.selected_user_public_id,
        effectiveDateTime: services_main.formatd_date(
          this.selectedRow.dateCreatedUtc
        ),
      };
      this.storeFHIR_resource(data);
    },

    send_esante() {
      const selected_resource = this.selectedRow.resource;
      const resourceType = JSON.parse(selected_resource)["resourceType"];
      if (resourceType == "DocumentReference") {
        console.log(selected_resource);
        this.$modal.show1({
          title1: i18n.t("lang_warning"),
          text1: "Connection not yet available.", //i18n.t("lang_nodatasel"),
        });
      } else {
        this.$modal.show1({
          title1: i18n.t("lang_failure"),
          text1:
            "Can only send PDF documents. Please choose a DocumentReference FHIR resource.", //i18n.t("lang_nodatasel"),
        });
      }
    },

    save(date) {
      this.$refs.menu.save(date);
    },
    reset() {
      this.bloodgroup = "";
      this.performer = "";

      // date
      this.activePicker = null;
      this.date = null;
      this.menu = false;
    },
    reload_table() {
      this.getFHIR_resource();
    },
    /*
    plot_data() {
      if (this.selected.length != 0) {
        // FHIR resource to string
        const resource_str = JSON.stringify(this.selectedRow.resource).replace(
          ",",
          "VVV"
        );

        this.show_chart = true;

        this.chart_fhir = {
          id: "chart_fhir",

          data: [
            {
              x: [0],
              y: [0],
              line: {
                color: "#85C1E9",
                width: 2,
                shape: "line",
              },
              marker: {
                size: 10,
                symbol: 1,
              },
              name: "test", //shown in legend
            },
          ],

          layout: {
            title:
              this.selectedRow.publicId +
              "\n" +
              this.selectedRow.dateCreatedUtc,
            width: 1000, //remove for full width, 700
            height: 800, //remove for full height, 500
            annotations: [
              {
                showarrow: false,
                x: 0,
                y: 0,
                text: resource_str,
                xanchor: "left",
                xshift: 10,
                opacity: 0.7,
              },
            ],
          },
        };
      } else {
        this.$modal.show1({
          title1: i18n.t("lang_warning"),
          text1: i18n.t("lang_nodatasel"),
        });
      }
    },
*/

    /// PDF export: # TODO move somewhere else
    /*
    generate_pdf(chart_id, chart_data, chart_layout, content_pdf) {
      Plotly.plot(chart_id, chart_data, chart_layout)
        .then((gd) => {
          return Plotly.toImage(gd, { format: "png" });
        })
        .then((dataURI) => {
          var canvasElement = document.createElement("canvas");

          html2canvas(content_pdf, {
            scale: "5",
          }).then((canvas) => {
            const marginX = 20;
            const marginY_upper = 10;
            var doc = new jsPDF("l", "mm", "a4"); //l = landscape, p = portrait

            //FT plot
            const pageWidth = doc.internal.pageSize.width; //210.0015555555555
            const pageHeight = doc.internal.pageSize.height; //297.0000833333333

            const widthRatio = pageWidth / canvas.width;
            const heightRatio = pageHeight / canvas.height;
            const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

            const canvasWidth = canvas.width * ratio;
            const canvasHeight = canvas.height * ratio;

            //const image = canvas.toDataURL('image/png', 0.3);//full quality 1.0 is full quality
            //console.log(image)

            //using dataURI as bad blurry pdf quality
            //console.log(canvasWidth)
            //console.log(canvasHeight)
            //console.log(dataURI)
            //doc.addImage(canvas, 'PNG', marginX, marginY_upper-30, 230, 150);
            doc.addImage(dataURI, "PNG", marginX, marginY_upper + 30, 210, 150);
            //doc.addImage(canvas, "PNG", x_coord, 40, 300, 220);

            //S4H logo
            //logo base64 conversion of s4h logo
            /*
            var img_logo = get_logo_base64();
            doc.addImage(
              img_logo,
              "PNG",
              pageWidth - 60, //position x-axis
              pageHeight - 10,
              96 / 2, //width (from png dimensions)
              12 / 2 //height (from png dimensions)
            );

            //store doc for either download or upload to 4HP
            //Plotly.purge(chart_id); //remove plot as otherwise is shown twice

            // Store PDF
            var filename = "ExportFHIR_example"; // + random_string + "__" + citizen_id + "_" + get_datetime_now() + "_";
            doc.save(filename);
          });
        });
    },
    */

    generate_PDF() {
      var pdf = new jsPDF("p", "mm", "a4"); //l = landscape, p = portrait
      pdf.setFont("Helvetica");
      pdf.setFontSize(11);
      const data = JSON.parse(this.selectedRow.resource);
      const data_split = JSON.stringify(data).split(",");

      pdf.text(20, 10, "-- Example PDF only -- ");
      pdf.text(20, 20, "FHIR export as PDF for: " + this.selectedRow.publicId);
      pdf.text(20, 30, "Resource created: " + this.selectedRow.dateCreatedUtc);
      pdf.text(20, 40, "Resource exported: " + new Date());

      var i = 5;
      for (var val in data_split) {
        pdf.text(20, 10 + i * 10, data_split[val] + ",");
        i += 1;
      }
      return pdf;
    },

    export_pdf() {
      if (this.selected.length != 0) {
        var pdf = this.generate_PDF();

        //export PDF
        var filename =
          "ExportFHIR_" +
          this.selectedRow.publicId +
          "_" +
          this.selectedRow.dateCreatedUtc;
        pdf.save(filename);

        // save base64 string
        this.pdfbase64 = pdf.output("datauristring");

        if (this.pdfCheckbox) {
          this.storeFHIR_resource_PDF();
        }
      } else {
        this.$modal.show1({
          title1: i18n.t("lang_warning"),
          text1: i18n.t("lang_nodatasel"),
        });
      }

      /*
      this.generate_pdf(
        this.chart_fhir.id,
        this.chart_fhir.data,
        this.chart_fhir.layout,
        this.$refs.content_pdf
      );
      */
    },
  },
  computed: {
    selected_user_public_id() {
      return this.$store.getters.selected_user_public_id;
    },
    valid_selected_user_public_id() {
      return this.selected_user_public_id && this.selected_user_public_id != "";
    },
    items_bloodgroup() {
      return ["Group A", "Group B", "Group O", "Group AB"];
    },
    headers() {
      return [
        {
          text: "Nbr", //this.$t("lang_nbr"),
          value: "resourceNbr",
          sortable: true,
        },
        {
          text: this.$t("lang_username"),
          value: "publicId",
          sortable: true,
        },
        {
          text: this.$t("lang_date"),
          value: "dateCreatedUtc",
          sortable: true,
        },
        {
          text: "FHIR Resource", //this.$t("lang_resource"),
          value: "resource",
          sortable: false,
        },
      ];
    },
    selectedRow() {
      return this.selected[0];
    },
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
};
</script>

