/**
 * Routes of the user module
 */

import UserProfileModule from "./UserProfileModule"
import UserAdministrationModule from "./UserAdministrationModule"
//import SignupPage from "./pages/SignupPage"

export const UserRoutes = [
    {
        //path: "/users/:id",
        path: "profile",
        name: "user-profile",
        component: UserProfileModule,
    },
    {
        path: "administration",
        name: "user-administration",
        component: UserAdministrationModule
    }
    /*
    children: [
        {
            path: "/",
            name: "user-profile",
            component: ProfileAboutPage
        },
        {

        }
    ]
    */

]
