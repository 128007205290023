<template>
  <div id="app">
    <Header :showLanguageBar="true" />

    <div v-if="moduleIsReady">
      <BodyPage />
    </div>
    <div v-else>Loading profile...</div>

    <Footer />
  </div>
</template>

<!--
UI Body component

This is the component that serves as landing page after login. Header, Footer, and Body are aligned accordingly.

.. Copyright (C) 2020-2022 Information Technology for Translational Medicine S.A.
.. moduleauthor:: Kerstin Neininger
-->
<script>
import BodyPage from "./BodyPage";
import Header from "./Header";
import Footer from "./Footer";

export default {
  name: "LandingPage",
  components: {
    BodyPage,
    Header,
    Footer
  },
  computed: {
    moduleIsReady() {
      return this.$store.getters.user_role;
    }
  }
};
</script>

<style lang="css">
/* language */
body {
  font-family: "Cardo", serif;
}

h1,
h2,
h3,
h4,
h5,
p {
  font-family: "Oswald", sans-serif;
  /*text-transform: uppercase;*/
}
</style>