
<template>
    <div>
      <!-- Messages Standard -->
      <v-snackbar
        v-model="visible1"
        elevation="24"
        width="400"
        :color="color1"
        text
        vertical
        right
      >
        <div style="margin: 10px 0 10px 0">
          <h2>{{ title1 }}</h2>
        </div>
        <div style="margin: 30px 0 10px 0">
          <p>{{ text1 }}</p>
        </div>
        <template v-slot:action="{ attrs }">
          <v-btn v-bind="attrs" text @click="visible1 = false">
            <h4>OK</h4>
          </v-btn>
        </template>
      </v-snackbar>

      <!-- Messages Standard Large -->
      <v-snackbar
        v-model="visible2"
        elevation="24"
        width="500"
        :color="color2"
        text
        vertical
        right
      >
        <div style="margin: 10px 0 10px 0">
          <h2>{{ title2 }}</h2>
        </div>
        <div style="margin: 30px 0 10px 0">
          <p>{{ text2 }}</p>
        </div>
        <template v-slot:action="{ attrs }">
          <v-btn v-bind="attrs" text @click="visible2 = false">
            <h4>OK</h4>
          </v-btn>
        </template>
      </v-snackbar>
    </div>
</template>

<!--
App modal component

This is the component to display dialogs to the user. It can be called from every component (modal).

.. Copyright (C) 2020-2022 Information Technology for Translational Medicine S.A.
.. moduleauthor:: Kerstin Neininger
-->
<script>
// we must import our Modal plugin instance because it contains reference to our Eventbus
import Modal from "../plugins/appmodal.js";
import i18n from "@/plugins/i18n";
export default {
  data() {
    return {
      visible1: false,
      title1: "",
      text1: "",
      color1: "",

      visible2: false,
      title2: "",
      text2: "",
      color2: "",
    };
  },
  methods: {
    get_color(title) {
      const color =
        title == i18n.t("lang_success")
          ? "success"
          : title == i18n.t("lang_failure")
          ? "error"
          : "warning";
      return color;
    },
    show1(params) {
      this.visible1 = true;
      this.title1 = params.title1;
      this.text1 = params.text1;
      this.color1 = this.get_color(params.title1);
    },

    show2(params) {
      this.visible2 = true;
      this.title2 = params.title2;
      this.text2 = params.text2;
      this.color2 = this.get_color(params.title2);
    },
  },

  beforeMount() {
    // listen for emited events; declared those events inside our plugin
    Modal.EventBus.$on("show1", (params) => {
      this.show1(params);
    });
    Modal.EventBus.$on("show2", (params) => {
      this.show2(params);
    });
  },
};
</script>

<style scoped>
/*
#diaghead {
  font-family: "Oswald", sans-serif;
  font-size: 18px;
}
#diagbody {
  font-family: "Oswald", sans-serif;
  font-size: 14px;
}
*/
</style>