<template>
  <v-app>
    <survey :survey="survey"></survey>
  </v-app>
</template>

<script>
//import * as img_base64 from "../assets/img/com4survey_quest_UNIVIE_S4Hplatform/img_base64";

//import i18n from "@/plugins/i18n";
import * as SurveyVue from "survey-vue";

// style
import surveyTheme from "../../../assets/json/surveyTheme.json";

var Survey = SurveyVue.Survey;

export default {
  name: "LoadQuestionnaireReplyPage",
  props: [
    "jsondata_quest",
    "jsondata_reply",
    "chosen_lang",
    "jsondata_img",
    "surveyPublicId",
    "surveyUuid",
  ],
  components: {
    Survey,
  },
  data() {
    try {
      //Create the model and pass it into VueSJ Survey component
      var self = this; //needed to access methods from data()
      var survey = new SurveyVue.Model(this.jsondata_quest); //(jsontest);
      survey.applyTheme(surveyTheme);
      survey.locale = this.chosen_lang; //"en"; //de, pt this.$i18n.locale;

      // keep this function here, because in case it is removed, an error appears: 'Move function declaration to function body root'
      survey.onComplete.add(function (result) {
        /* eslint-disable */
        console.log(result.data);
        self.sendResultToServer(result.data);
      });

      /*
      survey.onComplete.add(function (result) {
        //self.sendResultToServer(result.data);
        try {
        var payload = {
          survey_public_id: this.survId,
          survey_data: {
            language_chosen: this.language_chosen,
            survey_results: survey_results,
          },
        };
        await this.$store.dispatch("FETCH_SEND_SURVEY_RESULTS", { payload });
      } catch (error) {
        this.$modal.show1({
          title1: i18n.t("lang_failure"),
          text1: error,
        });
      }

      ////
      
      var payload = {
          data: {
            language_chosen: survey.locale,
            survey_results: result.data,
          },
          survey_public_id: "TODO", //this.selectedRow.surveyPublicId,
          survey_uuid: "TODO", //this.selectedRow.surveyUuid,
        };

        try {
        this.showloadingbtn_replies = true;

        await this.$store.dispatch("FETCH_ALL_SURVEY_REPLIES_MODIF", {
          payload,
        });  
        

      });
*/
      //Jump problem center-right
      //https://github.com/surveyjs/survey-library/issues/1836
      //https://surveyjs.answerdesk.io/ticket/details/t2953/scroll-up-for-more-than-1-page-survey
      // There is apparently an incompatibility in between vuetify and surveyJs
      // We manually move at each new page render the view to something close to the title of the suvey
      // A bit empirical, should be checked how to adapt for any screen size ?
      survey.onAfterRenderPage.add(function () {
        window.scrollTo(0, 650);
      });
      //set base64 images if applicable
      if (this.jsondata_img) {
        if (Object.keys(this.jsondata_img).length != 0) {
          // add images which are the same for all languages
          const available_lang = Object.keys(this.jsondata_img);
          if (available_lang.includes("all")) {
            const img_list_all = this.jsondata_img["all"];
            for (var l = 0; l < img_list_all.length; l++) {
              const img_name = img_list_all[l]["name"];
              const imagelink = img_list_all[l]["imagelink"];
              survey.getQuestionByName(img_name).imageLink = imagelink;
            }
          }
          // add language specific images
          const img_list = this.jsondata_img[this.chosen_lang];
          for (var i = 0; i < img_list.length; i++) {
            const img_name = img_list[i]["name"];
            const imagelink = img_list[i]["imagelink"];
            // check if it is a list, the iterate. If string, set it directly
            // we need to keep the string (instead of list with on element), as some users have img file that are not a list
            if (!Array.isArray(img_name)) {
              survey.getQuestionByName(img_name).imageLink = imagelink;
            } else {
              for (var j = 0; j < img_name.length; j++) {
                survey.getQuestionByName(img_name[j]).imageLink = imagelink;
              }
            }
          }
        }
      }
      /*
      //set base64 images if applicable
      if (this.jsondata_img) {
        const img_list = this.jsondata_img[this.chosen_lang];
        for (var i = 0; i < img_list.length; i++) {
          const img_name = img_list[i]["name"];
          const imagelink = img_list[i]["imagelink"];
          survey.getQuestionByName(img_name).imageLink = imagelink;
        }
      }
*/
      //var storageName = "test";
      //var timerId = 0;

      function loadState(survey) {
        //console.log("loadState - dasiodhas");
        // load data from database
        //var storageSt = window.localStorage.getItem(storageName) || ""; //adpat load from database
        //console.log(storageSt);
        //var res = {};
        //if (storageSt) {
        //   res = JSON.parse(storageSt); //create survey state
        // } else {
        if (this.jsondata_reply) {
          data_reply= this.jsondata_reply;
        } else {
           data_reply={};
          }
        var result = {
          currentPageNo: 0,
          
          data: data_reply, //this.jsondata_reply
          /*{
            
            teachersRate: [
              {
                interesting: 1,
                module1_quest2: [1, 2],
              },
              {
                interesting: 0,
              },
            ],
            
          },
          */
        };
        //}
        /* eslint-disable */
        console.log(result);

        //set data
        if (result.currentPageNo) {
          survey.currentPageNo = result.currentPageNo;
        }
        if (result.data) {
          survey.data = result.data;
        }
      }

      function saveState(survey) {
        var result = {
          currentPageNo: survey.currentPageNo,
          data: survey.data,
        };

        //store in database
        //window.localStorage.setItem(storageName, JSON.stringify(result)); //adpat load from database
        //self.sendResultToServer(result.data);
      }

      survey.onCurrentPageChanged.add(function (survey, options) {
        saveState(survey);
      });

      survey.onComplete.add(function (survey, options) {
        //kill timer
        //clearInterval(timerId);
        //save state
        saveState(survey);
      });

      //load initial survey
      loadState(survey);

      //save data every 10 secs
      /*
      timerId = window.setInterval(function () {
        saveState(survey);
      }, 10000);
      */

      //$("#surveyElement").Survey({model: survey})
    } catch (error) {
      /* eslint-disable */
      console.log(error);
      //this.$modal.show1({
      //      title1: i18n.t("lang_failure"),
      //      text1: error,
      //   });
    }
    return {
      survey: survey,
      survey_results: "",
    };
  },
  created() {},
  methods: {
    /**
     * Send the final results after the participants clicked 'Complete' to the respective endpoint for storage
     * @param {object} survey_results - The surveyJS results stored as JSON
     */

    async sendResultToServer(survey_results) {

      // this endpoint is for anonymous only, we would need that for HAS user
      if (true) {
      try {
        var payload = {
          user_public_id: this.user_public_id,
          survey_public_id: this.surveyPublicId,
          survey_data: {
            language_chosen: this.chosen_lang,
            survey_results: survey_results,
          },
        };
        await this.$store.dispatch("FETCH_SEND_SURVEY_RESULTS_USER", { payload });
        
      } catch (error) {
        this.$modal.show1({
          title1: i18n.t("lang_failure"),
          text1: error,
        });
      }
      }
      

      // clarify with Margot: why should this function be called? It is not storing the replies?
      if (false) {
        var payload = {
          data: {
            language_chosen: this.chosen_lang, //survey.locale,
            survey_results: survey_results, //result.data,
          },
          survey_public_id: this.surveyPublicId,
          survey_uuid: this.surveyUuid,
        };
        console.log(payload);

        await this.$store.dispatch("FETCH_ALL_SURVEY_REPLIES_MODIF", {
          payload,
        });
      }
    },
  },
  computed: {
    user_public_id() {
      return this.$store.getters.user_public_id;
    },
  },
  watch: {},
};
</script>
<style scoped>
</style>