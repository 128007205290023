import WorkflowModule from "./WorkflowModule"

export const WorkflowRoutes = [
    {
    //path: "/users/:id",
    path: "workflow",
    name: "workflow-tbd",
    component: WorkflowModule,
    /*
    children: [
        {
            path: "/",
            name: "workflow.details",
            component: WorkflowContentPage
        }
    ]
    */
}
]
