<template>
  <div class="container">
      <v-container fluid grid-list-lg pa-5>
        <div v-if="moduleIsReady">
          <SensorPage />
        </div>
        <div v-else>Loading profile...</div>

        <!--
        <router-view :key="moduleKey" v-if="moduleIsReady" />
        <div v-else>Loading profile...</div>
        ddffsds {{ this.user.name }}
-->
        
      </v-container>
  </div>
</template>

<script>
import SensorPage from "./pages/SensorPage";

export default {
  name: "SensorModule",
  components: {
    SensorPage,
  },
  created() {
    //this.bootstrap();
  },
  computed: {
    //sensor() {
    //  return this.$store.getters.sensor;
    //},

    moduleIsReady() {
      return true;//this.sensor;
    },

/*
    moduleKey() {
      console.log(this.user);
      console.log(this.user.id);
      console.log(this.user.name);
      return this.user ? this.user.id : 0;
    },
    */
  },

  methods: {
    /**
     * Fetch data from backend
     * Set state.user
     */
    //async bootstrap() {
      //const id = 0//this.$route.params.id;
      //await this.$store.dispatch("FETCH_SENSOR_BY_ID", { id });
    //},
  },
};
</script>

<style scoped>
.container {
  max-width: 1920px;
  width: 100%;
}
</style>