import { WorkflowService } from "./services"
var serviceClass = new WorkflowService(`${process.env.VUE_APP_BASE_URL}` + "api/process/")


export const WorkflowStore = {
    //namespaced: true,
    // -----------------------------------------------------------------
    state: {
        // properties to store user data
        workflow_content: null,
    },
    // -----------------------------------------------------------------
    getters: {
        // getters and computed props on the user data
        workflow_content: state => {
            return state.workflow_content
        }
    },
    // -----------------------------------------------------------------
    mutations: {
        // stuff to set user data locally
        SET_WORKFLOW_CONTENT(state, { workflow_content }) {
            state.workflow_content = workflow_content
        }
    },
    // -----------------------------------------------------------------
    actions: {
        //[FETCH_USER_BY_ID]: async ({ commit }, { id }) => {
        async FETCH_WORKFLOW_BY_ID({ commit }, { id }) {
            commit("SET_WORKFLOW_CONTENT", { workflow_content: null })
            const workflow_content = serviceClass.getWorkflowContent({ id })
            commit("SET_WORKFLOW_CONTENT", { workflow_content })
            return workflow_content
        },

        /*
      create: (context, userData) => {
        // stuff to create a new user on the backend : CRUD CREATE ACTION
      },
      read: context => {
        // stuff to retrieve user data from the backend : CRUD READ ACTION
      },
      update: (context, userData) => {
        // stuff to update user data to the backend : CRUD UPDATE ACTION
      },
      delete: context => {
        // stuff to erase user on the bockend : CRUD DELETE ACTION
      },
      login: (context, credentials) => {
        // stuff to log in a user : LOGIN ACTION
      },
      logout: context => {
        // stuff to logout the logged in user : LOGOUT ACTION
      }
      */
    }
}