/**
 * Set of rules for the user module forms
 */

import i18n from "@/plugins/i18n";

export var addressRules = [
    (v) => !!v || i18n.t("lang_field_required"),
    (v) => (v && v.length <= 100) || i18n.t("lang_maxchar"),
]

export var patHistRules = [
    //v => !!v || i18n.t("lang_field_required"),
    v => v.length <= 500 || i18n.t("lang_maxchar") //v &&
]

export var sessstartRules = [(v) => !!v || i18n.t("lang_field_required")]

export var dobRules = [(v) => !!v || i18n.t("lang_field_required")]
export var icdateRules = [(v) => !!v || i18n.t("lang_field_required")]

export var nameRules = [
    (v) => !!v || i18n.t("lang_field_required"),
    (v) => (v && v.length <= 30) || i18n.t("lang_maxchar"),
    //v => (v || "").indexOf(" ") < 0 || i18n.t("lang_nospaces")
]

export var zipRules = [
    (v) => !!v || i18n.t("lang_field_required"),
    (v) => (v && v.length <= 10) || i18n.t("lang_maxchar"),
    (v) => (v || "").indexOf(" ") < 0 || i18n.t("lang_nospaces"),
]

export var emailRules = [
    (v) => !!v || i18n.t("lang_field_required"),
    (v) =>
        /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/.test(v) ||
        i18n.t("lang_entry_notvalid"),
    (v) => (v || "").indexOf(" ") < 0 || i18n.t("lang_nospaces"),
]

export var phoneRules = [
    (v) => !!v || i18n.t("lang_field_required"),
    (v) => (v && v.length <= 30) || i18n.t("lang_maxchar"),
    //(v) => (v || "").indexOf(" ") < 0 || i18n.t("lang_nospaces"),
]


export var heightRules = [
    (v) => !!v || i18n.t("lang_field_required"),
    (v) => (v >= 130 && v <= 250) || i18n.t("lang_entry_notvalid"),
]
export var weightRules = [
    (v) => !!v || i18n.t("lang_field_required"),
    (v) => (v >= 40 && v <= 200) || i18n.t("lang_entry_notvalid"),
]

export var dateRules = [(v) => !!v || i18n.t("lang_field_required")]


//symbols etc. not allowed, can also be UUID
export var userpublicIdRules = [
    (v) => !!v || i18n.t("lang_field_required"),
    (v) => (v && v.length >= 8 && v.length <= 64) || i18n.t("lang_charslength"),
    (v) => (v || "").indexOf(" ") < 0 || i18n.t("lang_nospaces"),
    (v) => (/([A-Za-z_])$/.test(v) || /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/.test(v)) || i18n.t("lang_entry_notvalid")
]

//symbols etc. not allowed, upper case letters only
export var organisationpublicIdRules = [
    (v) => !!v || i18n.t("lang_field_required"),
    (v) => (v && v.length >= 1 && v.length <= 64) || i18n.t("lang_charslengthone"),
    (v) => (v || "").indexOf(" ") < 0 || i18n.t("lang_nospaces"),
    (v) => /^[A-Z]*$/.test(v) || i18n.t("lang_uppercase_only"),
]

export var passwordRules = [
    (v) => !!v || i18n.t("lang_field_required"),
    (v) => (v && v.length >= 8 && v.length <= 64) || i18n.t("lang_charslength"),
    (v) => (v || "").indexOf(" ") < 0 || i18n.t("lang_nospaces"),
]

export var passphraseRules = [
    (v) => !!v || i18n.t("lang_field_required"),
    //(v) => (v && v.length >= 8 && v.length <= 64) || i18n.t("lang_charslength"),
    (v) => (v || "").indexOf(" ") < 0 || i18n.t("lang_nospaces"),
]