import * as services from "../../services"

//TODO: remove next line when openEHR integrated
/* eslint-disable */

//API calls
export class EHRService {

    constructor() {
        this.url = "/v1_0/ehr_module"
    }

    //TODO backend
    //TODO: change 'this.fetch_API_endpoint' to 'services.fetch_API_endpoint' when integrated in C4C backend; then remove function here
    /*
    async openEHR_retrieveEHR(payload) {
        const response = await this.fetch_API_endpoint(payload, this.url + "/openEHR/ehr", "POST");
        if (response.status_code >= 200 & response.status_code < 300) {
            return response.result;
        } else {
            throw response.message;
        }
    }
    */

    async openEHR_createEHR(payload) {
        const response = await services.fetch_API_endpoint(payload, this.url + "/register", "POST");
        if (response.status_code >= 200 & response.status_code < 300) {
            return response.result;
        } else {
            throw response.message;
        }
    }

    async openEHR_deleteEHR(payload) {
        const openehr_id = payload["openehr_id"];
        const response = await services.fetch_API_endpoint({}, this.url + "/manage_ehrs/" + openehr_id, "DELETE");
        if (response.status_code >= 200 & response.status_code < 300) {
            return response.result;
        } else {
            throw response.message;
        }
    }

    parseAvailableOpenEHRTemplates(response_result) {
        var template_dict = {
            //'stats': response[0],
            "template_ids": [], //ids only
            "templates": response_result //list of templates
        };
        for (var i = 0; i < response_result.length; i++) {
            Object.entries(response_result[i]).forEach(([key, value]) => {
                if (key == "template_id") {
                    template_dict["template_ids"].push(value);
                }
            });
        }
        return template_dict;
    }

    async openEHR_getTemplates() {
        const response = await services.fetch_API_endpoint({}, this.url + "/manage_templates", "GET");
        if (response.status_code >= 200 & response.status_code < 300) {
            return this.parseAvailableOpenEHRTemplates(response.result);
        } else {
            throw response.message;
        }
    }

    async openEHR_uploadTemplate(payload) {
        const response = await services.fetch_API_endpoint(payload, this.url + "/manage_templates", "POST");
        if (response.status_code >= 200 & response.status_code < 300) {
            return response.result;
        } else {
            throw response.message;
        }
    }

    async openEHR_templateDelete(payload) {
        const template_id = payload["template_id"];
        const response = await services.fetch_API_endpoint(
            {},
            this.url + "/manage_template/" + template_id,
            "DELETE"
        );
        if (response.status_code == 200) {
            return response.result;
        } else {
            throw response.message;
        }
    }

    async openEHR_compositionDetails(payload) {
        const template_id = payload["template_id"];
        const response = await services.fetch_API_endpoint(payload, this.url + "/manage_compositions/" + template_id, "GET");
        if (response.status_code >= 200 & response.status_code < 300) {
            return response.result;
        } else {
            throw response.message;
        }
    }

    async openEHR_storeComposition(payload) {
        const response = await services.fetch_API_endpoint(payload["data"], this.url + "/manage_ehrs/" + payload["openehr_id"], "POST");
        if (response.status_code >= 200 & response.status_code < 300) {
            return response.result;
        } else {
            throw response.message;
        }
    }

    async openEHR_getComposition(payload) {
        const response = await services.fetch_API_endpoint({}, this.url + "/manage_ehr/" + payload["openehr_id"] + "/" + payload["template_id"], "GET");
        if (response.status_code >= 200 & response.status_code < 300) {
            var res = response.result.slice(1, -1);
            return res;
            //const openehrtemplate_public_id = payload["openehrtemplate_public_id"];
            //if (openehrtemplate_public_id == "problemlist") return this.parseAvailableDiagnoses(response.result);
            //else return "TODO";
        } else {
            throw response.message;
        }
    }

    //all compositions are retrieved from C4C backend, not EHRbase
    async openEHR_getAllCompositions(payload) {
        const openehr_id = payload["openehr_id"]
        const template_id = payload["template_id"]
        const response = await services.fetch_API_endpoint({}, this.url + "/manage_ehrs/" + openehr_id + "/" + template_id + "/compositions", "GET");
        if (response.status_code >= 200 & response.status_code < 300) {
            return response.result;
        } else {
            throw response.message;
        }
    }

    async openEHR_storeComposition_flat(payload) {
        const template_id = payload["template_id"];
        const response = await services.fetch_API_endpoint(payload["data"], this.url + "/manage_compositions/" + template_id, "POST");
        if (response.status_code >= 200 & response.status_code < 300) {
            return response.result;
        } else {
            throw response.message;
        }
    }

    async openEHR_compositionDelete(payload) {
        const ehr_id = payload["openehr_id"];
        //const template_id = payload["template_id"];
        const composition_id = payload["composition_id"];
        const response = await services.fetch_API_endpoint(
            {},
            this.url + "/manage_ehrs/" + ehr_id + "/composition/" + composition_id,
            "DELETE"
        );
        if (response.status_code == 200) {
            return response.result;
        } else {
            throw response.message;
        }
    }

    parse_aql_result(aql_result) {
        const parsed_results = {
          stats: aql_result[0],
          entries: [],
        };
        // start at position 1 as first position is statistics
        for (var i = 1; i < aql_result.length; i++) {
            parsed_results["entries"].push(aql_result[i]);
        }
        return parsed_results;
      }
    

    async openEHR_launchAQL(payload) {
        const ehr_id = payload["openehr_id"];
        const composition_id = payload["composition_id"];
        const aql_action = payload["aql_action"];
        const response = await services.fetch_API_endpoint(
            {},
            this.url + "/manage_ehrs/" + ehr_id + "/composition/" + composition_id + "/" + aql_action,
            "GET"
        );
        if (response.status_code >= 200 & response.status_code < 300) {
            return response.result;//this.parse_aql_result(response.result);
        } else {
            throw response.message;
        }
    }

    async EHR_getFHIRresource(payload) {
        const user_public_id = payload["user_public_id"];
        const response = await services.fetch_API_endpoint({}, this.url + "/manage_fhir/" + user_public_id, "GET");
        if (response.status_code >= 200 & response.status_code < 300) {
            return response.result;
        } else {
            throw response.message;
        }
    }

    async EHR_storeFHIRresource(payload) {
        const user_public_id = payload["user_public_id"];
        const response = await services.fetch_API_endpoint(payload["data"], this.url + "/manage_fhir/" + user_public_id, "POST");
        if (response.status_code >= 200 & response.status_code < 300) {
            return response.result;
        } else {
            throw response.message;
        }
    }
}

