<template>
  <v-app>
    <v-card>
      <!-- User Table -->
      <v-container fluid grid-list-lg class="title">
        <v-form ref="form_usertable">
          <v-card-title class="title">
            <v-text-field
              v-model="search"
              append-icon="search"
              :label="$t('lang_search')"
              single-line
              hide-details
            ></v-text-field>

            <!-- Action Button -->
            <v-layout row wrap justify-end>
              <v-flex shrink>
                <v-icon small color="error" @click="request_deletion">
                  fas fa-trash-alt
                </v-icon>
              </v-flex>
            </v-layout>
          </v-card-title>

          <v-data-table
            v-model="selected"
            :headers="headers"
            :items="entriesList"
            single-select
            item-key="publicId"
            show-select
            class="elevation-2"
            :sort-by="['dateCreatedUtc']"
            :sort-desc="[false, false]"
            :search="search"
          >
          </v-data-table>

          <!-- Realod when data was changed in health management -->
          <v-layout justify-center>
            <v-btn text icon @click="reload_table">
              <v-icon>fas fa-sync-alt</v-icon>
            </v-btn>
          </v-layout>
        </v-form>
      </v-container>

      <!---------------------------------ADD --------------------------------------->
      <v-container fluid grid-list-lg pa-8>
        <v-form ref="form_addorg" v-model="valid_org">
          <v-layout justify-left>
            <v-flex md6>
              <v-text-field
                v-model="publicId"
                :label="$t('lang_organisation')"
                :rules="organisationpublicIdRules"
                :hint="$t('lang_uppercase_only')"
                @input="
                  (v) => {
                    publicId = v.toUpperCase();
                  }
                "
                required
              ></v-text-field>
            </v-flex>
          </v-layout>
          <!-- ACCESS RIGHTS -->
          <h4>{{ $t("lang_access_rights") }}</h4>
          <v-layout justify-left>
            <v-flex xs12 md3>
              <v-switch
                v-model="accessmodule_main"
                color="secondary"
                :disabled="user_role_isOPE"
                :label="$t('lang_access_module') + ` MAIN`"
              ></v-switch>

              <v-switch
                v-model="accessmodule_user"
                color="secondary"
                :disabled="user_role_isOPE"
                :label="$t('lang_access_module') + ` USER`"
              ></v-switch>
            </v-flex>
            <v-flex xs12 md3>
              <v-switch
                v-model="accessmodule_survey"
                color="secondary"
                :disabled="user_role_isOPE"
                :label="$t('lang_access_module') + ` SURVEY`"
              ></v-switch>
              <v-switch
                v-model="accessmodule_ehr"
                color="secondary"
                :disabled="user_role_isOPE"
                :label="$t('lang_access_module') + ` EHR`"
              ></v-switch>
              <v-switch
                v-model="accessmodule_sensor"
                color="secondary"
                :disabled="user_role_isOPE"
                :label="$t('lang_access_module') + ` SENSOR `"
              ></v-switch>
            </v-flex>
            <v-flex xs12 md3>
              <v-switch
                v-model="accessmodule_communication"
                color="secondary"
                :disabled="user_role_isOPE"
                :label="$t('lang_access_module') + ` COMMUNICATION`"
              ></v-switch>

              <v-switch
                v-model="accessmodule_report"
                color="secondary"
                :disabled="user_role_isOPE"
                :label="$t('lang_access_module') + ` REPORT`"
              ></v-switch>
            </v-flex>
          </v-layout>

          <!-- ICON 1 -->
          <div style="margin: 20px 0 10px 0">
            <h4>{{ $t("lang_select_icon") }}</h4>
          </div>
          <v-layout justify-left>
            <v-container fluid grid-list-lg>
              <v-layout justify-left>
                <v-flex md4>
                  <label class="text-reader">
                    <input
                      id="upload_icon1_file"
                      type="file"
                      accept="application/png"
                      @change="read_icon1_binary_from_upload"
                    />
                  </label>
                </v-flex>
                <v-flex md3>
                  <v-btn
                    small
                    color="background"
                    @click="clear_uploaded_icon1_file"
                    >{{ $t("lang_clear") }}</v-btn
                  >
                </v-flex>
              </v-layout>
            </v-container>
          </v-layout>
        </v-form>

        <!-- Buttons -->
        <div style="margin: 20px 0 10px 0">
          <v-layout justify-left>
            <v-flex xs12 md2>
              <v-btn color="primary" @click="add_organisation">{{
                $t("lang_btn_addorg")
              }}</v-btn>
            </v-flex>
            <v-flex xs12 md2>
              <v-btn color="warning" @click="reset">{{
                $t("lang_resetform")
              }}</v-btn>
            </v-flex>
          </v-layout>
        </div>
      </v-container>
      <!-----------------------------------ADD ------------------------------------->

      <!-- Message 'Are you sure?' -->
      <v-dialog v-model="visibleDiagSure" max-width="400">
        <v-card>
          <v-card-title class="headline">
            <p id="diaghead">
              {{ $t("lang_diag_areyousure_head") }}
            </p></v-card-title
          >
          <v-card-text
            ><p id="diagbody">{{ textDiagSure }}</p></v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text small color="secondary" @click="closeDiagSure()">
              <p id="diagbody">{{ $t("lang_no") }}</p></v-btn
            >
            <v-btn color="secondary" @click="org_delete">
              <p id="diagbody">{{ $t("lang_yes") }}</p>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-app>
</template>

<!--
Organisation overview component

This is the component to display all available organisations (ORG) to which OPE and HAS are assigned to.

.. Copyright (C) 2020-2022 Information Technology for Translational Medicine S.A.
.. moduleauthor:: Kerstin Neininger
-->
<script>
import i18n from "@/plugins/i18n";
import * as rules from "../assets/rules";
import * as services from "../../../services";
export default {
  name: "OrganisationOverviewPage",
  data() {
    return {
      //tables
      search: "",
      entriesList: [],
      selected: [],

      //add org
      publicId: "",
      valid_org: true,

      //icon
      icon1_uploaded: null,

      //AccessToModules
      accessmodule_main: true,
      accessmodule_user: true,
      accessmodule_survey: true,
      accessmodule_ehr: true,
      accessmodule_sensor: true,
      accessmodule_communication: true,
      accessmodule_report: true,

      //dialog
      visibleDiagSure: false,
      textDiagSure: "",
    };
  },
  methods: {
    /**
     * Assign the retrieved organisations to the entries of the table
     */
    async fill_table() {
      await this.get_all_organizations();
      if (this.all_organisations) {
        this.entriesList = this.all_organisations.organisations;
      }
    },
    /**
     * Reload the table by querying the respective endpoint
     */
    reload_table() {
      this.fill_table();
    },

    /**
     * Retrieve all organisations
     */
    async get_all_organizations() {
      try {
        await this.$store.dispatch("FETCH_ALL_ORGANISATIONS");
      } catch (error) {
        this.$modal.show1({
          title1: i18n.t("lang_failure"),
          text1: error,
        });
      }
    },

    /**
     * Needed for deletion and give a warning in case no row was selected
     */
    request_action() {
      if (!this.selectedRow) {
        throw {
          title: i18n.t("lang_reminder"),
          message: i18n.t("lang_selectrow"),
        };
      }
    },

    /**
     * Request the deletion of an entry. This will open a dialog with OK to delete or CLOSE buttons.
     */
    request_deletion() {
      try {
        this.request_action();
        // reminder to confirm deletion
        this.showDiagSure({
          textDiagSure: i18n.t("lang_diag_areyousure_delete_body"),
        });
      } catch (error) {
        this.$modal.show1({
          title1: error.title,
          text1: error.message,
        });
      }
    },

    /**
     * In case deletion is triggered, delete the organisation from the database
     */
    async org_delete() {
      this.closeDiagSure(); //close dialog that is  still open
      try {
        var payload = {
          publicId: this.selectedRow.publicId,
        };
        await this.$store.dispatch("FETCH_ORGANISATION_DELETE", { payload });
        this.$modal.show1({
          title1: i18n.t("lang_success"),
          text1: i18n.t("lang_itemdeleted"),
        });
        this.reload_table();
      } catch (error) {
        this.$modal.show1({
          title1: i18n.t("lang_failure"),
          text1: error,
        });
      }
    },

    /**
     * Close the deletion dialog
     */
    closeDiagSure() {
      this.visibleDiagSure = false;
    },
    /**
     * Open the deletion dialog
     * @param {object} params - The text to be displayed
     */
    showDiagSure(params) {
      this.visibleDiagSure = true;
      this.textDiagSure = params.textDiagSure;
    },

    /**
     * Validate File size, throw error if invalid
     * @param {object} file - The file object that was uploaded
     */
    validate_file_size(file) {
      const file_size_kb = file.size / 1000;
      const valid_size = services.validUploadFileSize(
        file_size_kb / 1000, //MB
        "organizationIcon"
      );
      if (!valid_size) {
        throw {
          type: "c4cError",
          message: i18n.t("lang_sizeinvalid"),
          code: 400,
        };
      }
    },

    /**
     * clear file icon1
     */
    clear_uploaded_icon1_file() {
      const upload_file = document.getElementById("upload_icon1_file");
      if (upload_file) {
        upload_file.value = null;
      }
    },

    /**
     * Upload base64 icons
     */
    read_icon1_binary_from_upload(ev) {
      try {
        const file = ev.target.files[0];
        this.validate_file_size(file); //validate size

        //read file if size valid
        const reader = new FileReader();
        reader.onload = (ev) => {
          var text = ev.target.result;
          try {
            this.icon1_uploaded = text;
          } catch (error) {
            this.$modal.show1({
              title1: i18n.t("lang_failure"),
              text1: error,
            });
          }
        };
        reader.readAsDataURL(file);
      } catch (error_size) {
        this.$modal.show1({
          title1: i18n.t("lang_failure"),
          text1: error_size.message,
        });
      }
    },

    /**
     * Store the organisation
     */
    async add_organisation() {
      if (this.$refs.form_addorg.validate()) {
        try {
          var payload = {
            public_id: this.publicId,
            icon1_data: this.icon1_uploaded ? this.icon1_uploaded : "",
            modules_access: {
              main: this.accessmodule_main,
              user: this.accessmodule_user,
              survey: this.accessmodule_survey,
              ehr: this.accessmodule_ehr,
              sensor: this.accessmodule_sensor,
              communication: this.accessmodule_communication,
              report: this.accessmodule_report,
            },
          };
          await this.$store.dispatch("FETCH_ORGANISATION_REGISTER", {
            payload,
          });

          this.$modal.show1({
            title1: i18n.t("lang_success"),
            text1: i18n.t("lang_itemadded"),
          });
          this.reload_table();
        } catch (error) {
          this.$modal.show1({
            title1: i18n.t("lang_failure"),
            text1: error,
          });
        }
      }
    },

    /**
     * Reset all fields
     */
    reset() {
      this.$refs.form_addorg.reset(); //keep this as otherwise fields are red
      //Object.assign(this.$data, this.$options.data()); //reset all fields to initial values
    },
  },
  mounted() {
    this.fill_table();
  },
  watch: {
    //selectedRow: function () {
    //  if (this.selectedRow) {
    //  }
    //},
  },
  computed: {
    /**
     * @returns {boolean} - True, if the user is OPE, else False
     */
    user_role_isOPE() {
      return this.$store.getters.user_role_isOPE;
    },
    /**
     * @returns {object} - All available organisations
     */
    all_organisations() {
      return this.$store.getters.all_organisations;
    },
    /**
     * @returns {object} - The selected row
     */
    selectedRow() {
      return this.selected[0];
    },
    /**
     * @returns {array} - The headers of the datatable
     */
    headers() {
      return [
        {
          text: this.$t("lang_organisation"),
          value: "publicId",
          sortable: true,
        },
        {
          text: this.$t("lang_date"),
          value: "dateCreatedUtc",
          sortable: true,
        },
      ];
    },
    /**
     * Set of rules for the oranisation UUID
     */
    organisationpublicIdRules() {
      return rules.organisationpublicIdRules;
    },
  },
};
</script>

<style scoped>
</style>