<template>
  <footer class="site-footer">
    <div class="container">
      <p class="copyright-text">Powered by ITTM</p>
    </div>
  </footer>
</template>

<!--
Footer of the Login page component

This is the component for the Login footer.

.. Copyright (C) 2020-2022 Information Technology for Translational Medicine S.A.
.. moduleauthor:: Kerstin Neininger
-->
<script>
export default {
  name: "LoginFooter",
};
</script>

<style scoped>
.copyright-text {
  margin: 0;
  text-align: center;
  font-family: "Oswald", sans-serif;
}
.site-footer {
  background-color: #fff;
  font-size: 14px;
  line-height: 1px;
  color: #000;
}
</style>