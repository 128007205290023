/**
 * Service (API calls) of the survey module
 */


// TODO This module should be called "survey"
import * as services from "../../services"
//import i18n from "@/plugins/i18n";

//API calls
export class QuestionnaireService {
    // TODO This should be called SurveyService

    /**
     * API request path
     */
    constructor() {
        this.url = "/v1_0/surv_module"
    }

    /**
     * Iterate i18n for setup survey languages and store is for each language
     * @returns {object} - User/participants information in the supported languages
     */
    getSetupSurveyMailTemplates() {
        //can be useful if we want to prepare further languages before exposing them
        /*
        survey_email_templates_defaults: {
        de: "Liebe/r Teilnehmer/in,\n\nbitte nutzen Sie folgende URL, um an der Umfrage teilzunehmen:\n\n$url_token\n\nMit freundlichen Grüßen,",
        en: "Dear Participant,\n\nPlease use the following URL to participate in the survey:\n\n$url_token\n\nBest regards,",
        pt: "Querido participante,\n\nUse o link abaixo para começar o inquérito:\n\n$url_token\n\nCordialemente,",
        fr: "Cher/Chère Participant/e,\n\nVeuillez utiliser s'il vous plait le lien ci-dessous afin de participer à cette enquête:\n\n$url_token\n\nCordialement,"
        },
        */

        var survey_email_templates = {
            de: "Liebe/r Teilnehmer/in,\n\nbitte nutzen Sie folgende URL, um an der Umfrage teilzunehmen:\n\n$url_token\n\nMit freundlichen Grüßen,",
            en: "Dear Participant,\n\nPlease use the following URL to participate in the survey:\n\n$url_token\n\nBest regards,",
            pt: "Querido participante,\n\nUse o link abaixo para começar o inquérito:\n\n$url_token\n\nCordialemente,",
            fr: "Cher/Chère Participant/e,\n\nVeuillez utiliser s'il vous plait le lien ci-dessous afin de participer à cette enquête:\n\n$url_token\n\nCordialement,",
            it: "Gentile partecipante,\n\nUtilizzi il seguente URL per partecipare al sondaggio:\n\n$url_token\n\nCordiali saluti,",
            nl: "Geachte deelnemer,\n\nGelieve de volgende URL te gebruiken om aan de enquête deel te nemen:\n\n$url_token\n\nMet vriendelijke groet,",
            es: "Estimado participante,\n\nPor favor, utilice la siguiente URL para participar en la encuesta:\n\n$url_token\n\nUn saludo,",
            pl: "Szanowny Uczestniku,\n\nProszę użyć następującego adresu URL, aby wziąć udział w badaniu:\n\n$url_token\n\nPozdrawiam,",
            gr: "Αγαπητέ συμμετέχοντα,\n\nΠαρακαλούμε χρησιμοποιήστε την ακόλουθη διεύθυνση URL για να συμμετάσχετε στην έρευνα:\n\n$url_token\n\nΜε εκτίμηση,"
        }

        //does not yet work due to \n in csv sheet
        /*
        var survey_email_templates = {}
        for (const [key, value] of Object.entries( i18n.messages)) {
            survey_email_templates[key] = value["lang_setup_survey_mail"]
        }
        */
        return survey_email_templates

    }

    /**
     * 
     * @param {array} survlist_response - The registered survey data
     * @returns {object} - The parsed survey data but as dictionary with the survey ID as key. Local time is calculated from UTC accordingly.
     */
    parseRegisteredSurveys(survlist_response) {
        const survey_dict = {
            'stats': survlist_response[0],
            'survey_names': [],
            'surveys': [],
            //'survey_languages': {},
            'survey_map': {}
        }

        // start at position 1 as first position is statistics
        for (var i = 1; i < survlist_response.length; i++) {
            const surveyPublicId = survlist_response[i].surveyPublicId
            survey_dict['survey_names'].push(survlist_response[i].surveyPublicId)

            //DELETE
            //convert to local time
            /*
            var converted_resp = {
                surveyPublicId: surveyPublicId,//survlist_response[i].surveyPublicId,
                questionnairePublicId: survlist_response[i].questionnairePublicId,
                startTimeUtc: services.UTCtoLocalTime(survlist_response[i].startTimeUtc),
                expirationTimeUtc: services.UTCtoLocalTime(survlist_response[i].expirationTimeUtc),
                surveyUUID: survlist_response[i].surveyUUID
            }
            */
            //DELETE

            //convert to local time
            var converted_resp = survlist_response[i]
            converted_resp.startTimeUtc = services.UTCtoLocalTime(converted_resp.startTimeUtc)
            converted_resp.expirationTimeUtc = services.UTCtoLocalTime(converted_resp.expirationTimeUtc)
            survey_dict['surveys'].push(converted_resp)

            //map public id to other values
            survey_dict['survey_map'][surveyPublicId] = {}
            survey_dict['survey_map'][surveyPublicId]['languages'] = Object.keys(survlist_response[i].templateMail) //add languages for the token generation
            survey_dict['survey_map'][surveyPublicId]['survey_uuid'] = survlist_response[i].surveyUuid
            survey_dict['survey_map'][surveyPublicId]['token_dynamic_mode'] = survlist_response[i].tokenDynamicMode
            survey_dict['survey_map'][surveyPublicId]['template_mail'] = survlist_response[i].templateMail
            survey_dict['survey_map'][surveyPublicId]['startTimeUtc'] = converted_resp.startTimeUtc
            survey_dict['survey_map'][surveyPublicId]['expirationTimeUtc'] = converted_resp.expirationTimeUtc
        }
        return survey_dict
    }

    /**
     * 
     * @param {array} queslist_response - The available questionnaires
     * @returns {object} The parsed questionnaire data but as dictionary with the questonnaire ID as key. Local time is calculated from UTC accordingly.
     */
    parseAvailableQuestionnaires(queslist_response) {
        var quest_dict = {
            'stats': queslist_response[0],
            'quest_names': [],
            'questionnaires': [],
            'quest_map': {}
        }
        for (var i = 1; i < queslist_response.length; i++) {
            const questionnairePublicId = queslist_response[i].questionnairePublicId
            quest_dict["quest_names"].push(questionnairePublicId)

            var converted_resp = queslist_response[i]
            converted_resp.dateCreatedUtc = services.UTCtoLocalTime(converted_resp.dateCreatedUtc)
            quest_dict['questionnaires'].push(converted_resp)

            //establish mapping between id and data
            quest_dict['quest_map'][questionnairePublicId] = {}
            quest_dict['quest_map'][questionnairePublicId]["questionnaire_uuid"] = queslist_response[i].questionnaireUuid
            quest_dict['quest_map'][questionnairePublicId]["data"] = queslist_response[i].data
            quest_dict['quest_map'][questionnairePublicId]["imageData"] = queslist_response[i].imageData

        }
        return quest_dict
    }

    /**
     * API GET request to retrieve all available questionnaires
     * @returns {array} - The available questionnaires
     */
    async getAvailableQuestionnaires() {
        const response = await services.fetch_API_endpoint({}, this.url + "/manage_questionnaires", "GET")
        if (response.status_code >= 200 & response.status_code < 300) {
            return this.parseAvailableQuestionnaires(response.result)
        } else {
            throw response.message
        }
    }

    /**
     * API GET request to retrieve all data for an available questionnaires
     * @param {object} payload - The payload for the request (see API documentation for details)
     * @returns {object} - The questionnaire data (JSON, images,..)
     */
    async getAvailableQuestionnaireData(payload) {
        const questionnaire_public_id = payload["questionnaire_public_id"]
        const response = await services.fetch_API_endpoint(payload, this.url + "/manage_questionnaires/" + questionnaire_public_id, "GET")
        if (response.status_code >= 200 & response.status_code < 300) {
            return response.result
        } else {
            throw response.message
        }
    }

    /**
     * API POST request to register a survey
     * @param {object} payload - The payload for the request (see API documentation for details)
     * @returns {object} - The registered survey
     */
    async registerSurvey(payload) {
        const response = await services.fetch_API_endpoint(payload, this.url + "/manage_surveys", "POST")
        if (response.status_code >= 200 & response.status_code < 300) {
            return response.result
        } else {
            throw response.message
        }
    }

    /**
     * API GET request to retrieve all registered surveys
     * @returns {array} survlist_response - The registered survey data
     */
    async getRegisteredSurveys() {
        const response = await services.fetch_API_endpoint({}, this.url + "/manage_surveys", "GET")
        if (response.status_code >= 200 & response.status_code < 300) {
            return this.parseRegisteredSurveys(response.result)
        } else {
            throw response.message
        }
    }

    /**
     * API GET request to retrieve a token from the survey UUID
     * @param {object} payload - The payload for the request (see API documentation for details)
     * @returns {object} - The survey object converted to JSON
     */
    async getAnonymousToken(payload) {
        //const token_ex = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJ0b3B0YWwuY29tIiwiZXhwIjoxNDI2NDIwODAwLCJodHRwOi8vdG9wdGFsLmNvbS9qd3RfY2xhaW1zL2lzX2FkbWluIjp0cnVlLCJjb21wYW55IjoiVG9wdGFsIiwiYXdlc29tZSI6dHJ1ZX0.yRQYnWzskCZUxPwaQupWkiUzKELZ49eM7oWxAQK_ZXw"
        //const response = {result: {anonymous_token: {token: token_ex}}, status_code: 200}
        const survey_uuid = payload["survey_uuid"]
        const response = await services.fetch_API_endpoint(payload["data"], this.url + "/manage_surveys/" + survey_uuid, "POST") //was PUT before
        if (response.status_code >= 200 & response.status_code < 300) {
            return response.result
        } else {
            throw response.message
        }
    }

    /**
     * API DELETE request to delete a questionnaire with UUID questionnaire_uuid
     * @param {object} payload  - The payload for the request (see API documentation for details)
     * @returns {object} - The questionnaire data converted to JSON
     */
    async deleteQuestionnaire(payload) {
        const questionnaire_uuid = payload["questionnaire_uuid"]
        const response = await services.fetch_API_endpoint({}, this.url + "/manage_questionnaires/" + questionnaire_uuid, "DELETE")
        if (response.status_code == 200) {
            return response.result
        } else {
            throw response.message
        }
    }

    /**
     * API DELETE request to delete a survey with UUID survey_uuid
     * @param {object} payload - The payload for the request (see API documentation for details)
     * @returns  - The survey data converted to JSON
     */
    async deleteRegisteredSurvey(payload) {
        const survey_uuid = payload["survey_uuid"]
        const response = await services.fetch_API_endpoint({}, this.url + "/manage_surveys/" + survey_uuid, "DELETE")
        if (response.status_code == 200) {
            return response.result
        } else {
            throw response.message
        }
    }
    /**
     * Parse the retrieved survey responses
     * @param {object} response_table - The survey responses in TSV format
     * @param {object} response_json - The survey responses in JSON format
     * @returns {object} - All responses within one dictionary (TSV, JSON, etc)
     */
    parseRegisteredSurveyReplies(response_json) {

        var reply_dict = {
            'stats': response_json.statistics,
            'survey': response_json.survey,
            'replies_json': response_json.all_replies,
            'questionnaire': response_json.questionnaire,
            'dualPIF': response_json.all_replies[2].survey_results,
            'language': response_json.all_replies[2].language_chosen,

        }


        return reply_dict
    }

    parseDualPIF_Replies(response_table, response_json, response_pdf) {

        var reply_dict = {
            'stats': response_json.statistics,
            'survey': response_json.survey,
            'replies_json': response_json.all_replies,
            'replies_table': response_table.all_replies,
            'replies_pdf': response_pdf.all_replies,
            'questionnaire': response_json.questionnaire,


            'dualPIF': response_json.all_replies[2].survey_results,
            'language': response_json.all_replies[2].language_chosen,
            'resourceType': response_pdf.all_replies.resourceType,
        }


        return reply_dict
    }

    /**
     * API GET request to retrieve all survey replies (JSON, TSV)
     * @param {object} payload - The payload for the request (see API documentation for details)
     * @returns {object} - The registered survey replies in json format including statistics
     */
    async getRegisteredSurveyReplies(payload) {
        const survey_uuid = payload["survey_uuid"]
        const response_json = await services.fetch_API_endpoint({}, this.url + "/manage_anonymous_replies/" + survey_uuid + "/all?format=json", "GET")
        if (response_json.status_code == 201) {
            return this.parseRegisteredSurveyReplies(response_json.result)
        } else if (response_json.status_code == 203) {
            return null
        }
        else {
            throw response_json.message
        }
    }

    /**
    * API PUT request to modify a survey reply (JSON, TSV)
    * @param {object} payload - The payload for the request (see API documentation for details)
    * @returns {object} - The registered survey replies in different format incl. statistics
    */
    async getUserRegisteredSurveyReplies(payload) {
        const survey_public_id = payload["survey_uuid"]
        const user_public_id = payload["user_public_id"]
        const response_json = await services.fetch_API_endpoint({}, this.url + "/manage_replies_user/" + survey_public_id + "/user/" + user_public_id, "GET")

        if (response_json.status_code == 201) {
            return this.parseRegisteredSurveyReplies(response_json.result)
        } else {
            throw response_json.message
        }
    }

    /**
     * API GET request to retrieve survey content from a participant request in the anonymous module
     * @param {object} payload - The payload for the request (see API documentation for details)
     * @returns {object} - The survey results in case the token send is correct. If the token is accepted, the access to the sruvey is set to True
     */
    async getUserAccessSurvey(payload) {
        const survey_public_id = payload["survey_public_id"]
        const response = await services.fetch_API_endpoint({}, this.url + "/manage_anonymous_replies/" + survey_public_id, "GET")
        if (response.status_code >= 200 & response.status_code < 300) {
            return response.result
        } else {
            throw response.message
        }
    }

    /**
     * API GET request to retrieve survey and org logos from a participant request in the anonymous module
     * @param {object} payload - The payload for the request (see API documentation for details)
     * @returns {object} - The survey and org logos in case the survey id is valid.
     */
    async getUserAccessSurveyLogos(payload) {
        const survey_public_id = payload["survey_public_id"]
        const response = await services.fetch_API_endpoint({}, this.url + "/manage_anonymous_replies/" + survey_public_id + "/logos", "GET")
        if (response.status_code >= 200 & response.status_code < 300) {
            return response.result
        } else {
            throw response.message
        }
    }

    /**
     * API POST request to retrieve a token if the provided passphrase is correct
     * @param {object} payload - The payload for the request (see API documentation for details)
     * @returns {object} - Survey object converted to JSON
     */
    async getUserAccessSurveyPassphrase(payload) {
        const survey_uuid = payload["survey_uuid"]
        //const response = { status_code: 201, result: {has_access: true, token: "testtolendhasjkhdkasjdhjkah" }}
        const response = await services.fetch_API_endpoint(payload["data"], this.url + "/manage_anonymous_replies/" + survey_uuid + "/check_access", "POST")
        if (response.status_code >= 200 & response.status_code < 300) {
            return response.result
        } else {
            throw response.message
        }
    }

    /**
     * API POST request to store the survey replies
     * @param {object} payload - The payload for the request (see API documentation for details)
     * @returns {object} - Survey object converted to JSON
     */
    async sendUserAnoSurveyResults(payload) {
        const survey_public_id = payload["survey_public_id"]
        const survey_data = { data: payload["survey_data"] }
        const response = await services.fetch_API_endpoint(survey_data, this.url + "/manage_anonymous_replies/" + survey_public_id, "POST")
        if (response.status_code >= 200 & response.status_code < 300) {
            return response.result
        } else {
            throw response.message
        }
    }

    /**
     * API POST request to store the survey replies of a user HAS
     * @param {object} payload - The payload for the request (see API documentation for details)
     * @returns {object} - Survey object converted to JSON
     */
    async sendUserSurveyResults(payload) {
        const survey_public_id = payload["survey_public_id"]
        const survey_data = { data: payload["survey_data"] }
        const user_public_id = payload["user_public_id"]
        const response = await services.fetch_API_endpoint(survey_data, this.url + "/manage_replies_user/" + survey_public_id + "/user/" + user_public_id, "POST")
        if (response.status_code >= 200 & response.status_code < 300) {
            return response.result
        } else {
            throw response.message
        }
    }

    /**
     * API POST request to store an uploaded questionnaire
     * @param {object} payload - The payload for the request (see API documentation for details)
     * @returns {object} - Questionnaire object converted to JSON
     */
    async registerQuestionnaire(payload) {
        const response = await services.fetch_API_endpoint(payload, this.url + "/manage_questionnaires", "POST")
        if (response.status_code >= 200 & response.status_code < 300) {
            return response.result
        } else {
            throw response.message
        }
    }

    // TODO This should be renamed getQuestionnaire
    // TODO Why do you use a POST here ???
    /*
    async getQuestionnaireTemplate(payload) {
        const response = await services.fetch_API_endpoint(payload, this.url + "/manage_questionnaires", "POST");
        if (response.status_code == 201) {
            return response.result;
        } else {
            throw response.message;
        }
    }
    */

    /**
    * Connect a survey and an HAS user, e.g. patient connected to a specific questionnaire/survey
    * @param {object} payload - The payload for the request (see API documentation for details)
    * @returns {object} - Survey object converted to JSON
    */
    async connectSurveyUser(payload) {
        //const survey_public_id = payload["survey_public_id"];
        const user_public_id = payload["user_public_id"]
        //const survey_uuid = payload["survey_uuid"];

        //const survey_data = { data: payload["survey_data"] };
        const response = await services.fetch_API_endpoint(payload["data"], this.url + "/manage_surveys_user/" + user_public_id, "POST")
        /* eslint-disable */
        console.log(response)
        if (response.status_code >= 200 & response.status_code < 300) {
            return response.result
        } else {
            throw response.message
        }
    }

    /**
     * API GET request to retrieve all available surveys of an user (HAS)
     * @returns {array} - The available questionnaires
     */
    async getAvailableSurveysUser(payload) {
        const user_public_id = payload["user_public_id"]
        const response = await services.fetch_API_endpoint({}, this.url + "/manage_surveys_user/" + user_public_id, "GET")
        if (response.status_code >= 200 & response.status_code < 300) {
            return response.result//this.parseAvailableQuestionnaires(response.result);
        } else {
            throw response.message
        }
    }
}