/**
 * Vuex store of the dashboard composer module
 */

//import { DashboardContent } from "./services"
//var serviceClass = new DashboardContent("/api/content/");
//import appConfig from "./config.json"

//import { ComposerService } from "./services"
//var serviceClass = new ComposerService();

//Other things to read:
//http://vuejs-templates.github.io/webpack/env.html
const APP_ENV = process.env.VUE_APP_ENV
const BASE_URL = process.env.VUE_APP_BASE_URL

export const DashboardcomposerStore = {
    //namespaced: true,
    // -----------------------------------------------------------------
    state: {
        //app_version: this.$appConfig["version"],
        app_env: APP_ENV,
        base_url: BASE_URL,
        app_languages: [
            { flag: "gb", language: "en", title: "English" },
            { flag: "de", language: "de", title: "German" },
            { flag: "fr", language: "fr", title: "French" },
            { flag: "pt", language: "pt", title: "Portuguese" },
            { flag: "it", language: "it", title: "Italian" },
            { flag: "nl", language: "nl", title: "Dutch" },
            { flag: "es", language: "es", title: "Spanish" },
            { flag: "pl", language: "pl", title: "Polish" },
            { flag: "gr", language: "el", title: "Greek" },
        ],
        app_languages_anonymous: [
            { flag: "gb", language: "en", title: "English" },
            { flag: "de", language: "de", title: "German" },
            { flag: "fr", language: "fr", title: "French" },
            { flag: "pt", language: "pt", title: "Portuguese" },
            { flag: "it", language: "it", title: "Italian" },
            { flag: "nl", language: "nl", title: "Dutch" },
            { flag: "es", language: "es", title: "Spanish" },
            { flag: "pl", language: "pl", title: "Polish" },
            { flag: "gr", language: "el", title: "Greek" },
        ],


    },
    // -----------------------------------------------------------------
    getters: {
        //app_version: state => {
        //  return state.app_version
        //},
        app_env: state => {
            return state.app_env
        },
        base_url: state => {
            return state.base_url
        },
        app_languages: state => {
            return state.app_languages
        },
        app_languages_anonymous: state => {
            return state.app_languages_anonymous
        }
    },
    // -----------------------------------------------------------------
    mutations: {
    },
    // -----------------------------------------------------------------
    actions: {
    }
}