<template>
  <!--
   need when outside of /auth
  <v-app id="inspire">
   <Header />-->
  <v-app>
    <v-card>
      <v-container fluid grid-list-lg pa-8>
        <v-form ref="form_signup" v-model="valid_signup">
          <!-- USERNAME and PASSWORD -->
          <v-container fluid grid-list-lg>
            <v-layout justify-left>
              <v-flex md8>
                <v-text-field
                  v-model="publicId"
                  :label="$t('lang_username')"
                  :rules="userpublicIdRules"
                  required
                  autocomplete="new-password"
                ></v-text-field>
              </v-flex>
              <v-flex md12 class="pt-5">
                <!-- pt-5 moves the button down to align wit the textfield -->
                <v-btn color="background" @click="generate_uuid"
                  >{{ $t("lang_signup_randomid") }}
                </v-btn>
              </v-flex>
            </v-layout>

            <v-layout justify-left>
              <v-flex xs12 md8>
                <!-- @input="password_check" does not work with setting random password by function -->
                <v-text-field
                  v-model="password"
                  :append-icon="show_eye ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show_eye ? 'text' : 'password'"
                  :label="$t('lang_password')"
                  :rules="passwordRules"
                  required
                  @click:append="show_eye = !show_eye"
                  autocomplete="new-password"
                ></v-text-field>

                <p
                  class="pwValidation"
                  :class="{ 'pwValidation--passed': password.length > 8 }"
                >
                  <i
                    class="pwFormIcon fas"
                    :class="password.length > 8 ? 'fa-check' : 'fa-times'"
                  ></i>
                  {{ $t("lang_signup_pwvalidation_length") }}
                </p>
                <p
                  class="pwValidation"
                  :class="{ 'pwValidation--passed': has_uppercase }"
                >
                  <i
                    class="pwFormIcon fas"
                    :class="has_uppercase ? 'fa-check' : 'fa-times'"
                  ></i>
                  {{ $t("lang_signup_pwvalidation_uppercase") }}
                </p>

                <p
                  class="pwValidation"
                  :class="{ 'pwValidation--passed': has_lowercase }"
                >
                  <i
                    class="pwFormIcon fas"
                    :class="has_lowercase ? 'fa-check' : 'fa-times'"
                  ></i>
                  {{ $t("lang_signup_pwvalidation_lowercase") }}
                </p>
                <p
                  class="pwValidation"
                  :class="{ 'pwValidation--passed': has_number }"
                >
                  <i
                    class="pwFormIcon fas"
                    :class="has_number ? 'fa-check' : 'fa-times'"
                  ></i>
                  {{ $t("lang_signup_pwvalidation_number") }}
                </p>
                <p
                  class="pwValidation"
                  :class="{ 'pwValidation--passed': has_special }"
                >
                  <i
                    class="pwFormIcon fas"
                    :class="has_special ? 'fa-check' : 'fa-times'"
                  ></i>
                  {{ $t("lang_signup_pwvalidation_special") }}
                </p>
              </v-flex>
              <v-flex md12 class="pt-5">
                <v-btn color="background" @click="generate_password"
                  >{{ $t("lang_signup_randompw") }}
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>

          <v-container fluid grid-list-lg>
            <v-layout justify-left>
              <v-flex md8>
                <v-select
                  v-model="lang"
                  :items="user_lang_choice"
                  :rules="[(v) => !!v || $t('lang_field_required')]"
                  :label="$t('lang_defaultlang')"
                  required
                ></v-select>
                <v-select
                  v-model="userOrganisation"
                  :items="all_organisations_items"
                  :rules="[(v) => !!v || $t('lang_field_required')]"
                  :label="$t('lang_organisation')"
                  required
                  :disabled="user_role_isOPE"
                ></v-select>
              </v-flex>
            </v-layout>

            <v-layout justify-left>
              <v-flex md4>
                <v-select
                  v-model="role"
                  :items="user_roles_choice"
                  :rules="[(v) => !!v || $t('lang_field_required')]"
                  :label="$t('lang_roles')"
                  required
                  :disabled="user_role_isOPE"
                ></v-select>
              </v-flex>
              <v-flex md4>
                <v-select
                  v-model="roleLevel"
                  :items="user_level_choice"
                  :rules="[(v) => !!v || $t('lang_field_required')]"
                  :label="$t('lang_level')"
                  required
                  :disabled="user_role_isOPE"
                ></v-select>
              </v-flex>
            </v-layout>
          </v-container>

          <!-- ACCESS RIGHTS -->
          <v-container fluid grid-list-lg>
            <h4>{{ $t("lang_access_rights") }}</h4>
            <v-switch
              v-model="platform_access"
              color="primary"
              :label="$t('lang_platform_access')"
            ></v-switch>
            <div v-if="platform_access">
              <v-layout justify-left>
                <v-flex xs12 md3>
                  <v-switch
                    v-model="accessmodule_main"
                    color="secondary"
                    disabled
                    :label="$t('lang_access_module') + ` MAIN`"
                  ></v-switch>

                  <v-switch
                    v-model="accessmodule_user"
                    color="secondary"
                    disabled
                    :label="$t('lang_access_module') + ` USER`"
                  ></v-switch>
                </v-flex>
                <v-flex xs12 md3>
                  <v-switch
                    v-model="accessmodule_survey"
                    color="secondary"
                    :disabled="user_role_isOPE"
                    :label="$t('lang_access_module') + ` SURVEY`"
                  ></v-switch>
                  <v-switch
                    v-model="accessmodule_ehr"
                    color="secondary"
                    :disabled="user_role_isOPE"
                    :label="$t('lang_access_module') + ` EHR`"
                  ></v-switch>
                  <v-switch
                    v-model="accessmodule_sensor"
                    color="secondary"
                    :disabled="user_role_isOPE"
                    :label="$t('lang_access_module') + ` SENSOR`"
                  ></v-switch>
                </v-flex>
                <v-flex xs12 md3>
                  <v-switch
                    v-model="accessmodule_communication"
                    color="secondary"
                    :disabled="user_role_isOPE"
                    :label="$t('lang_access_module') + ` COMMUNICATION`"
                  ></v-switch>

                  <v-switch
                    v-model="accessmodule_report"
                    color="secondary"
                    :disabled="user_role_isOPE"
                    :label="$t('lang_access_module') + ` REPORT`"
                  ></v-switch>
                </v-flex>
              </v-layout>
            </div>
          </v-container>

          <!-- openEHR -->
          <v-container fluid grid-list-lg>
            <v-switch
              v-model="openEHR_access"
              :label="$t('lang_openEHR_access')"
              disabled
            ></v-switch>
            <div v-if="openEHR_access">
              {{ $t("lang_openehr_id") }}: {{ user_openEHR_id }}
            </div>
          </v-container>
        </v-form>
        <!-- Buttons -->
        <div style="margin: 50px 0 10px 0">
          <v-layout justify-left>
            <v-flex xs12 md2>
              <!-- :disabled="user_role_isOPE" -->
              <v-btn
                color="primary"
                @click="signup"
                >{{ $t("lang_signup") }}</v-btn
              >
            </v-flex>
            <v-flex xs12 md2>
              <v-btn color="warning" @click="reset">{{
                $t("lang_resetform")
              }}</v-btn>
            </v-flex>
          </v-layout>
        </div>
      </v-container>
      <!--
   need when outside of /auth
    <Footer />
  </v-app>
  -->
    </v-card>
  </v-app>
</template>

<!--
User signup page component (basic)

This is the component to signup a user (basic such as username, password, organisation etc.).

.. Copyright (C) 2020-2022 Information Technology for Translational Medicine S.A.
.. moduleauthor:: Kerstin Neininger
-->
<script>
import i18n from "@/plugins/i18n";
import * as rules from "../assets/rules";
import * as services_main from "../../../services";
//import Header from "../../../pages/Header";
//import Footer from "../../../pages/Footer";
export default {
  name: "SignupBasicPage",
  //components: {
  //  Header,
  //  Footer,
  //},
  data() {
    return {
      valid_signup: true,
      publicId: "",
      role: "",
      roleLevel: 0,
      userOrganisation: "",
      lang: "en",
      platform_access: false,
      openEHR_access: false,
      user_openEHR_id: "", //do not use vuex here, as only for dummy user

      //AccessToModules
      accessmodule_main: true,
      accessmodule_user: true,
      accessmodule_survey: true,
      accessmodule_ehr: true,
      accessmodule_sensor: false,
      accessmodule_communication: false,
      accessmodule_report: false,

      // Password validation
      password: "",
      show_eye: false,
      has_number: false,
      has_lowercase: false,
      has_uppercase: false,
      has_special: false,
      all_valid_pw: false,
    };
  },
  mounted() {
    //differentiate between admin and OPE
    this.role = this.user_role_isAdmin
      ? this.user_roles_choice[2]
      : this.user_roles_choice[1]; //set to HAS and disable for OPE
    this.roleLevel = this.user_level_choice[0];
    this.userOrganisation = this.user_role_isOPE
      ? this.user_organisation
      : this.all_organisations_items[0];

    //access rights
    this.platform_access = this.user_role_isAdmin ? true : false;
    //this.accessmodule_main = this.user_role_isAdmin ? true : false;
  },
  methods: {
    /**
     * Validation of the password field to check if the password is secure enough
     */
    password_check() {
      this.has_number = /\d/.test(this.password);
      this.has_lowercase = /[a-z]/.test(this.password);
      this.has_uppercase = /[A-Z]/.test(this.password);
      this.has_special = /[!@#$%^&*)(+=._-]/.test(this.password);
      this.all_valid_pw =
        this.has_number &
        this.has_lowercase &
        this.has_uppercase &
        this.has_special;
    },
    /**
     * Generate a random UUID and assign to the respective field
     */
    generate_uuid() {
      this.publicId = this.$uuid.v4().toString();
    },

    /**
     * Generate a random password and assign to the respective field
     */
    generate_password() {
      this.password = services_main.password_generator();
    },

    /**
     * Store the user information for tool signup.
     * In case openEHR is selected, the user is also assigned an openEHR ID by communicating with the openEHR framework.
     */
    async signup() {
      if (this.$refs.form_signup.validate() & this.all_valid_pw) {
        try {
          await this.signup_basic(); //create the user in our db, then create the openehr account
          if (this.openEHR_access) {
            this.signup_openEHR();
          }
        } catch (error) {
          this.$modal.show1({
            title1: i18n.t("lang_failure"),
            text1: error,
          });
        }
      }
    },

    /**
     * Basic user signup that contains usernmaa, password, organisation etc.
     */
    async signup_basic() {
      try {
        var payload = {
          public_id: this.publicId,
          password: this.password,
          role: this.role,
          role_level: this.roleLevel.toString(),
          organisation: {
            public_id: this.userOrganisation,
          },
          modules_access: {
            main: this.accessmodule_main,
            user: this.accessmodule_user,
            survey: this.accessmodule_survey,
            ehr: this.accessmodule_ehr,
            sensor: this.accessmodule_sensor,
            communication: this.accessmodule_communication,
            report: this.accessmodule_report,
          },
          chosen_style: 0,
          default_language: this.lang,
          platform_access: this.platform_access,
          openEHR_access: this.openEHR_access,
        };
        await this.$store.dispatch("FETCH_USER_SIGNUP", { payload });
        this.$modal.show1({
          title1: i18n.t("lang_success"),
          text1: i18n.t("lang_itemadded"),
        });
      } catch (error) {
        this.$modal.show1({
          title1: i18n.t("lang_failure"),
          text1: error,
        });
      }
    },

    /**
     * User is registerred in openEHR (conntecting to their library in the backend)
     */
    async signup_openEHR() {
      try {
        var payload = {
          public_id: this.publicId,
        };
        this.user_openEHR_id = await this.$store.dispatch(
          "FETCH_OPENEHR_CREATE_EHR",
          { payload }
        );
        this.$modal.show1({
          title1: i18n.t("lang_success"),
          text1: i18n.t("lang_itemadded"),
        });
      } catch (error) {
        this.$modal.show1({
          title1: i18n.t("lang_failure"),
          text1: error,
        });
      }
    },
    /**
     * Reset all fields on this page
     */
    reset() {
      this.$refs.form_signup.reset(); //keep this as otherwise fields are red
      Object.assign(this.$data, this.$options.data()); //reset all fields to initial values
    },
  },
  watch: {
    role() {
      this.role == "HAS"
        ? (this.openEHR_access = false) // put to true after openEHR is integrated
        : (this.openEHR_access = false);
    },
    password() {
      this.password_check();
    },
  },
  computed: {
    /**
     * @returns {boolean} - True, if the user is OPE, else False
     */
    user_role_isOPE() {
      return this.$store.getters.user_role_isOPE;
    },
    /**
     * @returns {boolean} - True, if the user is admin, else False
     */
    user_role_isAdmin() {
      return this.$store.getters.user_role_isAdmin;
    },
    /**
     * @returns {string} - The organisation that the user is associated to
     */
    user_organisation() {
      return this.$store.getters.user_organisation;
    },
    /**
     * @returns {array} - The list of available user roles
     */
    user_roles_choice() {
      return this.$store.getters.user_roles_choice;
    },
    /**
     * @returns {array} - The list of available user levels
     */
    user_level_choice() {
      return this.$store.getters.user_level_choice;
    },
    /**
     * @returns {array} - The list of available languages
     */
    user_lang_choice() {
      return this.$store.getters.user_lang_choice;
    },
    /**
     * @returns {array} - Set of rules for the public ID
     */
    userpublicIdRules() {
      return rules.userpublicIdRules;
    },
    /**
     * @returns {array} - Set of rules for the password
     */
    passwordRules() {
      return rules.passwordRules;
    },
    //user_openEHR_id() {
    //  return this.$store.getters.user_openEHR_id;
    //},

    /**
     * @returns {array} - List of organisations dependent on the admin or the OPE.
     */
    all_organisations_items() {
      //different ORGs only available for admin; OPE: always put the ORG of the OPE
      var org_list = [];
      if (this.user_role_isAdmin) {
        org_list = this.$store.getters.all_organisations
          ? this.$store.getters.all_organisations.organisations_items
          : [];
      } else if (this.user_role_isOPE) {
        org_list.push(this.user_organisation);
      }
      return org_list;
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 1920px;
  width: 100%;
}
.pwValidation {
  font-size: 13px;
}
.pwValidation--passed {
  color: #717b85;
}
.pwFormIcon {
  color: #eb0029;
}
.pwValidation--passed .pwFormIcon {
  color: #0fa140;
}
</style>