<template>
  <div class="container">
    <v-container fluid grid-list-lg pa-5>
      <div v-if="moduleIsReady">
        <ComposerOverviewModule />
      </div>
      <div v-else>Loading profile...</div>
    </v-container>
  </div>
</template>

<!--
Dashboard composer component

This is the component to render the dashboard regarding colors, languages, names etc.

.. Copyright (C) 2020-2022 Information Technology for Translational Medicine S.A.
.. moduleauthor:: Kerstin Neininger
-->
<script>
import ComposerOverviewModule from "./ComposerOverviewModule";
export default {
  name: "ComposerModule",
  components: {
    ComposerOverviewModule,
  },
  created() {
    //this.bootstrap();
  },
  computed: {
    /**
     * TODO: Make this dependent on some mounting functionalities
     * @returns {boolean} - True, if the module is ready, else false.
     */
    moduleIsReady() {
      return true;
    },
  },

  methods: {
    /**
     * Fetch data from backend
     * Set state.user
     */
    //async bootstrap() {
    //  const id = this.$route.params.id;
    // await this.$store.dispatch("FETCH_SENSOR_BY_ID", { id });
    //},
  },
};
</script>

<style scoped>
.container {
  max-width: 1920px;
  width: 100%;
}
</style>