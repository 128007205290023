
/**
 * Routes of the dashboard composer module
 */

import ComposerModule from "./ComposerModule"

export const ComposerRoutes = [
    {
    path: "composer",
    name: "composer-overview",
    component: ComposerModule,
}
]
