<template>
  <div>
    <survey :survey="survey"></survey>

    <div v-if="survey_results">
      <br /><br />
      <h3>{{ $t("lang_expl_response") }}</h3>
      <v-container fluid grid-list-lg pa-5>
        <v-textarea
          v-model="survey_results"
          :label="$t('lang_response_area')"
          counter
          rows="8"
          filled
          outlined
          color="grey"
          prepend-icon="fas fa-edit"
          readonly
        ></v-textarea>

        <p>{{ $t("lang_expl_responses_info") }}</p>
      </v-container>
    </div>
  </div>
</template>


<!--
Rendering/preview a questionnaire component

This is the component to preview an uploaded questionnaire JSON file (in surveyJS JSON format).
The componentn is used to validate the uploaded JSON and if the questionnaire is rendered/shown as expected.
After this, the questionnaire can be stored.

.. Copyright (C) 2020-2022 Information Technology for Translational Medicine S.A.
.. moduleauthor:: Kerstin Neininger
--> 
<script>
//import * as img_base64 from "../assets/img/com4survey_quest_UNIVIE_S4Hplatform/img_base64";

//import i18n from "@/plugins/i18n";
import * as SurveyVue from "survey-vue";

// style
import surveyTheme from "../../../assets/json/surveyTheme.json";

var Survey = SurveyVue.Survey;

export default {
  name: "RenderSurveyPage",
  props: ["jsondata_quest", "chosen_lang", "jsondata_img"],
  components: {
    Survey,
  },
  data() {
    try {
      //Create the model and pass it into VueSJ Survey component
      var self = this; //needed to access methods from data()
      var survey = new SurveyVue.Model(this.jsondata_quest);
      survey.applyTheme(surveyTheme);
      survey.locale = this.chosen_lang; //"en"; //de, pt this.$i18n.locale;
      survey.onComplete.add(function (result) {
        self.fill_textarea(result.data);
      });

      //Jump problem center-right
      //https://github.com/surveyjs/survey-library/issues/1836
      //https://surveyjs.answerdesk.io/ticket/details/t2953/scroll-up-for-more-than-1-page-survey
      // There is apparently an incompatibility in between vuetify and surveyJs
      // We manually move at each new page render the view to something close to the title of the suvey
      // A bit empirical, should be checked how to adapt for any screen size ?
      survey.onAfterRenderPage.add(function () {
        window.scrollTo(0, 650);
      });

      //set base64 images if applicable
      if (this.jsondata_img) {
        // add images which are the same for all languages
        const available_lang = Object.keys(this.jsondata_img);
        if (available_lang.includes("all")) {
          const img_list_all = this.jsondata_img["all"];
          for (var l = 0; l < img_list_all.length; l++) {
            const img_name = img_list_all[l]["name"];
            const imagelink = img_list_all[l]["imagelink"];
            survey.getQuestionByName(img_name).imageLink = imagelink;
          }
        }
        // add language specific images
        const img_list = this.jsondata_img[this.chosen_lang];
        for (var i = 0; i < img_list.length; i++) {
          const img_name = img_list[i]["name"];
          const imagelink = img_list[i]["imagelink"];
          // check if it is a list, the iterate. If string, set it directly
          // we need to keep the string (instead of list with on element), as some users have img file that are not a list
          if (!Array.isArray(img_name)) {
            survey.getQuestionByName(img_name).imageLink = imagelink;
          } else {
            for (var j = 0; j < img_name.length; j++) {
              survey.getQuestionByName(img_name[j]).imageLink = imagelink;
            }
          }
        }
      }
    } catch (error) {
      //console.log(error);
      //this.$modal.show1({
      //      title1: i18n.t("lang_failure"),
      //      text1: error,
      //   });
    }
    return {
      survey: survey,
      survey_results: "",
    };
  },
  created() {},
  methods: {
    /**
     * To diplay how the results could look like, the results are pasted into the textarea field
     * @param {object} result_data - The results in JSON surveyJS format
     */
    fill_textarea(result_data) {
      this.survey_results = JSON.stringify(result_data);
    },
  },
  computed: {},
  watch: {},
};
</script>
<style scoped>
</style>
