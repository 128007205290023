/**
 * Service (API calls) of the user module
 */

import * as services from "../../services";

//API calls
export class UserService {
  constructor() {
    this.url = "/v1_0/user_module";
  }

  async userAuthentication(payload) {
    const response = await services.fetch_API_endpoint(
      payload,
      this.url + "/auth",
      "POST"
    );
    if (response.status_code == 200) {
      const access_token = response.result['accessToken'];
      const token_parsed = services.parseJwt(access_token);
      return {
        access_token: access_token,
        user_role: token_parsed["role"],
        user_organisation: response.result['organisation']['publicId'],
        organisation_icon: response.result['organisation']['icon1Data'],
        modulesAccess: response.result['userInfo']['modulesAccess']
      };
    } else {
      throw response.message;
    }
  }

  async userLogout(payload) {
    const response = await services.fetch_API_endpoint(
      payload,
      this.url + "/logout",
      "DELETE"
    );
    if (response.status_code == 200) {
      return response.result;
    } else {
      throw response.message;
    }
  }

  async userSignup(payload) {
    const response = await services.fetch_API_endpoint(
      payload,
      this.url + "/register",
      "POST"
    );
    if (response.status_code == 201) {
      return response.result;
    } else {
      throw response.message;
    }
  }

  parse_organisations(organisation_list) {
    const filtered_dict = {
      stats: organisation_list[0],
      organisations: [],
      organisations_items: [], // an index of organisation publicId
    };
    // start at position 1 as first position is statistics
    for (var i = 1; i < organisation_list.length; i++) {
      if (organisation_list[i].publicId != "ITTM") {
        //convert to local time
        var converted_resp = {
          publicId: organisation_list[i].publicId,

          dateCreatedUtc: services.UTCtoLocalTime(
            organisation_list[i].dateCreatedUtc
          ),
        };
        filtered_dict["organisations"].push(converted_resp);
        //filtered_dict["organisations"].push(organisation_list[i]); //[ {publicId: ITTM,...}] for table
        filtered_dict["organisations_items"].push(
          organisation_list[i].publicId
        ); //[ITTM,...] for dropdown
      }
    }
    return filtered_dict;
  }

  async getAllOrganisations() {
    const response = await services.fetch_API_endpoint(
      {},
      this.url + "/all_orgs",
      "GET"
    );
    if (response.status_code == 200) {
      return this.parse_organisations(response.result);
    } else {
      throw response.message;
    }
  }

  async organisationRegister(payload) {
    const response = await services.fetch_API_endpoint(
      payload,
      this.url + "/register_org",
      "POST"
    );
    if (response.status_code == 201) {
      return response.result;
    } else {
      throw response.message;
    }
  }

  async organisationDelete(payload) {
    const publicId = payload["publicId"];
    const response = await services.fetch_API_endpoint(
      {},
      this.url + "/organisation/" + publicId,
      "DELETE"
    );
    if (response.status_code == 200) {
      return response.result;
    } else {
      throw response.message;
    }
  }

  parseUsers(user_list) {
    const filtered_dict = { stats: user_list[0], users: [] };
    // start at position 1 as first position is statistics
    for (var i = 1; i < user_list.length; i++) {
      const user_role = user_list[i].role; //anonymous token: user_role == undefined
      if (user_list[i].publicId != "ITTM_admin") {
        var tmp_userlist = {};
        for (const [key, value] of Object.entries(user_list[i])) {
          tmp_userlist[key] = value;
          if (key == "organisation") {
            tmp_userlist[key] = value.publicId;
          }
          else {
            if (key == "dateCreatedUtc") {
              tmp_userlist[key] = services.UTCtoLocalTime(value);
            }
            else {
              tmp_userlist[key] = value;
            }
          }
        }
        tmp_userlist["description"] = user_role === 'undefined' ? "anonymous" : "identified";
        filtered_dict["users"].push(tmp_userlist);
      }
    }
    return filtered_dict;
  }

  async getUsers() {
    //const endpoint = payload["user_role"] == "admin" ? "/all_users" : "/my_users"; //my_users not implemented yet
    const response = await services.fetch_API_endpoint(
      {},
      this.url + "/all_users",
      "GET"
    );
    if (response.status_code == 200) {
      return this.parseUsers(response.result);
    } else {
      throw response.message;
    }
  }

  async userDelete(payload) {
    const publicId = payload["publicId"];
    const response = await services.fetch_API_endpoint(
      {},
      this.url + "/user/" + publicId,
      "DELETE"
    );
    if (response.status_code == 200) {
      return response.result;
    } else {
      throw response.message;
    }
  }

  async userEdit(payload) {
    const publicId = payload["publicId"];
    const data = payload["data"];
    const response = await services.fetch_API_endpoint(
      data,
      this.url + "/user/" + publicId,
      "PUT"
    );
    if (response.status_code == 201) {
      return response.result;
    } else {
      throw response.message;
    }
  }
}
