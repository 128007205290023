<template>
  <div class="container">
    <p>
      Content {{ this.workflow_content }}
    </p>
  </div>
</template>

<script>
export default {
  name: "WorkflowContentPage",
  data() {
    return {};
  },
  methods: {
  },
  computed: {
    workflow_content() {
      return this.$store.getters.workflow_content;
    }
  },
};
</script>

