<template>
    <v-container fluid grid-list-lg>
        <h4> Switch: {{$t("lang_colorblind_friendly")}}</h4>
      <v-switch
        v-model="switch_color"
        :label="`Switch: ${switch_color.toString()}`"
      ></v-switch>

     <v-layout justify-left>
        <v-flex md2>
          <v-btn color="success" @click="open_diag_success">{{
            $t("lang_color_diagsuccess")
          }}</v-btn>
        </v-flex>
        <v-flex md2>
          <v-btn color="error" @click="open_diag_error">{{
            $t("lang_color_diagerror")
          }}</v-btn>
        </v-flex>
        <v-flex md2>
          <v-btn color="warning" @click="open_diag_warning">{{
            $t("lang_color_diagwarning")
          }}</v-btn>
        </v-flex>
      </v-layout>
      <v-layout justify-left>
        <v-flex md2>
          <v-btn color="primary">{{ $t("lang_color_btn") }}</v-btn>
        </v-flex>
        <v-flex md2>
          <v-btn color="secondary">{{ $t("lang_color_btn") }}</v-btn>
        </v-flex>
        <v-flex md2>
          <v-btn color="background">{{ $t("lang_color_btn") }}</v-btn>
        </v-flex>
      </v-layout>
    </v-container>
</template>

<!--
Color component

This is the component to change the color of the UI, e.g. to a more colourblind-friendly setting. 

.. Copyright (C) 2020-2022 Information Technology for Translational Medicine S.A.
.. moduleauthor:: Kerstin Neininger
-->
<script>
import i18n from "@/plugins/i18n";
export default {
  name: "ColorPage",
  data() {
    return {
      switch_color: false,
    };
  },
  methods: {
    open_diag_success() {
      this.$modal.show1({
        title1: i18n.t("lang_success"),
        text1: "Test",
      });
    },
    open_diag_error() {
      this.$modal.show1({
        title1: i18n.t("lang_failure"),
        text1: "Test",
      });
    },
    open_diag_warning() {
      this.$modal.show1({
        title1: i18n.t("lang_warning"),
        text1: "Test",
      });
    },
  },
  computed: {},
  watch: {
    //light2 = colorblind-friendly palette, taken from https://personal.sron.nl/~pault/#sec:qualitative
    switch_color: function () {
      if (this.switch_color) {
        this.$vuetify.theme.themes.light = this.$colors.light2;
      } else {
        this.$vuetify.theme.themes.light = this.$colors.light1;
      }
    },
  },
};
</script>

<style scoped>
</style>