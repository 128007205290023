/**
 * Set of rules for the survey module forms
 */

import i18n from "@/plugins/i18n";

export var numberTokenRules = [
    (v) => !!v || i18n.t("lang_field_required"),
    (v) => (v>0 && v <=100) || i18n.t("lang_entry_notvalid"),
    (v) => (v || "").indexOf(" ") < 0 || i18n.t("lang_nospaces"),
]

export var numberDayRules = [
    (v) => !!v || i18n.t("lang_field_required"),
    (v) => (v>0 && v <=100) || i18n.t("lang_entry_notvalid"),
    (v) => (v || "").indexOf(" ") < 0 || i18n.t("lang_nospaces"),
]


export var mailtemplateRules = [
    //(v) => !!v || i18n.t("lang_field_required"),
    (v) => (v && v.length <= 3000) || i18n.t("lang_maxchar"),
    //(v) => (v || '' ).length <= 200) || i18n.t("lang_maxchar"),
]

export var surveyidRules = [
    (v) => !!v || i18n.t("lang_field_required"),
    (v) => (v && v.length <= 30) || i18n.t("lang_maxchar"),
    (v) => (v || "").indexOf(" ") < 0 || i18n.t("lang_nospaces"),
]

export var questidRules = [
    (v) => !!v || i18n.t("lang_field_required"),
    (v) => (v && v.length <= 60) || i18n.t("lang_maxchar"),
    (v) => (v || "").indexOf(" ") < 0 || i18n.t("lang_nospaces"),
]

export var passphraseRules = [
    (v) => !!v || i18n.t("lang_field_required"),
    (v) => (v && v.length >= 8 && v.length <= 64) || i18n.t("lang_charslength"),
    (v) => (v || "").indexOf(" ") < 0 || i18n.t("lang_nospaces"),
]

