/**
* Services and helper functions
* Collection of different helper functions for API requests, timestamp calculations etc. that are useful for multiple modules.

.. Copyright (C) 2020-2022 Information Technology for Translational Medicine S.A.
.. moduleauthor:: Kerstin Neininger
 */

import { store } from "./store"
import i18n from "@/plugins/i18n"

export async function fetch_API_endpoint(payload, APIendpoint, requestMethod) {
    /*
    Fetch to call API endpoints for data retrieval and storage

    Args: 
        payload (dictionary): request body
        APIendpoint (string): the endpoint name
        requestMethod (string): POST, GET etc.

    Returns:
        The server response as json dictionary.
    */
    try {
        const baseURL = `${process.env.VUE_APP_API_HOST}${process.env.VUE_APP_BASE_URL}`
        const requestURL = baseURL + "api"
        const url = requestURL + APIendpoint

        if (store.getters.app_env == "DEV") {
            /* eslint-disable */
            console.log(url, requestMethod, payload)
            //console.log("token", store.getters.user_token);
        }
        //console.log("anonymous_token", store.getters.anonymous_token);

        //if defined set user_token, else anonymous token
        const token = store.getters.user_token ? store.getters.user_token : store.getters.anonymous_token

        var res = ""
        const auth_scheme = 'Bearer'
        if (requestMethod == "POST" || requestMethod == "PUT") {
            res = await fetch(url, {
                method: requestMethod,
                headers: {
                    'Authorization': auth_scheme + " " + token,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(payload) // body data type must match "Content-Type" header
            })
        } else if (requestMethod == "GET" || requestMethod == "DELETE") {
            res = await fetch(url, {
                method: requestMethod,
                headers: {
                    'Authorization': auth_scheme + " " + token,
                },
            })
        }
        const response = await evalFetchResponse(res)
        if (store.getters.app_env == "DEV") {
            console.log(url, requestMethod, response)
        }
        return response
    } catch (error) {
        if (error.type) {
            throw error.message.toString() //c4c errors
        } else {
            throw 'Fetch API. Probably connection lost or route invalid. [' + error.toString() + "]"
        }
    }
}

async function evalFetchResponse(res) {
    /*
    Evaluate the response and catch different types of error (c4c backend, flask backend, any other problem)
    
    Convention: the c4c backend always returns `status_code` and `message`. If this is not part of the response, there is another problem, possibly with Flask response message.
    in case token is not valid {msg: "Not enough segments"} is thrown (by Flask app); will be catched below.
    
    Args: res (response): server response

    Returns:
    The response as json or throws an error.
    */
    const response = await res.json()
    //console.log(response.status_code, response.message, response.result)
    //{message: "Survey name already exists", status_code: 409}
    if (response.message && response.status_code) {
        //success msg are shown in the respective components; replace failure messages here
        if (response.status_code >= 200 & response.status_code < 300) {
            return response
        } else {
            return { message: mapStatusCodesToi18n(response.status_code), status_code: response.status_code }
        }
    } else {
        //Flask response
        if (response.msg) {
            throw { type: 'c4cError', message: mapFlaskInternalMsg(response.msg) + " [code " + res.status + "]" }
        } else {
            throw { type: 'c4cError', message: "Server response 'message' or 'status_code' is N/A." }
        }
    }
}

function mapStatusCodesToi18n(status_code) {
    /*
    Convert C4C defined messages to i18n

    Args: The HTTPS error code returned from the backend

    Returns:
    string: Message string in the currently chosen language. 'lang_httpscode...' refers to the translations defined in the i18n src/assets/json/UIelements.json.
    */
    return i18n.t("lang_httpscode" + status_code.toString() + "_body")
}

function mapFlaskInternalMsg(msg) {
    /*
    Convert internal Flask response messages to more user-friendly notifications.
    Returns the mapping (if exists), else the original msg (msg_map is 'undefined' in this case).
    
    Args: msg(string): the Flask message

    Returns:
    string: The mapped message
    */
    const msg_mapping = {
        "Token has been revoked": "You have already replied or your participation expired.",
        "Token has expired": "Token has expired. You have already replied or your participation expired."
    }
    const msg_map = msg_mapping[msg]
    return msg_map ? msg_map : msg
}

export function parseJwt(token) {
    /*
    Parse token and extract role
    
    Args: token (string): the JWT token
    
    Returns:
        exp: 1623257802
        fresh: false
        iat: 1623229002
        jti: "d6ff3769-0271-44ad-8321-190729e12279"
        nbf: 1623229002
        organisation: "ITTM"
        role: "OPE"
        sub: 5
        type: "access"
    */
    try {
        var base64Url = token.split('.')[1]
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        }).join(''))
        return JSON.parse(jsonPayload)

    } catch (e) {
        throw {
            name: 'ParseTokenError',
            message: e.toString()
        }
    }
}


export function saveTextAsFile(textToWrite, fileNameToSaveAs) {
    /*
    Download texarea content as txt. Automatic download will start and saves the content to the given filename.
    
    Args:
        textToWrite (string): the textarea content
        fileNameToSaveAs (string): the filename        
    */
    var textFileAsBlob = new Blob([textToWrite], { type: "text/plain" })
    var downloadLink = document.createElement("a")
    const timestamp = new Date().getTime()
    downloadLink.download = timestamp + "_" + fileNameToSaveAs
    //downloadLink.innerHTML = "Download File";
    if (window.webkitURL != null) {
        // Chrome allows the link to be clicked without actually adding it to the DOM.
        downloadLink.href = window.webkitURL.createObjectURL(textFileAsBlob)
    } else {
        // Firefox requires the link to be added to the DOM before it can be clicked.
        downloadLink.href = window.URL.createObjectURL(textFileAsBlob)
        downloadLink.style.display = "none"
        document.body.appendChild(downloadLink)
    }
    downloadLink.click()
}

export function savePDF(pdfToexportbase64, fileNameToSaveAs) {
    /*
    Download PDF.
    
    Args:
        pdfToexportbase64 (string): the base64 encoding of a PDF, e.g. data:application/pdf;base64,JVBERi0xLjQKJa...
        fileNameToSaveAs (string): the filename        
    */
    var downloadLink = document.createElement("a")
    const timestamp = new Date().getTime()
    downloadLink.download = timestamp + "_" + fileNameToSaveAs
    if (window.webkitURL != null) {
        // Chrome allows the link to be clicked without actually adding it to the DOM.
        downloadLink.href = pdfToexportbase64
    } else {
        // Firefox requires the link to be added to the DOM before it can be clicked.
        downloadLink.href = pdfToexportbase64
        downloadLink.style.display = "none"
        document.body.appendChild(downloadLink)
    }
    downloadLink.click()
}

export function getToday() {
    /*
    Returns:
        string: date today
    */
    var today = new Date()
    var dd = today.getDate()
    var mm = today.getMonth() + 1
    var yyyy = today.getFullYear()
    if (dd < 10) {
        dd = '0' + dd
    }
    if (mm < 10) {
        mm = '0' + mm
    }
    return yyyy + "-" + mm + "-" + dd
}

export function isSurveyExpired(expirationTimeUtc) {
    /*
    Returns if a survey is expired given the expiration date.
   
    Args:
        expirationTimeUtc (string): the expiration time in UTC
    
    Returns:
        boolean: true if the survey is expired, else false
    */

    const today = new Date()
    //today.setHours(0, 0, 0, 0);   // use this in case the time should be ignored

    return Date.parse(expirationTimeUtc) < today
}

export function timeout(ms) {
    /*
    Timeout function
    i.e. try every x seconds to Logout
    */
    return new Promise((resolve) => setTimeout(resolve, ms))
}

export function daysToSeconds(days) {
    return days * 86400
}

export function secondsToDays(seconds) {
    return seconds / 86400
}

export function epochToDatetime(epochTime) {
    /*
    Convert Epoch time (seconds) to datetime.   

    Args:
        epochTime (string): the seconds (Epoch time)
    Returns:
        Datetime, e.g. Mon Jan 19 1970 18:44:50 GMT+0100 (Central European Standard Time)
    */
    return new Date(epochTime * 1000)
}

export function dateTimeToEpoch(date) {
    /*
    Convert datetime to Epoch time (seconds)

    Args:
        Datetime, e.g. Mon Jan 19 1970 18:44:50 GMT+0100 (Central European Standard Time)
    Returns:
        epochTime (string): the seconds (Epoch time)
    */

    return Date.parse(date) / 1000
}



export function epochUTCToDatetime(epochTimeUTC) {
    var d = new Date(0) // The 0 there is the key, which sets the date to the epoch
    return d.setUTCSeconds(epochTimeUTC)
}

export function epochToDatetime_localeString(epochTime) {
    /*
Convert Epoch time (seconds) to datetime. Show the time only.

Args:
    epochTime (string): the seconds (Epoch time)
Returns:
    Datetime, e.g. 07/05/2021, 16:20:26
*/
    return new Date(epochTime * 1000).toLocaleString()
}


export function UTCtoLocalTime(UTCTime) {
    /*
    Convert UTC time to local time.   

    Args:
        UTCTime (string): e.g.Tue, 21 Apr 2020 09:20:30 GMT
    Returns:
        local time, e.g. Tue Apr 21 2020 11:20:30 GMT+0200 (Central European Summer Time)
    */
    return new Date(UTCTime).toString()
}

export function localToUTCTime(localTime) {
    /*
    Convert local time to UTC time.   

    Args:
        localTime (string): e.g.Tue, 21 Apr 2020 11:20:30 GMT
    Returns:
        local time, e.g. Tue Apr 21 2020 09:20:30 GMT+0200 (Central European Summer Time)
    */
    return new Date(localTime).toUTCString()
}


export function validUploadFileSize(size_MB, context) {
    /*
    Validate if the size of a file that should be uploaded does not exceed a certain threshold.
    Differentiate by context, e.g. survey json file.

    Assumption for volume space is this (assuming a worst case scenario):
     - we plan a VM with 4GB free after deployment
     - if a questionnaire is around 50MB (hardcoded), we believe we could need up to 60MB storing all the replies (10kB multiplied by 1000 users in db) etc.
     - then we can store up to 65 questionnaires
    => our design should stand for 65 questionnaires and 1 survey of each having 1000 replies

    Args:
        size: File size in MB
        context: surveyJS (others to be added)
    Returns:
        true, if size is valid, else false
    */
    try {
        const allowed_MB = { "surveyJS": 50, "openEHRtemplate": 5.0, "organizationIcon": 1.0, "studyIcon": 1.0 }
        return size_MB <= allowed_MB[context] ? true : false
    } catch (e) {
        return false
    }

}

export function geTableRowData(entriesList, columnName, id) {
    /*
    From a v-table return the all data for a selected row. For entries not shown directly in the table.

    Args:
        entriesList: All table entries
        columnName: the column name that should match the id
        id: the entry that the data is searched for
    Returns:
        the respective row if there is one
    */
    for (var i = 1; i < entriesList.length; i++) {
        const rowtmp = entriesList[i]
        if (rowtmp[columnName] == id) return rowtmp
    }

}

export function beautify_json(json) {
    /*
    Beautify a json onject to display in a textare field

    Args:
        json: The json object
    Returns:
        the Json as a beautified String
    */
    return JSON.stringify(json, undefined, 4)
}

export function password_generator() {
    /*
    //deprecated
    var buf = new Uint8Array(10);
    window.crypto.getRandomValues(buf);
    return btoa(String.fromCharCode.apply(null, buf));
    */

    var passwordLength = 5 //5*3 = 15
    var password = ""
    const chars1 = "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ"
    const chars2 = "0123456789"
    const chars3 = "!@#$%^&*()"
    //basic + additional to have symbol and number
    var charmap = { c1: chars1, c2: chars2, c3: chars3 }
    for (const [key, chars] of Object.entries(charmap)) {
        for (var i = 0; i <= passwordLength; i++) {
            var randomNumber = Math.floor(Math.random() * chars.length)
            password += chars.substring(randomNumber, randomNumber + 1)
        }
    }

    //shuffle to not have order from for loop
    var shuffled = password.split('').sort(function () { return 0.5 - Math.random() }).join('')
    return shuffled

}

export function int_to_double(intval) {
    //return String.format("%f", Float.parseFloat(intval.toString()))
    //eturn parseFloat((intval).toFixed(1))
    return parseFloat(intval)
}


export function formatd_date(date) {
    /*
    Format a date to YYYY-MM-DD string
    Args:
        date: A valid date string
    Returns:
        the respective date as YYYY-MM-DD
    */
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear()

    if (month.length < 2)
        month = '0' + month
    if (day.length < 2)
        day = '0' + day

    return [year, month, day].join('-')
}
