import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

//import json for languages
//import questionnaireTableMaster from '../assets/json/questionnaireTableMaster.json'
import UIelements from '../assets/json/UIelements.json'

//init messages
var messages = {
    'en': {},
    'fr': {},
    'de': {},
    'pt': {},
    'it': {},
    'nl': {},
    'es': {},
    'pl': {},
    'el': {}
}

/*
var ques_id_mapping = {
    'prepost_session_prev': { 'answer': {}, 'question': {}, 'version': "" },
    'prepost_session_prev_zs': { 'answer': {}, 'question': {}, 'version': "" },
    'pre_session_treat': { 'answer': {}, 'question': {}, 'version': "" },
    'post_session_treat': { 'answer': {}, 'question': {}, 'version': "" },
    //put only version and question and answers overlap and not many
    'pre_training': { 'answer': {}, 'question': {}, 'version': "" },
    'post_training': { 'answer': {}, 'question': {}, 'version': "" }
};
*/

//var FHIR_ID_mapping = {}    //{ lang_QpainPreQ11: "current_back_pain_level", ...}

const languages_supported = Object.keys(messages);
const lang_lists = [UIelements] //questionnaireTableMaster, 

for (var l = 0; l < lang_lists.length; l++) {
    const json_list_tmp = lang_lists[l];
    //check if quesionnaire (alsways have a name entry)
    //var is_questionnaire = json_list_tmp[0]["name"] == undefined ? false : true;

    for (var d = 0; d < json_list_tmp.length; d++) {
        const lang_key = json_list_tmp[d]["lang_id"];
        //const scope = json_list_tmp[d]["scope"];
        //const version = json_list_tmp[d]["version"];
        for (var i = 0; i < languages_supported.length; i++) {
            const lang_tmp = languages_supported[i];
            const lang_value = json_list_tmp[d][lang_tmp]
            //needed for i18n lang changes
            messages[lang_tmp][lang_key] = lang_value;

            /*
            //needed to get lang_id from string, only map questionnaire entries
            if (is_questionnaire) {
                const name = json_list_tmp[d]["name"];
                if (scope == "question" | scope == "answer") {
                    //some ques names like 'diagnosis_prim' are not in dict
                    if (Object.keys(ques_id_mapping).includes(name)) {
                        //session questionnaire are automatized more as larger
                        //if (name.includes("session")) {
                            ques_id_mapping[name][scope][lang_value] = lang_key;
                        //}
                        //put version for both, session and training
                        ques_id_mapping[name]["version"] = version;
                    }
                }
            }
            */
        }
    }
}

//console.log(messages);
//console.log(ques_id_mapping);
//console.log(FHIR_ID_mapping);

const i18n = new VueI18n({
    locale: 'en', // set locale
    fallbackLocale: 'de', // set fallback locale
    messages, // set locale messages
});

export default i18n;

/*
Set store params to map lang_ids and strings
*/
//import { store } from "../components/store"
//store.commit("SET_QUES_ID_MAPPING", ques_id_mapping);
//store.commit("SET_LANG_MESSAGES", messages);
//store.commit("SET_FHIR_LINKID_MAPPING", FHIR_ID_mapping);

