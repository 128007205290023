<template>
  <div class="container">
    <v-container fluid grid-list-lg>
      <div v-if="moduleIsReady">
        <ProfileAboutPage />
      </div>
      <div v-else>Loading profile...</div>
    </v-container>
  </div>
</template>

<!--
User about component

This is the component to show the user information (role etc.).

.. Copyright (C) 2020-2022 Information Technology for Translational Medicine S.A.
.. moduleauthor:: Kerstin Neininger
-->
<script>
import ProfileAboutPage from "./pages/ProfileAboutPage";

export default {
  name: "UserProfileModule",
  components: {
    ProfileAboutPage,
  },
  created() {
    //this.bootstrap();
  },
  computed: {
    /**
     * TODO: Make this dependent on some mounting functionalities
     * @returns {boolean} - True, if the module is ready, else false.
     */
    moduleIsReady() {
      return true;
    },
  },

  methods: {
    /**
     * Fetch data from backend
     * Set state.user
     */
    //async bootstrap() {
    //  const id = this.$route.params.id;
    // await this.$store.dispatch("FETCH_USERPROFILE_BY_ID", { id });
    //},
  },
};
</script>

<style scoped>
.container {
  max-width: 1920px;
  width: 100%;
}
</style>