<template>
  <div class="container">
      <v-container fluid grid-list-lg pa-5>
        <div v-if="moduleIsReady">
          <WorkflowContentPage />
        </div>
        <div v-else>Loading profile...</div>

      </v-container>
  </div>
</template>

<script>
import WorkflowContentPage from "./pages/WorkflowContentPage";

export default {
  name: "WorkflowModule",
  components: {
    WorkflowContentPage,
  },
  created() {
    this.bootstrap();
  },
  computed: {
    workflow_content() {
      return this.$store.getters.workflow_content;
    },

    moduleIsReady() {
      return this.workflow_content;
    },
  },

  methods: {
    /**
     * Fetch data from backend
     * Set state.user
     */
    async bootstrap() {
      const id = this.$route.params.id;
      await this.$store.dispatch("FETCH_WORKFLOW_BY_ID", { id });
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 1920px;
  width: 100%;
}
</style>