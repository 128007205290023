<template>
  <v-app>
    <v-card>
      <v-container fluid grid-list-lg>
        <div v-if="moduleIsReady">
          <div style="margin: 40px 0 40px 0">
            <v-expansion-panels v-model="panel" popout focusable multiple>
              <!-- create survey -->
              <v-expansion-panel>
                <v-expansion-panel-header
                  >{{ $t("lang_register_survey") }}
                  <template v-slot:actions>
                    <v-icon color="primary">$expand</v-icon>
                  </template>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                  <CreateSurveyPage />
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- Assign participants -->
              <v-expansion-panel v-model="panel">
                <v-expansion-panel-header
                  >{{ $t("lang_generateurl") }}
                  <template v-slot:actions>
                    <v-icon color="primary">$expand</v-icon>
                  </template>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                  <AssignParticipantsSurveyPage />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </div>
        <div v-else>Loading profile...</div>
      </v-container>
    </v-card>
  </v-app>
</template>


<!--
Survey setup component

This is the component to setup a survey. 
Basically, there are two steps: 
(1) Create a survey (previous step: upload a questionnaire) and 
(2) Assign participants to this survey.

.. Copyright (C) 2020-2022 Information Technology for Translational Medicine S.A.
.. moduleauthor:: Kerstin Neininger
--> 
<script>
import CreateSurveyPage from "./CreateSurveyPage";
import AssignParticipantsSurveyPage from "./AssignParticipantsSurveyPage";
export default {
  name: "SurveySetupPage",
  components: {
    CreateSurveyPage,
    AssignParticipantsSurveyPage,
  },
  created() {},
  data() {
    return {
      panel: [0],
    };
  },
  methods: {},
  computed: {
    /**
     * TODO: Make this dependent on some mounting functionalities
     * @returns {boolean} - True, if the module is ready, else false.
     */
    moduleIsReady() {
      return true;
    },
  },
  mounted() {},
};
</script>

<style scoped>
.container {
  max-width: 1920px;
  width: 100%;
}
</style>