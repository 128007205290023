<template>
  <v-container fluid grid-list-lg pa-5>
    <!-- retrieve token -->
    <v-form ref="form_generate_url_first">
      <v-layout justify-left>
        <v-flex md4>
          <v-select
            v-model="select_survId"
            :items="registered_survey_names"
            :rules="[(v) => !!v || $t('lang_field_required')]"
            :label="$t('lang_selectsurvey')"
            :hint="$t('lang_notexpiredsurvey')"
            persistent-hint
            required
          ></v-select>
        </v-flex>

        <v-flex md4>
          <v-select
            v-model="select_language_url"
            :items="survey_languages"
            :rules="[(v) => !!v || $t('lang_field_required')]"
            :label="$t('lang_language')"
            required
          ></v-select>
        </v-flex>

        <!-- switch to token dynamic in case several users should use the same ID -->
        <v-flex md4>
          <v-switch
            v-model="token_dynamic_mode"
            :label="$t('lang_token_switch')"
            readonly
          ></v-switch>
        </v-flex>

        <!-- switch to token dynamic in case several users should use the same ID -->
        <v-flex md4>
          <v-switch
            v-model="token_dynamic_mode_lang"
            :label="$t('lang_token_switch_lang')"
            :disabled="!token_dynamic_mode"
            color="warning"
          ></v-switch>
        </v-flex>
      </v-layout>
    </v-form>

    <v-form ref="form_generate_url_second">
      <v-layout justify-left>
        <v-flex md4>
          <v-text-field
            v-model="select_nbrtokens"
            type="number"
            :label="$t('lang_nbrparticipants')"
            :rules="numberTokenRules"
            required
            :disabled="token_dynamic_mode"
          ></v-text-field>
        </v-flex>

        <v-flex md4>
          <v-text-field
            v-model="select_validitytime_token"
            type="number"
            :label="$t('lang_surveytimetorespond')"
            :rules="numberDayRules"
            required
            :disabled="token_dynamic_mode"
          ></v-text-field>
        </v-flex>
      </v-layout>
    </v-form>
    <v-layout justify-left>
      <v-flex md2>
        <v-btn color="primary" @click="generate_survey_access">{{
          $t("lang_btn_generate")
        }}</v-btn>
      </v-flex>
      <v-flex xs12 md2>
        <v-btn color="warning" @click="reset_fields">{{
          $t("lang_clear")
        }}</v-btn>
      </v-flex>
    </v-layout>
    <div style="margin: 20px 0 10px 0">
      <v-flex md4>
        <p style="color: #34a1ad">
          <i> {{ didSaturate_msg }} </i>
        </p>
      </v-flex>
    </div>

    <!-- progress bar -->
    <div style="margin: 50px 0 10px 0">
      <v-flex md12>
        <v-progress-linear :value="progress_bar" color="primary" height="25">
          <strong>{{ progress_perc }}%</strong>
        </v-progress-linear>
        <p>{{ $t("lang_generated") }}: {{ progress_nbr }}</p>
        <!-- delete -->
      </v-flex>
    </div>
    <v-container fluid grid-list-lg>
      <v-layout justify-left>
        <v-flex md12>
          <!-- auto-grow param removed -->
          <v-textarea
            v-model="generated_url_list"
            :label="$t('lang_generated_url')"
            counter
            rows="20"
            readonly
            filled
            outlined
            color="cyan"
            prepend-icon="fas fa-envelope"
          ></v-textarea>
        </v-flex>
      </v-layout>
      <v-layout justify-center>
        <v-btn color="primary" @click="export_token_list" class="lowercase">{{
          $t("lang_download_content")
        }}</v-btn>
      </v-layout>
    </v-container>
  </v-container>
</template>


<!--
Assignment of participants to a survey component

This is the component to assign participanst to a created survey.
Basically, the URL(s)/tokens are generated and can be downloaded.

.. Copyright (C) 2020-2022 Information Technology for Translational Medicine S.A.
.. moduleauthor:: Kerstin Neininger
-->
<script>
import i18n from "@/plugins/i18n";
import * as rules from "../assets/rules";
import * as services from "../../../services";
export default {
  name: "AssignParticipantsSurveyPage",
  data() {
    return {
      // retrieve token
      select_survId: null,
      select_validitytime_token: null,
      select_nbrtokens: null,
      select_language_url: null,
      generated_url_list: "", //textarea
      didSaturate_msg: "", //displaying message if respond time was adapted

      //token_dynamic_mode: false,
      token_dynamic_mode_lang: false,

      //progress bar
      progress_bar: 0,
      progress_nbr: 0,
      progress_perc: 0,
    };
  },
  created() {
    this.get_registered_surveys();
  },
  methods: {
    /**
     * Retrieves all registered surveys for this user
     */
    async get_registered_surveys() {
      try {
        await this.$store.dispatch("FETCH_REGISTERED_SURVEYS");
      } catch (error) {
        this.$modal.show1({
          title1: i18n.t("lang_failure"),
          text1: error,
        });
      }
    },

    /**
     * Calulates the relative progress of generating tokens
     * @param {int} value - the current progress valye
     * @param {int} nbr_selected_tokens - the total number of tokens
     * @returns {int} - the relative progress in percent
     */
    map_progress(value, nbr_selected_tokens) {
      return ((value - 0) * (100 - 0)) / (nbr_selected_tokens - 0) + 0;
    },

    /**
     * Generates the token list or permanent URL dependent on if the survey is dynamic or not.
     * Both is pasted into the textares field.
     */
    async generate_survey_access() {
      this.reset_page();
      if (!this.token_dynamic_mode) {
        this.get_anonymous_token_list(); //generate token list
      } else {
        this.get_dynamic_url(); //just display url
      }
    },

    /**
     * Generate all URLs for all languages for participants to access the survey. The $url_token parameter is mandatory and will be replaced automatically by the correct URL.
     * @param {string} templateMail - The E-Mail templates as stored beforehand.
     */
    get_dynamic_url_all(templateMail) {
      for (var i = 0; i < this.survey_languages.length; i++) {
        const lang = this.survey_languages[i];
        this.generated_url_list =
          this.generated_url_list +
          "#" +
          lang +
          "-".repeat(60) +
          "\n\n" +
          templateMail[lang].replace(
            "$url_token",
            this.generated_url_dynamic + lang
          ) +
          "\n\n";
      }
    },

    /**
     * Generate one URL for participants to access the survey. The $url_token parameter is mandatory and will be replaced automatically by the correct URL.
     * @param {string} templateMail - The E-Mail templates as stored beforehand.
     */
    get_dynamic_url_one(templateMail) {
      this.generated_url_list = templateMail[this.select_language_url].replace(
        "$url_token",
        this.generated_url_dynamic + this.select_language_url
      );
    },

    /**
     * Generate dynamic (one) URL.
     * In case 'token_dynamic_mode_lang' is true, one URL (with different lang=en etc.) is generated for every language of the survey.
     */
    get_dynamic_url() {
      if (this.$refs.form_generate_url_first.validate()) {
        var templateMail =
          this.registered_surveys["survey_map"][this.select_survId][
            "template_mail"
          ];

        if (!this.token_dynamic_mode_lang) {
          this.get_dynamic_url_one(templateMail);
        } else {
          this.get_dynamic_url_all(templateMail);
        }

        this.progress_bar = 100;
        this.progress_nbr = 1;
        this.progress_perc = 100;
      }
    },

    /**
     * Retrieve token from backend and concat to current list.
     * Replace texarea with new tokens.
     */
    async get_anonymous_token_list() {
      //progress bar
      this.progress_bar = 0;
      this.progress_nbr = 0;
      this.progress_perc = 0;
      this.didSaturate_msg = "";
      //this.generated_url_list = "";

      if (
        this.$refs.form_generate_url_first.validate() &
        this.$refs.form_generate_url_second.validate()
      ) {
        //retrieve current number of tokens=participants; this sets the 'this.all_survey_results'
        await this.get_all_survey_replies();

        //set current counter and retrieve current number of participants (=nbExpectedAnonymousReplies) from backend
        var counter_participant = this.all_survey_results
          ? this.all_survey_results.stats.nbExpectedAnonymousReplies + 1
          : 1;

        for (var i = 0; i < this.select_nbrtokens; i++) {
          await this.get_anonymous_token();

          //progress bar
          this.progress_bar = this.map_progress(i + 1, this.select_nbrtokens); //progress bar itself
          this.progress_nbr = i + 1; //nbr
          this.progress_perc = Math.round(
            (this.progress_nbr / this.select_nbrtokens) * 100
          ); //perc

          //print to textarea
          if (this.token_templateMail) {
            const template_text = this.token_templateMail[
              this.select_language_url
            ].replace("$url_token", this.generated_url);

            //set text
            this.generated_url_list =
              this.generated_url_list +
              "#" +
              counter_participant +
              "-".repeat(60) +
              "\n\n" +
              template_text +
              "\n\n";
            counter_participant = counter_participant + 1;
          }
        }
        //set message in case validity time and respond time differ
        this.didSaturate_msg = this.token_didSaturate
          ? i18n.t("lang_survey_times_adapt")
          : "";
      }
    },

    /**
     * Get valid token after questionnaire was registered.
     */
    async get_anonymous_token() {
      try {
        const selected_validity_time = this.select_validitytime_token
          ? this.select_validitytime_token
          : 1; //just send something=1 if dynamic token mode as not used by backend then
        var payload = {
          survey_public_id: this.select_survId,
          survey_uuid: this.survey_uuid,
          data: {
            anonymous_has: {
              token_validity_time_utc: services.daysToSeconds(
                selected_validity_time
              ), // * 86400,
              default_language: this.select_language_url,
              default_language_ui: this.select_language_url,
            },
          },
        };
        await this.$store.dispatch("FETCH_ANONYMOUS_TOKEN", { payload });
      } catch (error) {
        this.$modal.show1({
          title1: i18n.t("lang_failure"),
          text1: error,
        });
        this.reset_page();
      }
    },

    /**
     * Retrieve stats for url+token counter
     */
    async get_all_survey_replies() {
      try {
        var payload = {
          survey_public_id: this.select_survId,
          survey_uuid: this.survey_uuid,
        };
        await this.$store.dispatch("FETCH_ALL_SURVEY_REPLIES", {
          payload,
        });
      } catch (error) {
        this.$modal.show1({
          title1: i18n.t("lang_failure"),
          text1: error,
        });
      }
    },

    /**
     * Export the current token list that is printed to the textarea.
     */
    export_token_list() {
      services.saveTextAsFile(this.generated_url_list, "export.txt");
      this.reset_page();
    },
    /**
     * Reset all page parameters
     */
    reset_page() {
      this.generated_url_list = "";
      this.didSaturate_msg = "";
      this.progress_bar = 0;
      this.progress_nbr = 0;
      this.progress_perc = 0;
    },

    /**
     * Reset all fields in the token assignment part
     */
    reset_fields() {
      this.select_survId = null;
      this.select_validitytime_token = null;
      this.select_nbrtokens = null;
      this.select_language_url = null;
      this.token_dynamic_mode_lang = false;
    },
  },
  computed: {
    /**
     * @returns {object} - Anonymous token data from the setup survey (E-Mail template etc.)
     */
    anonymous_token_results() {
      return this.$store.getters.anonymous_token_results;
    },
    /**
     * @returns {string} - Anonymous token from the setup survey
     */
    anonymous_token() {
      return this.$store.getters.anonymous_token;
    },
    /**
     * @returns {string} - JSON with mail templates as defined when survey was setup
     */
    token_templateMail() {
      return this.anonymous_token_results.templateMail;
    },
    /**
     * @returns {boolean} - Parameter (boolean) to judge if the expiration date of the survey is larger equal than time to answers the survey
     */
    token_didSaturate() {
      return this.anonymous_token_results.didSaturate;
    },
    /**
     * @returns {string} - The URL for the survey participants with the access token as retrieved from the backend.
     * Not dynamic mode.
     */
    generated_url() {
      var url = "";
      if (
        this.select_survId &&
        this.anonymous_token &&
        this.select_language_url
      ) {
        url =
          this.serviceURL +
          process.env.VUE_APP_BASE_URL + "anonymous?survId=" +
          //this.select_survId +
          this.survey_uuid +
          //"&default_language=" +
          //this.select_language_url +
          "&token=" +
          this.anonymous_token;
      }
      return url;
    },
    /**
     * @returns {string} - The URL for the survey participants (same for all participants as dynamic mode).
     * The languages and the survey ID are added. Dependent on these, the URL redirects to the respective survey
     * Dynamic mode
     */
    generated_url_dynamic() {
      var url = "";
      if (this.select_survId && this.select_language_url && this.survey_uuid) {
        url =
          this.serviceURL +
          process.env.VUE_APP_BASE_URL + "validation?survId=" +
          this.survey_uuid +
          "&language=";
      }
      return url;
    },
    /**
     * @returns {string} - Dependent on the VM (dev, test, prod), the host address is generated automatically.
     */
    serviceURL() {
      return `${location.host.includes("localhost") ? 'http://' : 'https://'}${location.host}`;
    },
    /**
     * @returns {array} - Set of rules for the number of tokens that can be entered.
     */
    numberTokenRules() {
      return rules.numberTokenRules;
    },
    /**
     * @returns {array} - Set of rules for the number of days that can be entered.
     */
    numberDayRules() {
      return rules.numberDayRules;
    },
    /**
     * @returns {object} - The registered surveys for the dropdown menue.
     */
    registered_surveys() {
      return this.$store.getters.registered_surveys;
    },
    /**
     * @returns {array} - All registred surveys that have not expired yet. If expired, it is not possible to assign participants.
     */
    registered_survey_names() {
      if (Object.keys(this.registered_surveys).length != 0) {
        const all_survey_names = this.registered_surveys.survey_names;
        var notexpired_surveys = [];
        for (var i = 0; i < all_survey_names.length; i++) {
          const surveyPublicId = all_survey_names[i];
          const isExpired = services.isSurveyExpired(
            this.registered_surveys["survey_map"][surveyPublicId]
              .expirationTimeUtc
          );
          if (!isExpired) notexpired_surveys.push(surveyPublicId);
        }
        return notexpired_surveys;
      }
      return [];
    },

    /**
     * @returns {object} - Data of the selected survey.
     */
    registered_surveys_survId() {
      // in case a survey is deleted, then this does not exist anymore
      return this.registered_surveys["survey_map"][this.select_survId];
    },

    /**
     * @returns {array} - The languages available for the selected survey.
     */
    survey_languages() {
      if (
        this.select_survId &&
        Object.keys(this.registered_surveys).length != 0
      ) {
        if (this.registered_surveys_survId) {
          return this.registered_surveys_survId["languages"];
        }
      }
      return [];
    },
    /**
     * @returns {string} -The UUID of the selected survey.
     */
    survey_uuid() {
      if (
        this.select_survId &&
        Object.keys(this.registered_surveys).length != 0
      ) {
        if (this.registered_surveys_survId) {
          return this.registered_surveys_survId["survey_uuid"];
        }
      }
      return "";
    },

    /**
     * @returns {boolean} - True, if the survey is dynamic, else False
     */
    token_dynamic_mode() {
      if (
        this.select_survId &&
        Object.keys(this.registered_surveys).length != 0
      ) {
        if (this.registered_surveys_survId) {
          return this.registered_surveys_survId["token_dynamic_mode"];
        }
      }
      return false;
    },

    /**
     * @returns {object} - The current survey results. This is needed to calculate the correct numbering of tokens.
     */
    all_survey_results() {
      return this.$store.getters.all_survey_results;
    },
  },
  watch: {
    /**
     * Reset the page as soon as a new survey is chosen.
     */
    select_survId: function () {
      this.reset_page();
    },
  },
};
</script>

<style scoped>
</style>