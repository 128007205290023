
<template>
  <div>
    <v-dialog v-model="idle" max-width="400">
      <v-card>
        <v-card-title class="headline">
          <p id="diaghead">{{ $t("lang_sessionexp") }}</p></v-card-title
        >
        <v-card-text>
          <p id="diagbody">{{ $t("lang_idlebrowser") }}</p>
          <p id="diagbody">{{ $t("lang_idlebrowser_logoutsoon") }}</p>
          <!--<p>{{ time / 1000 }} {{ $t("lang_idlesecondsleft") }}</p>-->
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<!--
Idle Modal component

This is the component to implement app idle state as modal for security reasons.
After a certain time, the app gives a warning that inactivity is detected and will logout automatically after a certain time.

.. Copyright (C) 2020-2022 Information Technology for Translational Medicine S.A.
.. moduleauthor:: Kerstin Neininger
-->
<script>
export default {
  data() {
    return {
      idle: true,
      time: 1 * 1000, //milliseconds, short time to auto logout as when switch tabs not possible to logout as not on com4care page anymore
    };
  },
  created() {
    //with timer
    let timerId = setInterval(() => {
      this.time -= 1000;
      if (!this.$store.state.idleVue.isIdle) clearInterval(timerId);
      if (this.time < 1) {
        clearInterval(timerId);
        this.logout();
      }
    }, 1000);
  },
  methods: {
    //try to logout and blacklist token
    async logout() {
      await this.blacklist_token();
      this.redirect_loginpage();
    },

    //Blacklist the token (e.g. for Logout)
    async blacklist_token() {
      await this.$store.dispatch("FETCH_USER_LOGOUT", { payload: {} }); //backend logout to blacklist token
    },

    redirect_loginpage() {
      this.$emit("authenticated", false);
      this.$router.replace({ name: "login" });
    },
  },
};
</script>

<style scoped>
#diaghead {
  font-family: "Oswald", sans-serif;
  font-size: 18px;
}
#diagbody {
  font-family: "Oswald", sans-serif;
  font-size: 14px;
}
</style>