/**
 * Vuex store of the survey module
 */

import { QuestionnaireService } from "./services"
var serviceClass = new QuestionnaireService()

export const QuestionnaireStore = {
    //namespaced: true,
    // -----------------------------------------------------------------
    state: {
        available_questionnaires: [], //list of available questionnaires (ids)
        available_questionnaire_data_anonymous: {}, //quest and img data for a questionnaire in anonymous module
        available_questionnaire_data: {}, //quest and img data for a questionnaire as OPE
        anonymous_page_icons: {},//the two logos form the study and the OPE

        //anonymous token and questionnaires
        anonymous_token: null,
        //template_mail_token: null,
        anonymous_token_results: null,
        registered_surveys: {},
        //registered_survey_names: [],
        user_has_access_survey: false,
        all_survey_results: null,

        // Survey setup mail templates from i18n master
        survey_email_templates: serviceClass.getSetupSurveyMailTemplates(),

        // non-anpnymous user, e.g. HAS
        available_surveys_user: {}, //list of available surveys for a user (ids)
    },
    // -----------------------------------------------------------------
    getters: {
        // getters and computed props on the user data
        available_questionnaires: state => {
            return state.available_questionnaires
        },
        anonymous_token: state => {
            return state.anonymous_token
        },
        template_mail_token: state => {
            return state.template_mail_token
        },
        anonymous_token_results: state => {
            return state.anonymous_token_results
        },
        registered_surveys: state => {
            return state.registered_surveys
        },
        //registered_survey_names: state => {
        //  return state.registered_survey_names
        //},
        user_has_access_survey: state => {
            return state.user_has_access_survey
        },
        available_questionnaire_data_anonymous: state => {
            return state.available_questionnaire_data_anonymous
        },
        anonymous_page_icons: state => {
            return state.anonymous_page_icons
        },
        available_questionnaire_data: state => {
            return state.available_questionnaire_data
        },
        //user_has_access_survey_questId: state => {
        //  return state.user_has_access_survey_questId
        //},
        all_survey_results: state => {
            return state.all_survey_results
        },
        survey_email_templates: state => {
            return state.survey_email_templates
        },
        available_surveys_user: state => {
            return state.available_surveys_user
        },

    },
    // -----------------------------------------------------------------
    mutations: {
        // stuff to set user data locally
        SET_ANONYMOUS_TOKEN(state, { anonymous_token }) {
            state.anonymous_token = anonymous_token
        },
        //SET_TEMPLATEMAIL_TOKEN(state, { template_mail_token }) {
        //  state.template_mail_token = template_mail_token;
        //},
        SET_ANONYMOUS_TOKEN_DATA(state, { anonymous_token_results }) {
            state.anonymous_token_results = anonymous_token_results
        },
        SET_REGISTERED_SURVEYS(state, { registered_surveys }) {
            state.registered_surveys = registered_surveys
        },
        //SET_REGISTERED_SURVEY_NAMES(state, { registered_survey_names }) {
        //  state.registered_survey_names = registered_survey_names;
        //},
        SET_USER_HAS_ACCESS_SURVEY(state, { user_has_access_survey }) {
            state.user_has_access_survey = user_has_access_survey
        },
        //SET_USER_HAS_ACCESS_SURVEY_QUESTID(state, { user_has_access_survey_questId }) {
        //  state.user_has_access_survey_questId = user_has_access_survey_questId;
        //},
        SET_ALL_SURVEY_RESULTS(state, { all_survey_results }) {
            state.all_survey_results = all_survey_results
        },
        SET_AVAILABLE_QUESTIONNAIRES(state, { available_questionnaires }) {
            state.available_questionnaires = available_questionnaires
        },
        //to render to anonymous survey
        SET_AVAILABLE_QUESTIONNAIRE_DATA_ANONYMOUS(state, { available_questionnaire_data_anonymous }) {
            state.available_questionnaire_data_anonymous = available_questionnaire_data_anonymous
        },
        SET_ANONYMOUS_PAGE_ICONS(state, { anonymous_page_icons }) {
            state.anonymous_page_icons = anonymous_page_icons
        },
        //to render to anonymous survey
        SET_AVAILABLE_QUESTIONNAIRE_DATA(state, { available_questionnaire_data }) {
            state.available_questionnaire_data = available_questionnaire_data
        },
        SET_AVAILABLE_SURVEYS_USER(state, { available_surveys_user }) {
            state.available_surveys_user = available_surveys_user
        },
    },
    // -----------------------------------------------------------------
    actions: {
        /* eslint-disable */

        /**
         * Retrieve all available questionnaires
         * @param {*} commit - Vuex store commit parameter
         * @returns {object} - Response from the request (see API documentation for details)
         */
        async FETCH_GET_AVAILABLE_QUESTIONNAIRES({ commit }) {
            commit("SET_AVAILABLE_QUESTIONNAIRES", { available_questionnaires: [] })
            const available_questionnaires = await serviceClass.getAvailableQuestionnaires()
            commit("SET_AVAILABLE_QUESTIONNAIRES", { available_questionnaires: available_questionnaires })
            return available_questionnaires
        },

        /**
       * Retrieve all data for an available questionnaires
       * @param {*} commit - Vuex store commit parameter
       * @param {*} payload - The payload for the request (see API documentation for details)
       * @returns {object} - Response from the request (see API documentation for details)
       */
        async FETCH_GET_AVAILABLE_QUESTIONNAIRE_DATA({ commit }, { payload }) {
            commit("SET_AVAILABLE_QUESTIONNAIRE_DATA", { available_questionnaire_data: {} })
            const available_questionnaire_data = await serviceClass.getAvailableQuestionnaireData(payload)
            commit("SET_AVAILABLE_QUESTIONNAIRE_DATA", { available_questionnaire_data: available_questionnaire_data })
            return available_questionnaire_data
        },

        /**
        * Delete a questionnaire with UUID questionnaire_uuid
        * @param {*} commit - Vuex store commit parameter
        * @param {*} payload - The payload for the request (see API documentation for details)
        * @returns {object} - Response from the request (see API documentation for details)
        */
        async FETCH_DELETE_QUESTIONNAIRE({ commit }, { payload }) {
            const response = await serviceClass.deleteQuestionnaire(payload)
            return response
        },

        /**
        * Register a survey
        * @param {*} commit - Vuex store commit parameter
        * @param {*} payload - The payload for the request (see API documentation for details)
        * @returns {object} - Response from the request (see API documentation for details)
        */
        async FETCH_REGISTER_SURVEY({ commit }, { payload }) {
            const response = await serviceClass.registerSurvey(payload)
            return response
        },

        /**
        * Retrieve all registered surveys
        * @param {*} commit - Vuex store commit parameter
        * @returns {object} - Response from the request (see API documentation for details)
        */
        async FETCH_REGISTERED_SURVEYS({ commit }) {
            commit("SET_REGISTERED_SURVEYS", { registered_surveys: {} })
            //commit("SET_REGISTERED_SURVEY_NAMES", { registered_survey_names: [] });
            const registered_surveys = await serviceClass.getRegisteredSurveys()
            //commit("SET_REGISTERED_SURVEY_NAMES", { registered_survey_names: registered_surveys.survey_names });
            commit("SET_REGISTERED_SURVEYS", { registered_surveys: registered_surveys })
            return registered_surveys
        },

        /**
        * Retrieve a token from the survey UUID
        * @param {*} commit - Vuex store commit parameter
        * @param {*} payload - The payload for the request (see API documentation for details)
        * @returns {object} - Response from the request (see API documentation for details)
        */
        async FETCH_ANONYMOUS_TOKEN({ commit }, { payload }) {
            commit("SET_ANONYMOUS_TOKEN", { anonymous_token: null })
            //commit("SET_TEMPLATEMAIL_TOKEN", { template_mail_token: null });
            commit("SET_ANONYMOUS_TOKEN_DATA", { anonymous_token_results: null })
            const response = await serviceClass.getAnonymousToken(payload)
            commit("SET_ANONYMOUS_TOKEN", { anonymous_token: response.anonymousToken.token })
            //commit("SET_TEMPLATEMAIL_TOKEN", { template_mail_token: response.templateMail });
            commit("SET_ANONYMOUS_TOKEN_DATA", { anonymous_token_results: response })  //e.g. mail template, didSaturate
            return response
        },

        /**
        * Store the survey replies
        * @param {*} commit - Vuex store commit parameter
        * @param {*} payload - The payload for the request (see API documentation for details)
        * @returns {object} - Response from the request (see API documentation for details)
        */
        async FETCH_SEND_SURVEY_RESULTS({ commit }, { payload }) {
            const response = await serviceClass.sendUserAnoSurveyResults(payload)
            return response
        },

        /**
        * Store the survey replies of a known user
        * @param {*} commit - Vuex store commit parameter
        * @param {*} payload - The payload for the request (see API documentation for details)
        * @returns {object} - Response from the request (see API documentation for details)
        */
        async FETCH_SEND_SURVEY_RESULTS_USER({ commit }, { payload }) {
            const response = await serviceClass.sendUserSurveyResults(payload)
            return response
        },

        /**
        * Delete a survey with UUID survey_uuid
        * @param {*} commit - Vuex store commit parameter
        * @param {*} payload - The payload for the request (see API documentation for details)
        * @returns {object} - Response from the request (see API documentation for details)
        */
        async FETCH_DELETE_REGISTERED_SURVEY({ commit }, { payload }) {
            const response = await serviceClass.deleteRegisteredSurvey(payload)
            return response
        },

        /**
        * Set the anonymous token as retrieved
        * @param {*} commit - Vuex store commit parameter
        * @param {*} anonymous_token - The anonymous token
        * @returns {object} - Response from the request (see API documentation for details)
        */
        async FETCH_SET_ANONYMOUS_TOKEN({ commit }, anonymous_token) {
            commit("SET_ANONYMOUS_TOKEN", { anonymous_token: anonymous_token })
            return anonymous_token
        },

        /**
         * Set the logos as retrieved
         * @param {*} commit - Vuex store commit parameter 
         * @param {*} payload - The payload for the request (see API documentation for details) 
         * @returns {object} - Response from the request (see API documentation for details)
         */
        async FETCH_USER_ACCESS_SURVEY_LOGOS({ commit }, { payload }) {
            const response = await serviceClass.getUserAccessSurveyLogos(payload)
            commit("SET_ANONYMOUS_PAGE_ICONS", { anonymous_page_icons: { "organisation": response.organisation.icon1Data, "study": response.survey.icon2Data } })
            
            return response
        },

        /**
        * Access anonymous survey if allowed and retrieve data
        * @param {*} commit - Vuex store commit parameter
        * @param {*} payload - The payload for the request (see API documentation for details)
        * @returns {object} - Response from the request (see API documentation for details)
        */
        async FETCH_USER_ACCESS_SURVEY({ commit }, { payload }) {
            commit("SET_USER_HAS_ACCESS_SURVEY", { user_has_access_survey: false })
            commit("SET_AVAILABLE_QUESTIONNAIRE_DATA_ANONYMOUS", { available_questionnaire_data_anonymous: {} })
            //commit("SET_USER_HAS_ACCESS_SURVEY_QUESTID", { user_has_access_survey_questId: "" });

            const response = await serviceClass.getUserAccessSurvey(payload)

            commit("SET_USER_HAS_ACCESS_SURVEY", { user_has_access_survey: true })
            //commit("SET_USER_HAS_ACCESS_SURVEY_QUESTID", { user_has_access_survey_questId: response.survey.questionnairePublicId });
            commit("SET_AVAILABLE_QUESTIONNAIRE_DATA_ANONYMOUS", { available_questionnaire_data_anonymous: { data: response.questionnaire.data, imageData: response.questionnaire.imageData, defaultLanguage: response.defaultLanguage } })
            commit("SET_ANONYMOUS_PAGE_ICONS", { anonymous_page_icons: { "organisation": response.organisation.icon1Data, "study": response.survey.icon2Data } })

            return response
        },

        /**
        * Retrieve all survey replies (JSON, TSV)
        * @param {*} commit - Vuex store commit parameter
        * @param {*} payload - The payload for the request (see API documentation for details)
        * @returns {object} - Response from the request (see API documentation for details)
        */
        async FETCH_ALL_SURVEY_REPLIES({ commit }, { payload }) {
            commit("SET_ALL_SURVEY_RESULTS", { all_survey_results: null })
            const all_survey_results = await serviceClass.getRegisteredSurveyReplies(payload)
            commit("SET_ALL_SURVEY_RESULTS", { all_survey_results: all_survey_results })
            return all_survey_results
        },

        /**
        * Retrieve all survey replies (JSON, TSV)
        * @param {*} commit - Vuex store commit parameter
        * @param {*} payload - The payload for the request (see API documentation for details)
        * @returns {object} - Response from the request (see API documentation for details)
        */
        async FETCH_USER_SURVEY_REPLIES({ commit }, { payload }) {
            commit("SET_ALL_SURVEY_RESULTS", { all_survey_results: null })
            const all_survey_results = await serviceClass.getUserRegisteredSurveyReplies(payload)
            commit("SET_ALL_SURVEY_RESULTS", { all_survey_results })
            return all_survey_results
        },

        /**
        * Store an uploaded questionnaire
        * @param {*} commit - Vuex store commit parameter
        * @param {*} payload - The payload for the request (see API documentation for details)
        * @returns {object} - Response from the request (see API documentation for details)
        */
        async FETCH_STORE_QUESTIONNAIRE_TEMPLATE({ commit }, { payload }) {
            const response = await serviceClass.registerQuestionnaire(payload)
            return response
        },

        /**
        * Access anonymous survey if allowed and retrieve data via passphrase
        * @param {*} commit - Vuex store commit parameter
        * @param {*} payload - The payload for the request (see API documentation for details)
        * @returns {object} - Response from the request (see API documentation for details)
        */
        async FETCH_USER_ACCESS_SURVEY_PASSPHRASE({ commit }, { payload }) {
            const response = await serviceClass.getUserAccessSurveyPassphrase(payload)
            //const res = { has_access: true, token: response.anonymousToken } //access is granted if the status code is 200
            return response.anonymousToken.token
        },

        /**
        * Connect a survey and an HAS user, e.g. patient connected to a specific questionnaire/survey
        * @param {*} commit - Vuex store commit parameter
        * @param {*} payload - The payload for the request (see API documentation for details)
        * @returns {object} - Response from the request (see API documentation for details)
        */
        async FETCH_CONNECT_SURVEY_USER({ commit }, { payload }) {
            const response = await serviceClass.connectSurveyUser(payload)
            return response
        },

        /**
       * Retrieve all available questionnaires for an user (HAS)
       * @param {*} commit - Vuex store commit parameter
       * @returns {object} - Response from the request (see API documentation for details)
       */
        async FETCH_GET_AVAILABLE_SURVEYS_USER({ commit }, { payload }) {
            commit("SET_AVAILABLE_SURVEYS_USER", { available_surveys_user: [] })
            const available_surveys_user = await serviceClass.getAvailableSurveysUser(payload)
            commit("SET_AVAILABLE_SURVEYS_USER", { available_surveys_user: available_surveys_user })
            return available_surveys_user
        },
    }
}
