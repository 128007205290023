<template>
    <div class="logo-box">
        <div class="logo-landscape" v-for="item in sources" :key="item.id">
            <img :src="item.src" />
        </div>
    </div>
</template>

<!--
Logo component

This is the component to render the organisation logos in the footer.

.. Copyright (C) 2020-2022 Information Technology for Translational Medicine S.A.
.. moduleauthor:: Kerstin Neininger
-->
<script>
export default {
    name: "LogoPage",
    computed: {
        sources() {
            return [
                //icon1 and icon2 from the organisation
                this.organisation_icon && {
                    id: 1,
                    organisation: this.user_organisation,
                    src: this.organisation_icon,
                },
                process.env.VUE_APP_SHOW_FOOTER_LOGO_ITTM && {
                    id: 2,
                    organisation: "ITTM",
                    src: require("../assets/img/logo_ITTM.png"), //necessary to put require, otherwise not displayed
                },
            ].filter(logo => logo)
        },
        user_organisation() {
            return this.$store.getters.user_organisation
        },
        organisation_icon() {
            return this.$store.getters.organisation_icon
        }
    },
}
</script>

<style scoped>
img {
    max-height: 70px;
    max-width: 190px;
}

.logo-box {
    display: flex;
    height: 125px;
    justify-content: space-around;
    align-items: center;
    padding-top: 0px;
    /*distances botton, side*/
    padding-bottom: 0px;
    padding-left: 100px;
    padding-right: 100px;
    background-color: #f1f1f1;
}
</style>